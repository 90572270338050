import { LoaderService } from './../../../services/loading-service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';
import { Equipo } from '../../equipos/services/equipo.service';
import { FederadosService } from '../../federados/services/federados.service';
import { Asociacion } from '../services/asociacion.service';
import { HttpHeaders } from '@angular/common/http';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'ngx-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  asociacion: Asociacion[] = [];
  equipos: any[] = [];
  public uniones: any[] = [];
  public paises: any[] = [];
  public estados: any[] = [];
  public recintos: any[] = [];
  public asociacionForm: FormGroup;
  hideModal: boolean;
  url: string;
  filename2: any;
  file2: '';
  min: Date;
  max: Date;
  url1: string;
  url2: string;
  url3: string;
  b64: any = null;
  datosFederado: any[];

  public dataRender: any = [
    {
      id: "inp-nombreAsociacion",
      formControl: "nombreAsociacion",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-union",
      formControl: "union",
      label: "Unión",
      placeholder: "Unión...",
      required: true,
      defaultValue: null,
      type: "autoCompletar",
      withError: false,
      dato:this.uniones
    },

    {
      id: "inp-pais_fundacion",
      formControl: "pais_origen",
      label: "País",
      placeholder: "México",
      required: true,
      defaultValue: 'MEXICO',
      type: "autoCompletar",
      withError: false,
      dato:this.paises
    },

    {
      id: "inp-domicilio",
      formControl: "domicilio",
      label: "Calle y número",
      placeholder: "Calle",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-colonia",
      formControl: "colonia",
      label: "Colonia",
      placeholder: "Constitución",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-ciudad_fundacion",
      formControl: "ciudad_fundacion",
      label: "Municipio",
      placeholder: "Monterrey",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-estado_fundacion",
      formControl: "estado_origen",
      label: "Entidad",
      placeholder: "Jalisco",
      required: true,
      defaultValue: 'MEXICO',
      type: "autoCompletar",
      withError: false,
      dato:this.estados
    },
    {
      id: "inp-codigo_postal",
      formControl: "codigo_postal",
      label: "CP / ZIP CODE",
      placeholder: "89670",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-telefono",
      formControl: "telefono",
      label: "Teléfono",
      placeholder: "5598765432",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-email",
      formControl: "email",
      label: "Correo",
      placeholder: "charros@jalisco.com",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-fecha_fundacion",
      formControl: "fecha_fundacion",
      label: "Fecha de Fundación",
      placeholder: "yyyy-mm-dd",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },
    {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "Facebook",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "Instagram",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-w",
      formControl: "w",
      label: "w",
      placeholder: "w",
      required: true,
      white: true,
      defaultValue: 0,
      type: "select",
      withError: false,
      noVisual: true,
      values: [
        {
          value: 0,
          label: "[-- Selecciona --]"
        }
      ]
    },
    {
      id: "inp-w",
      formControl: "w",
      label: "w",
      placeholder: "w",
      required: true,
      white: true,
      defaultValue: 0,
      type: "select",
      withError: false,
      noVisual: true,
      values: [
        {
          value: 0,
          label: "[-- Selecciona --]"
        }
      ]
    },

    {
      id: "inp-nombre_lienzo",
      formControl: "nombre_lienzo",
      label: "Lienzo Sede",
      placeholder: "Lienzo",
      required: true,
      defaultValue: 'FEDERACION',
      type: "autoCompletar",
      withError: false,
      dato:this.recintos
    }, {
      id: "inp-domicilio_lienzo",
      formControl: "domicilio_lienzo",
      label: "Domicilio Lienzo Sede",
      placeholder: "Calle...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-logotipo",
      formControl: "logotipo",
      label: "Logotipo",
      placeholder: "logotipo",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
      noVisual: true
    }
  ];
  public integrantes: any[] = [];

  public no: boolean = false;

  constructor(
    protected dateService: NbDateService<Date>,
    public api: FederadosService,
    private router: Router,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private loadingService: LoaderService
  ) {
    this.min = this.dateService.addDay(this.dateService.today(), -5);
    this.max = this.dateService.addDay(this.dateService.today(), 5);

    this.url1 = '/getAllAsociaciones';
    this.url2 = '/getAllEquipos';
    this.url3 = '/getAllRecintos';

    this.asociacionForm = this.formBuilder.group({
      nombreAsociacion: ["rancho el pitayo 1"],
      propietario_ap_paterno: ["propietarioTest4"],
      propietario_ap_materno: ["propietarioTest4"],
      propietario_nombre: ["propietarioTest4"],
      fecha_fundacion: ["2022-8-17"],
      no_registroFMCH: ["654654654"],
      facebook: ["face.book"],
      instagram: "@instagram",
      union: ["union test 2"],
      pais_fundacion: ["México"],
      estado_fundacion: ["Nuevo Leónn"],
      ciudad_fundacion: ["Monterrey"],
      equipo: ["rancho el pitayo 1"],
      importFile: [''],
    })

    let objRender: any = {};
    this.dataRender.forEach(element => {
      if (!element.noVisual) {
        let validators: any[] = [];
        validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
        if (element.required) {
          validators.push(Validators.required);
        }

        objRender[element.formControl] = validators;
      }
    });

    this.asociacionForm = this.formBuilder.group(objRender);


  }

  ngOnInit(): void {
    this.url = '/createAsociacion';
    this.hideModal = false;
    this.mostrarEquipo();
    this.mostrarUniones();
    this.mostrarpais();
    this.mostrarestados();
    this.mostrarLienzos();
  }

  mostrarLienzos() {
    this.api.get(this.url3).subscribe((res) => {
      
      res.recArr.map(e=>{

        this.recintos.push(e.nombre_recinto)
      });
     // console.log(this.recintos);
    });
  }

  mostrarEquipo() {
    this.api.get(this.url2).subscribe((res) => {
      //console.log("equipoEXTERNO", res);
      this.equipos = res.equipoArr;
    });
  }

  mostrarUniones() {
    this.api.get("/getAllUniones").subscribe((res) => {
      //console.log("equipoEXTERNO", res);

      res.UnionArr.forEach(e => {
        
        this.uniones.push(e.nombre);
        
      });
      
    });
  }
  mostrarpais() {
    this.api.get("/getAllPaises").subscribe((res) => {
      //console.log("equipoEXTERNO", res);

      res.paisArr.forEach(e => {
        
        this.paises.push(e.nombrePais);
        
      });
      
    });
  }
  mostrarestados() {
    this.api.get("/getAllEstados").subscribe((res) => {
      //console.log("equipoEXTERNO", res);

      res.edoArr.forEach(e => {
        
        this.estados.push(e.nombreEstado);
        
      });
      
    });
  }



  mostrarFederado(id, curp) {

    if(this.integrantes[id].curp_integrante.value.length === 18) {
      try {
        this.api.get(`/getFederadoByCurp/${curp}`).subscribe((res) => {

          this.integrantes[id].nombre_completo_integrante.value = res[0].nombre_completo;
          this.integrantes[id].email_integrante.value = res[0].email;
          this.integrantes[id].telefono_integrante.value = res[0].cel;
  
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    //console.log(files);

    if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
      this.filename2 = "Selected file: " + files[0].name;
      this.file2 = files[0];

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.b64 = reader.result;
      };
    } else {
      this.alertService.errorAlert("Oops!", "Solo formatos de imagen permitido (png, jpg, jpeg)");
    }
  }

  addAsociacion() {
    let formData = new FormData();
    const headers = new HttpHeaders();
    this.loadingService.show("Generando asociación");
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append('nombreAsociacion', this.asociacionForm.value.nombreAsociacion);
    formData.append('propietario_ap_paterno', this.asociacionForm.value.propietario_ap_paterno);
    formData.append(' propietario_ap_materno', this.asociacionForm.value.propietario_ap_materno);
    formData.append('propietario_nombre', this.asociacionForm.value.propietario_nombre);
    formData.append('fecha_fundacion', this.asociacionForm.value.fecha_fundacion);
    formData.append('no_registroFMCH', this.asociacionForm.value.no_registroFMCH);
    formData.append('facebook', this.asociacionForm.value.facebook);
    formData.append('instagram', this.asociacionForm.value.instagram);
    formData.append('union', this.asociacionForm.value.union);
    formData.append('pais_fundacion', this.asociacionForm.value.pais_fundacion);
    formData.append('estado_fundacion', this.asociacionForm.value.estado_fundacion);
    formData.append('ciudad_fundacion', this.asociacionForm.value.ciudad_fundacion);
    formData.append(' equipo', this.asociacionForm.value.equipo);
    formData.append('importFile', this.file2);

    this.api.post2(this.url, formData, headers).subscribe(res => {
      if (res) {
        this.clearForm();
        this.loadingService.hide();
        this.alertService.successAlert("Bien!", res.msg);
        location.reload();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
      }
    });


  }

  clearForm() {
    window.history.back();
    this.asociacionForm.reset();
  }

  nuevaMesa() {
    this.integrantes.push({
      cargo_integrante: {
        value: "",
        placeholder: "Cargo"
      },
      nombre_completo_integrante: {
        value: "",
        placeholder: "Nombre completo"
      },
      curp_integrante: {
        value: "",
        placeholder: "CURP"
      },
      email_integrante: {
        value: "",
        placeholder: "Email"
      },
      telefono_integrante: {
        value: "",
        placeholder: "Teléfono"
      }
    });
  }

  borraMesa(position) {
    this.integrantes.splice(position, 1);
  }

  addAsociacion2() {
    this.loadingService.show("Creando...");
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    let dataJson = {};
    this.dataRender.forEach(element => {
      if (!element.noVisual) {
        formData.append(element.formControl, element.defaultValue);
        dataJson[element.formControl] = element.defaultValue;
      }

    });

    //formData.append("integrantes", this.integrantes && this.integrantes?.length > 0 ? this.integrantes : []);
    //formData.append("importFile",this.dataRender[17].defaultValue);
    dataJson["integrantes"] = [];

    if (this.integrantes.length > 0) {
      dataJson["integrantes"] = [];
      this.integrantes.forEach(m => {
        dataJson["integrantes"].push({
          cargo_integrante: m.cargo_integrante.value,
          curp_integrante: m.curp_integrante.value,
          nombre_completo_integrante: m.nombre_completo_integrante.value,
          telefono_integrante: m.telefono_integrante.value,
          email_integrante: m.email_integrante.value
        });
      });
    }

    dataJson["importFile"] = this.dataRender[17].defaultValue;

    this.api.post2(this.url, dataJson, headers).subscribe(res => {
      this.loadingService.hide();
      if (res) {
        this.clearForm();
        this.loadingService.hide();
        this.alertService.successAlert("Bien!", res.msg);
        //location.reload();
        setTimeout(() => {
          window.history.back();
        }, 1000);
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
      }
    });
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[17].defaultValue = files[0];

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.dataRender[17].b64 = reader.result;
    };


  }



}


