import { HttpErrorResponse } from "@angular/common/http";
import { AlertService } from "./../../services/alert.service";
import { Router } from "@angular/router";
import { LoaderService } from "./../../services/loading-service";
import { Subscription } from "rxjs";
import { EventService } from "./../../services/event.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpHeaders } from "@angular/common/http";
import { FederadosService } from "../federados/services/federados.service";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";
@Component({
  selector: "ngx-equipos",
  templateUrl: "./equipos.component.html",
  styleUrls: ["./equipos.component.scss"],
})
export class EquiposComponent implements OnInit {
  public datosUsuario: any;
  equipoId;
  camposIntegrante: boolean = false;
  public create: boolean = false;
  public uniones: any[] = [];
  public suscription: Subscription;
  public suscription2: Subscription;
  public data: any = null;
  public UnionName: any = [];
  public asociacionName: any = [];
  public eq: any = null;
  public fedName: any = [];
  public fedData: any = [];

  public equipor = null;

  ls: any = JSON.parse(localStorage.getItem("userSessionFederacion"));
  public userPerfil: String = this.ls.perfiles[0];
  public isAsocEdoDisabled: Boolean = false;

  //public generalForm: FormGroup;

  public dataRender: any = [
    {
      id: "inp-nombreEquipo",
      formControl: "nombreEquipo",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-asociacion",
      formControl: "asociacion",
      label: "Asociación",
      placeholder: "Asociación...",
      required: true,
      defaultValue: null,
      type: "autoCompletar",
      withError: false,
      dato: this.asociacionName,
      values: [
        {
          value: "",
          label: "[-- Selecciona --]",
        },
      ],
    },
    {
      id: "inp-fecha_fundacion",
      formControl: "fecha_fundacion",
      label: "Fecha de fundación",
      placeholder: "yyyy-mm-dd",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false,
    },
    {
      id: "inp-pais_origen",
      formControl: "pais_origen",
      label: "País",
      placeholder: "País...",
      required: true,
      defaultValue: 'MEXICO',
      type: "autoCompletar",
      withError: false,
      dato: ['MEXICO', 'USA']
    },
    {
      id: "inp-estado_origen",
      formControl: "estado_origen",
      label: "Estado",
      placeholder: "Estado...",
      required: true,
      defaultValue: 'MEXICO',
      type: "autoCompletar",
      withError: false,
      dato: this.UnionName,
    },
    {
      id: "inp-ciudad",
      formControl: "ciudad",
      label: "Ciudad",
      placeholder: "Ciudad...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },

    {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "@facebook...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },

    {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "@Instagram...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },

    {
      id: "inp-tiktok",
      formControl: "tiktok",
      label: "Tiktok",
      placeholder: "@Tiktok...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-curp",
      formControl: "curp",
      label: "CURP",
      placeholder: "@Curp...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-propietario_Nombre_Completo",
      formControl: "propietario_Nombre_Completo",
      label: "Nombre completo del Capitán",
      placeholder: "Nombre capitán...",
      required: true,
      defaultValue: null,
      type: "autoCompletar",
      withError: false,
      dato: this.fedName,
    },
    {
      id: "inp-cel",
      formControl: "celular",
      label: "Celular",
      placeholder: "5512345678",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-email",
      formControl: "email",
      label: "Email",
      placeholder: "charros@jalisco.com",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
      noVisual: true,
    },
    {
      id: "inp-foto",
      formControl: "foto",
      label: "Foto",
      placeholder: "foto",
      required: true,
      defaultValue: null,
      defaultValueb64: null,
      type: "text",
      withError: false,
      noVisual: true,
    },
  ];
  asociaciones: any[];

  public img: any = environment.urlServerimg;

  constructor(
    private eventService: EventService,

    public api: FederadosService,
    private loadingService: LoaderService,
    private router: Router,
    private alertService: AlertService
  ) {
    let objRender: any = {};
    this.dataRender.forEach((element) => {
      if (!element.noVisual) {
        let validators: any[] = [];
        validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
        if (element.required) {
          validators.push(Validators.required);
        }

        objRender[element.formControl] = validators;
      }
    });
    //console.log(objRender);

    //this.generalForm = this.formBuilder.group(objRender);
  }

  ngOnInit(): void {
    
    this.mostrarUsario();
    this.mostrarFederados();
    this.mostrarEquipo();
    this.mostrarUniones();
    this.asociacionesGET();
    this.validatePerfil();
    this.suscription2 = this.eventService.get("sss2").subscribe((data) => {
      this.create = true;
      this.validatePerfil();
    });
    this.suscription = this.eventService.get("sss").subscribe((data) => {
      //console.log("detalleEquipo");
      this.create = false;
      console.log(data);

      if (data) {
        this.data = data;
        this.eq = data;

        let objForm: any = {};
        this.dataRender.forEach((itm) => {
          //if(itm.formControl.includes(itm[])){
          objForm[itm.formControl] = this.data.current[itm.formControl];
          itm.defaultValue = data.current[itm.formControl];

          //}
        });
      } else {
        this.dataRender.forEach((itm) => {
          itm.defaultValue = "";          
        });
        this.data.current = {};        
      }
      //console.log(objForm);

      //this.generalForm.setValue(objForm);

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scroll(0, 0); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    });
  }

  goToPagos() {    
    this.router.navigateByUrl(
      "pages/pagos/" + this.data?.current?._id + "/false"
    );
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
    this.suscription2.unsubscribe();
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    console.log(files);

    if (
      files[0].type == "image/png" ||
      files[0].type == "image/jpg" ||
      files[0].type == "image/jpeg"
    ) {
      this.dataRender[13].defaultValue = files[0];
      this.dataRender[13].foto = "";
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.dataRender[13].defaultValueb64 = reader.result;

        console.log(this.dataRender);
      };
    } else {
      this.alertService.errorAlert(
        "Oops!",
        "Solo formatos de imagen permitido (png, jpg, jpeg)"
      );
    }
  }

  update() {
    this.loadingService.show("Actualizando...");
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    //headers.append("x-access-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYWRiYjRkNmUwYTA2MWMxM2U5MTI4NyIsImlhdCI6MTYzODc3NjA0NywiZXhwIjoxNjM4ODYyNDQ3fQ.5mlgDusvLXw2EO3nvmmbDErekRuUWZO9tc8Zx7WP_VI");
    //console.log(this.dataRender);
    let dataJson = {};
    this.dataRender.forEach((element) => {
      if (element.formControl != "foto") {
        formData.append(element.formControl, element.defaultValue);
        dataJson[element.formControl] = element.defaultValue;
      }
    });

    if (this.dataRender[13]?.foto?.length <= 0) {
      formData.append("importFile", this.dataRender[13].defaultValue);
      dataJson["importFile"] = this.dataRender[13].defaultValue;
    } else {
      formData.append("importFile", this.dataRender[13].foto);
      dataJson["importFile"] = this.dataRender[13].foto;
    }
    //console.log(formData);
    console.log(this.data);

    let urlupdate = "/updateEquipo/" + this.data?.current?._id;
    let ss = this.api.update(urlupdate, dataJson, headers);
    if (this.create) {
      urlupdate = "/createEquipo/";
      ss = this.api.post(urlupdate, dataJson);
    }

    ss.subscribe((res) => {
      this.loadingService.hide();
      if (!res.error) {
        // // this.clearForm();
        // // this.showWareHoouse();
        // // this.router.navigateByUrl('/usuario/u/warehouseCheck');
        // let idTmp = this.create ? res.newEquipo._id : this.data.current._id;
        // urlupdate = `/updateEquipo/${idTmp}`;
        // formData.delete("foto");
        // this.api.update(urlupdate, formData, headers).subscribe(
        //   (resFile) => {
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: this.create
                ? "Se creó el equipo correctamente."
                : "Se actualizo el equipo correctamente.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.clearForm();
            });
          // },
          // (error: HttpErrorResponse) => {
          //   this.loadingService.hide();
          //   Swal.fire({
          //     //position: "top-end",
          //     icon: "error",
          //     title: error.error.msg,
          //     showConfirmButton: false,
          //     timer: 1500,
          //   }).then((result) => {
          //     this.clearForm();
          //   });
          // }
        // );

        //location.reload();
      }
      else{
          this.loadingService.hide();
            Swal.fire({
              //position: "top-end",
              icon: "error",
              title: res.msg,
              showConfirmButton: false,
              timer: 3000,
            })
            // .then((result) => {
            //   this.clearForm();
            // });
      }
    });
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará la unión permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingService.show("Eliminando...");
        const url2 = "/deleteEquipo/" + this.data.id;
        this.api.delete(url2).subscribe((res3) => {
          this.loadingService.hide();
          //console.log("delete", res3);
          setTimeout(() => {
            location.reload();
          }, 1000);
          //this.mostrarUnion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  clearForm() {
    this.eventService.send("rmv", null);
    //this.generalForm.reset();
    this.data = null;
    this.create = false;
    setTimeout(() => {
      location.reload();
    }, 1500);
  }

  plus() {
    this.clearForm();

    this.router.navigateByUrl("/pages/equipos/registro");
  }

  print() {
    localStorage.setItem("eq", JSON.stringify(this.eq));
    this.router.navigate(["/", "pages", "print", "1"]);
  }
  printEquipo() {
    localStorage.setItem("eq", JSON.stringify(this.eq));
    this.router.navigateByUrl("/CredencialEquipo");
  }

  asociacionesGET() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      this.asociaciones = res.asocArr;
      this.asociaciones.forEach((element) => {
        this.dataRender[1].values.push({
          label: element.nombreAsociacion,
          value: element.nombreAsociacion,
        });
      });
      res.asocArr.forEach((element) => {
        const nombre = element.nombreAsociacion;
        this.asociacionName.push(nombre);
      });
    });
  }

  mostrarFederado(id, curp) {
    if (this.dataRender[id].defaultValue.length === 18) {
      try {
        this.api.get(`/getFederadoByCurp/${curp}`).subscribe((res) => {
          this.dataRender[10].defaultValue = res[0].nombre_completo;
          this.dataRender[11].defaultValue = res[0].cel;
          this.dataRender[12].defaultValue = res[0].email;
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  addCamposIntegrante() {
    this.camposIntegrante = !this.camposIntegrante;
  }

  nuevoMiembro: any = {};

  nuevoCURP: string = "";
  nuevoNombreCompleto: string = "";
  buscarCURP(event: any) {
    const CURP = event.target.value;
    const urlCURP = "/getFederadoByCurp/" + CURP;
    this.api.get(urlCURP).subscribe((res) => {
      console.log(res);

      this.nuevoMiembro = res[0];
      this.nuevoNombreCompleto = this.nuevoMiembro.nombre_completo;
      //console.log(this.nuevoMiembro.nombre_completo);
      //console.log(this.nuevoMiembro);
      //this.equipo = res;
    });
  }

  buscarCURP2(event: any) {
    const CURP = event.target.value;
    const urlCURP = "/getFederadoByCurp/" + CURP;
    this.api.get(urlCURP).subscribe((res) => {
      if (res[0]) {
        this.dataRender[10].defaultValue = res[0].nombre_completo;
        this.dataRender[11].defaultValue = res[0].cel;
        this.dataRender[12].defaultValue = res[0].email;
      }

      //console.log(this.nuevoMiembro.nombre_completo);
      //console.log(this.nuevoMiembro);
      //this.equipo = res;
    });
  }

  equipo: any[] = [];
  mostrarEquipo() {
    this.api.get("/getAllEquipos").subscribe((res) => {
      //console.log("equipoEXTERNO", res);
      this.equipo = res.eqArr;
    });
  }

  equipoSeleccionado: any = {};
  miembros: any[] = [];
  alineacion: boolean = false;
  elegirMiembros(equipo: any) {
    const urlmiembros = "/getAllFederadosByEquipo/" + this.equipor._id;
    this.api.get(urlmiembros).subscribe((res) => {
      this.alineacion = true;
      this.miembros = res.arrFed;
    });
  }

  mostrarUniones() {
    this.api.get("/getAllUniones").subscribe((res) => {
      //console.log("equipoEXTERNO", res);
      this.uniones = res.UnionArr;
      this.uniones.forEach((element) => {
        this.dataRender[1].values.push({
          label: element.nombre,
          value: element.nombre,
        });
      });
      res.UnionArr.forEach((e) => {
        this.UnionName.push(e.nombre);
      });
    });
  }
  mostrarFederados() {
    this.api.get("/getAllFederados").subscribe((res) => {
      res.arrFed.forEach((e) => {
        this.fedName.push(e.nombre_completo);
      });
    });
  }

  userNamengmodelchange(value) {
    this.api.get(`/getAllFederados`).subscribe((res) => {
      res.arrFed.forEach((element) => {
        const _id = element._id;
        const nombre = element.nombre_completo;
        const cel = element.cel;
        this.fedData.push({ _id, nombre, cel });
      });

      if (this.fedData.find((element) => element.nombre === value)) {
        let busqueda = this.fedData.find((element) => element.nombre === value);
        this.dataRender[11].defaultValue = busqueda.cel;
        console.log(busqueda);
      }
    });
  }
  clicked() {
    document.getElementById("hf-logo46").click();
  }

  addNuevoIntegranteEquipo() {
    //console.log(this.equipoSeleccionado);
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    const actualizarfederados = "/updateFederado/" + this.nuevoMiembro._id;
    this.nuevoMiembro.asociacion = null;
    this.nuevoMiembro.equipo = this.equipoSeleccionado.nombreEquipo;
    this.nuevoMiembro.estado_origen = null;
    this.nuevoMiembro.pais_origen = null;

    this.api
      .update(actualizarfederados, this.nuevoMiembro, headers) //
      .subscribe((res) => {
        if (res) {
          this.camposIntegrante = false;
          this.nuevoCURP = "";
          this.nuevoNombreCompleto = "";
          this.elegirMiembros(this.equipoSeleccionado);
        }
      });
  }

  mostrarUsario() {
    //this.api.get(this.url).subscribe((res) => {
    let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
    this.datosUsuario = res;

    console.log(this.datosUsuario);
    // this.chang.detectChanges();
    //});
  }

  public validatePerfil():any{
    // if(this.userPerfil === 'superadmin'){
    //   return this.isAsocEdoDisabled = false;
    // }
    if(this.userPerfil === 'presidente union' || this.userPerfil === 'presidente asociacion'){
      console.log("datarender ",this.dataRender);
      console.log("LS ==> ",this.ls);
      let newDataRenderObj = this.dataRender.map((arr) => {
        console.log(arr);
        const newArr = {...arr};

        if(arr.id === 'inp-asociacion'){
          newArr.type = 'disabled';
          newArr.defaultValue = this.ls.asociacion[0];
        }
        
        if(arr.id === 'inp-estado_origen'){
          newArr.type = 'disabled';
          newArr.defaultValue = this.ls.estado_origen[0];
        }
        
        if(arr.id === 'inp-pais_origen'){
          newArr.type = 'disabled';
        }
        return newArr;
      })
      this.dataRender = newDataRenderObj;
      // return newDataRenderObj;
      console.log("this.dataRender =>", this.dataRender);
    } 
  }
}
