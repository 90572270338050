import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NbDateService } from "@nebular/theme";
import { FederadosService } from "../../federados/services/federados.service";
import { Asociacion, Equipo } from "../../interface/interfaces";
import { HttpHeaders } from "@angular/common/http";
import { AlertService } from "../../../services/alert.service";
@Component({
  selector: "ngx-registro",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.scss"],
})
export class RegistroUnionComponent implements OnInit {
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  public unionForm: FormGroup;
  hideModal: boolean;
  url: string;
  filename2: any;
  file2: "";
  min: Date;
  max: Date;
  url1: string;
  url2: string;
  b64: any = null;
  constructor(
    protected dateService: NbDateService<Date>,
    public api: FederadosService,
    private router: Router,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.min = this.dateService.addDay(this.dateService.today(), -5);
    this.max = this.dateService.addDay(this.dateService.today(), 5);

    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";

    this.unionForm = this.formBuilder.group({
      nombre: ["Union Test 2"],
      pais_origen: ["MÉXICO"],
      estado_origen: ["CDMX"],
      cp: ["52123"],
      calle: ["calle test 1"],
      numero_ext: ["1235"],
      colonia: ["colonia test 1"],
      ciudad: ["ECATEPUNK"],
      lada: ["+52"],
      celular: ["5523467910"],
      noRegistroFMCH: ["463815348"],
      email: ["emailunion1@mail.com"],
      facebook: [""],
      instagram: [""],
      importFile: [""],
      tipo_Puesto_Union_FMCH: ["PRESIDENTE"],
      tipo_Puesto_Union_FMCH_NombreCompleto: ["nombre presidente test 1"],
      tipo_Puesto_Union_FMCH_Tel: ["5546798025"],
      tipo_Puesto_Union_FMCH_email: ["testemailpuesto1@mail.com"],
      statusPago: ["VIGENTE"],
      vencimiento: ['2022-8-17'],
    });
  }

  ngOnInit(): void {
    this.url = "/createUnion";
    this.hideModal = false;
  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
   //console.log(files);

    if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
      this.filename2 = "Selected file: " + files[0].name;
      this.file2 = files[0];

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.b64 = reader.result;
      };
    } else {
      this.alertService.errorAlert("Oops!", "Solo formatos de imagen permitido (png, jpg, jpeg)");
    }
  }

  addUnion() {
    let formData = new FormData();
    const headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("nombre", this.unionForm.value.nombre);
    formData.append("pais_origen", this.unionForm.value.pais_origen);
    formData.append("estado_origen", this.unionForm.value.estado_origen);
    formData.append("cp", this.unionForm.value.cp);
    formData.append("calle", this.unionForm.value.calle);
    formData.append("numero_ext", this.unionForm.value.numero_ext);
    formData.append("colonia", this.unionForm.value.colonia);
    formData.append("cuidad", this.unionForm.value.cuidad);
    formData.append("lada", this.unionForm.value.lada);
    formData.append("celular", this.unionForm.value.celular);
    formData.append("noRegistroFMCH", this.unionForm.value.noRegistroFMCH);
    formData.append("email", this.unionForm.value.email);
    formData.append("facebook", this.unionForm.value.facebook);
    formData.append("instagram", this.unionForm.value.instagram);
    formData.append(
      "tipo_Puesto_Union_FMCH",
      this.unionForm.value.tipo_Puesto_Union_FMCH
    );
    formData.append("importFile", this.file2);
    formData.append(
      "tipo_Puesto_Union_FMCH_NombreCompleto",
      this.unionForm.value.tipo_Puesto_Union_FMCH_NombreCompleto
    );
    formData.append(
      "tipo_Puesto_Union_FMCH_Tel",
      this.unionForm.value.tipo_Puesto_Union_FMCH_Tel
    );
    formData.append(
      "tipo_Puesto_Union_FMCH_email",
      this.unionForm.value.tipo_Puesto_Union_FMCH_email
    );
    formData.append("statusPago", this.unionForm.value.statusPago);
    formData.append("vencimiento", this.unionForm.value.vencimiento);
    this.api.post2(this.url, formData, headers).subscribe((res) => {
      if (res) {
        this.clearForm();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
      }
    });
  }
  clearForm() {
    this.unionForm.reset();
  }
}
