import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../../environments/environment";
import { DetallesComponent } from "../../../federados/detalles/detalles.component";
import { FederadosService } from "../../../federados/services/federados.service";
import { Federados, Equipo } from "../../../interface/interfaces";
import { Asociacion } from "../../services/asociacion.service";

import { EventService } from "../../../../services/event.service";
import { LoaderService } from "../../../../services/loading-service";

declare var $: any;
@Component({
  selector: "ngx-tabla-asoc",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.scss"],
})
export class TablaAsocComponent implements OnInit {
  public datosUsuario:any;
  @Input() federadosList: any[] = [];
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  public federadosForm: FormGroup;
  hideModal: boolean;
  url: string;
  url1: string;
  url2: string;
 
  img: any;

  constructor(
    //public rest: FederadosService,
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private loadingService: LoaderService,
    private eventService: EventService,
  ) {
    this.img = environment.urlServerimg;
    this.url = "/getAllFederados";
    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";
    //this.mostrarFederados();

    setTimeout(function () {
      $(function () {
        $("#dt").DataTable({
          bDestroy: true,

          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 10,
          sPaginationType: "full_numbers",
          sDom: '<"top"i> T<"clear">lfrtip',
          sPageButtonActive: "paginate_active",
          sPageButtonStaticDisabled: "paginate_button",
          scrollY: "400px",
          scrollCollapse: true,
        });
      });
    }, 5990);
  }

  ngOnInit(): void {
    
    this.hideModal = false;
    this.mostrarUsario();
    // this.getProducts();
  }

  plus() {
    this.router.navigateByUrl("/pages/federados/detalles");
  }

  edit(id) {
    
    this.router.navigateByUrl("pages/federados/detalles/" + id);
   
  }


  mostrarUsario() {
    //this.api.get(this.url).subscribe((res) => {
     let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
     this.datosUsuario = res
    
    console.log(this.datosUsuario);
    // this.chang.detectChanges();
    //});
  
  }

  
}
