import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { FederadosService } from "../federados/services/federados.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericService } from './../../services/generic.service';
import { AlertService } from '../../services/alert.service';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.scss'],
})
export class ValidacionComponent implements OnInit {
public codigo:any;
public codigoPass:any;
public email:string;
public pass:string;
public ids: any = null;
public perfil: any = null;

  constructor(
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    private alertService: AlertService,
    public router: Router,
    private genericService: GenericService,
  ) { }

  ngOnInit() {
    this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        this.ids = params["id"];
        this.perfil = params["perfil"];
      })

      this.api.get(`/getFederadoById/${this.ids}`).subscribe((res) => {
        this.codigoPass=res[0].password;
        console.log(this.codigoPass)
      });
  }

confirmacion(){
  let JSON ={
    esPresAsoc:true,
    perfiles:[this.perfil]
  }

  
  let formData = new FormData();
  const headers = new HttpHeaders();
  headers.append("Content-Type", "multipart/form-data");
  headers.append("Accept", "application/json");
  formData.append("esPresAsoc", 'true');
  let url = "/updateFederado/" + this.ids;
  console.log(url)

  


  let sus = this.api.update(url, JSON, headers)
  sus.subscribe((res: any) => {
   // this.loadingService.hide();
   console.log("Response del createFed => ",res);})
 
  
}
login() {
  

  if(this.codigo==this.codigoPass){

  if (this.email.length <= 0 || this.pass.length <= 0) {
    this.alertService.errorAlert("Oops", "Asegurate de haber ingresado usuario y contraseña");
  } else {
    let request: any = {
      email: this.email,
      password: this.pass
    }
    this.confirmacion();
    this.genericService.sendPostRequest(environment.login, request).subscribe((response: any) => {
      localStorage.setItem("tokenazo", JSON.stringify(response.token));
      this.genericService.sendGetRequestParams(`${environment.getFederadoById}/${response.id}`, request).subscribe((response2: any) => {
        response2[0].passwerd = this.pass;
        localStorage.setItem("userSessionFederacion", JSON.stringify(response2[0]));
       
        this.router.navigate(["/pages/estadisticas"]);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        
        if (error?.error?.msg) {
          this.alertService.errorAlert("Oops", error?.error?.msg);
        } else {
          this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
        }
      });
    }, (error: HttpErrorResponse) => {
      console.log(error);
      if (error?.error?.msg) {
        this.alertService.errorAlert("Oops", error?.error?.msg);
      } else {
        this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
      }
    });
  }
}
}
}
