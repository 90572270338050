import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, Color } from 'ng2-charts';
import * as _ from 'lodash';

import { FederadosService } from '../federados/services/federados.service';
declare var $: any;
@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss'],
})

export class EstadisticasComponent implements OnInit {

  public union: any = [];

  public UnionMexico:any;
  public UnionUsa:any;
  public UnionTotal:any;

  public AsocMexico:any;
  public AsocUsa:any;
  public AsocTotal:any;


  public EquipoMexico:any;
  public EquipoUsa:any;
  public EquipoTotal:any;

  public FedMexico:any;
  public FedUsa:any;
  public FedTotal:any;

  public CharrosMexico:any;
  public CharrosUsa:any;
  public CharrosTotal:any;

  public EscMexico:any;
  public EscUsa:any;
  public EscTotal:any;

  public DLH:any;
  public DLM:any;

  public infantilA_h:any;
  public infantilA_m:any;

  public infantilB_h:any;
  public infantilB_m:any;

  public juvenil_h:any;
  public juvenil_m:any;
  
  public libre_h:any;
  public libre_m:any;

  public charroM_h:any;
  public charroM_m:any;


  public barChartOptions: ChartOptions = {
  //   title: {
  //     fontSize: 15,
  //     fontStyle:'bold',
  //     display: true,
  //     text: 'Custom Chart Title'
  // },
    legend: {
      display:false,
      labels: {
          // This more specific font property overrides the global property
          fontColor: 'blue',
          fontSize:18
      }
  },
    aspectRatio:2.0,
    plugins: {
      
      datalabels: {
        display:false,
        color: 'white',
       // anchor: 'end',
       // align: 'end',
        font: {
          size: 14,
          weight: 'bold'
          }
      }
    },
    scales: {
     
      xAxes: [{
      
        display: true,
        ticks: {
          
          fontSize: 12,
          fontStyle:'bold'
        },
        stacked: true,
        id: "bar-x-axis1",
      //  barThickness: 30,
      }, {
        display: false,
        stacked: true,
        id: "bar-x-axis2",
        gridLines: {
        offsetGridLines: false
        },
        offset: false
      }],
      yAxes: [
      {
        display: true,
     
          id: "bar-y-axis1",
        stacked: true,
        ticks: {
          fontStyle:'bold',
          fontSize: 15,
           }
      },
        {
          id: "bar-y-axis2",
          stacked: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 10,
            autoSkip: false,
            display: false
          },
          gridLines: {
              display: false
          }
      }]
  
    }
  
  };

  public barChartOptions2: ChartOptions = {
    //   title: {
    //     fontSize: 15,
    //     fontStyle:'bold',
    //     display: true,
    //     text: 'Custom Chart Title'
    // },
      legend: {
        display:false,
        labels: {
            // This more specific font property overrides the global property
            fontColor: 'blue',
            fontSize:18
        }
    },
      aspectRatio:1.5,
      plugins: {
        
        datalabels: {
          
          color: 'white',
         // anchor: 'end',
         // align: 'end',
          font: {
            size: 14,
            weight: 'bold'
            }
        }
      },
 
    
    };


  public ChartColors: Color[] = [
    {
      borderColor: '#5285f5',
      backgroundColor: '#5285f5',
    },
    {
      borderColor: 'blue',
      backgroundColor: 'blue',
    },
  ];
  public ChartColors2: Color[] = [
    {
      borderColor: ['#5285f5','#5295f5','#5275f5','#5285f5','#5295f5','#5275f5'],
      backgroundColor: ['#5285f5','#5295f5','#5275f5','#5285f5','#5295f5','#5275f5'],
    },
    {
      borderColor: 'blue',
      backgroundColor: 'blue',
    },
  ];
  public barChartLabels: Label[] = ['DL 1-10', 'IA 11-13', 'IB 14-16', 'Ju 17-18', 'Li 19-44', 'M 45-55+'];
  public barChartType: ChartType = 'horizontalBar';
  public barChartType2: ChartType = 'pie';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80], label: 'Series Mujeres' },
    { data: [65, 59, 80], label: 'Series Hombres' },
  ];


  public barChartData2: ChartDataSets[] = [
    { data: [65, 59, 80], label: 'Series A' },
   
  ];


  constructor(
    public api: FederadosService,
  ) { }

  ngOnInit() {
    this.mostrarUniones();
    
   
    
  }

  ngAfterViewInit(): void {
  this.mostrarCategorias();
  }
  mostrarUniones() {
    this.api.get("/getNumberUnionesMexico").subscribe((res) => {
     //  console.log("UnionMexico", res.unionesMexico);
      this.UnionMexico=res.unionesMexico;
      this.UnionTotal=res.unionesMexico;
      this.api.get("/getNumberUnionesUSA").subscribe((res) => {
      //  console.log("UnionUsa", res.unionesUSA);
        this.UnionUsa=res.unionesUSA;
        this.UnionTotal= this.UnionTotal+res.unionesUSA;
     });
    });
   
   this.api.get("/getNumberAsociacionesMexico").subscribe((res) => {
  //  console.log("AsocMexico", res.asociacionesMexico);
    this.AsocMexico=res.asociacionesMexico;
    this.AsocTotal=res.asociacionesMexico;
    this.api.get("/getNumberAsociacionesUSA").subscribe((res) => {
     // console.log("AsocUsa", res.asociacionesUSA);
      this.AsocUsa=res.asociacionesUSA;
      this.AsocTotal=this.AsocTotal+res.asociacionesUSA;
     });
   });
 
   this.api.get("/getNumberEquiposMexico").subscribe((res) => {
    //console.log("EquiMexico", res.equiposMexico);
    this.EquipoMexico=res.equiposMexico;
    this.EquipoTotal=res.equiposMexico;
    this.api.get("/getNumberEquiposUsa").subscribe((res) => {
      //console.log("EquiUsa", res.equiposUSA);
      this.EquipoUsa=res.equiposUSA;
      this.EquipoTotal=this.EquipoTotal+res.equiposUSA;
     });
   });
   
   this.api.get("/getNumberFederadosMexico").subscribe((res) => {
   // console.log("FedMexico", res.federadosMexico);
    this.FedMexico=res.federadosMexico;
    this.FedTotal = res.federadosMexico;
    this.api.get("/getNumberFederadosUsa").subscribe((res) => {
    //  console.log("FedUsa", res.federadosUSA);
      this.FedUsa=res.federadosUSA;
      this.FedTotal = this.FedTotal + res.federadosUSA;
  
     });
   });
  
 
   this.api.get("/getNumberCharrosMexico").subscribe((res) => {
   // console.log("CharroMexico", res.charrosMexico);
    this.CharrosMexico=res.charrosMexico;
    this.CharrosTotal = res.charrosMexico;

    this.api.get("/getNumberCharrosUSA").subscribe((res) => {
     // console.log("CharroUsa", res.charrosUSA);
      this.CharrosUsa=res.charrosUSA;
      this.CharrosTotal = this.CharrosTotal + res.charrosUSA;
     });
   });
   this.api.get("/getNumberEscaramuzasMexico").subscribe((res) => {
   // console.log("EscaramuzaMexico", res.escaramuzasMexico);
    this.EscMexico=res.escaramuzasMexico;
    this.EscTotal = res.escaramuzasMexico;

    this.api.get("/getNumberEscaramuzasUsa").subscribe((res) => {
    //  console.log("EscaramuzaUsa", res.escaramuzasUSA);
      this.EscUsa=res.escaramuzasUSA;
      this.EscTotal = this.EscTotal + res.escaramuzasUSA;
     });
   });
 
    
  
  }


  mostrarCategorias(){
    const dataChartH=[];
    const dataChartM= [];
    const dataChartT= [];
    const data= [{
      DlH:'',
      DlM:'',
      IAH:'',
      IAM:'',
      IBH:'',
      IBM:'',
      JH:'',
      JM:'',
      LH:'',
      LM:'',
      CH:'',
      CM:'',
    }]
   

    let jsonDL={
      "categoria_federado": "dientes de leche",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonDM={
      "categoria_federado": "dientes de leche",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonDL).subscribe((res)=>{
      
      this.DLH=res.federadosDientesLecheHombresTotal;
      data[0].DlH=res.federadosDientesLecheHombresTotal;
  
      this.api.post("/getNumberFederadosByCat",jsonDM).subscribe((resM)=>{
        this.DLM=resM.federadosDientesLecheMujeresTotal;
        data[0].DlM=resM.federadosDientesLecheMujeresTotal;
      });
     

    });


    let jsonIAH={
      "categoria_federado": "infantil a",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonIAM={
      "categoria_federado": "infantil a",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonIAH).subscribe((res)=>{
      this.infantilA_h=res.federadosInfantilAHombresTotal;
      data[0].IAH=res.federadosInfantilAHombresTotal;
      this.api.post("/getNumberFederadosByCat",jsonIAM).subscribe((resM)=>{
        this.infantilA_m=resM.federadosInfantilAMujeresTotal;
        data[0].IAM=resM.federadosInfantilAMujeresTotal;

       
      });
     

    });

    let jsonIBH={
      "categoria_federado": "infantil b",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonIBM={
      "categoria_federado": "infantil b",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonIBH).subscribe((res)=>{
      this.infantilB_h=res.federadosInfantilBHombresTotal;
      data[0].IBH=res.federadosInfantilBHombresTotal;
     
      this.api.post("/getNumberFederadosByCat",jsonIBM).subscribe((resM)=>{
        this.infantilB_m=resM.federadosInfantilBMujeresTotal;
        data[0].IBM=resM.federadosInfantilBMujeresTotal;
   
      });
     

    });  

    let jsonJH={
      "categoria_federado": "juvenil",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonJM={
      "categoria_federado": "juvenil",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonJH).subscribe((res)=>{
        this.juvenil_h=res.federadosJuvenilHombresTotal;
        data[0].JH=res.federadosJuvenilHombresTotal;
    
      this.api.post("/getNumberFederadosByCat",jsonJM).subscribe((resM)=>{
       this.juvenil_m=resM.federadosJuvenilMujeresTotal;
       data[0].JM=resM.federadosJuvenilMujeresTotal;
   
      });
     

    });  

    let jsonLH={
      "categoria_federado": "libre",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonLM={
      "categoria_federado": "libre",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonLH).subscribe((res)=>{
        this.libre_h=res.federadosLibreHombresTotal;
        data[0].LH=res.federadosLibreHombresTotal;
      
      this.api.post("/getNumberFederadosByCat",jsonLM).subscribe((resM)=>{
       this.libre_m=resM.federadosLibreMujeresTotal;
       data[0].LM=resM.federadosLibreMujeresTotal;
     
      });
     

    });  


    let jsonCMH={
      "categoria_federado": "charros mayores",
      "pais_origen": "",
      "totales": true,
      "sexo": "h" 
    }
    let jsonCMM={
      "categoria_federado": "charros mayores",
      "pais_origen": "",
      "totales": true,
      "sexo": "m" 
    }
    this.api.post("/getNumberFederadosByCat",jsonCMH).subscribe((res)=>{
        this.charroM_h=res.federadosMayoresHombresTotal;
        data[0].CH=res.federadosMayoresHombresTotal;
      
      this.api.post("/getNumberFederadosByCat",jsonCMM).subscribe((resM)=>{
       this.charroM_m=resM.federadosMayoresMujeresTotal;
       data[0].CM=resM.federadosMayoresMujeresTotal;
      
      });
     

    });  


    
   //dataChartH.push(data)
  
    setTimeout(() => {
      console.log(data[0].DlH)
      dataChartH.push(data[0].DlH,data[0].IAH,data[0].IBH,data[0].JH,data[0].LH,data[0].CH)
      dataChartM.push(data[0].DlM,data[0].IAM,data[0].IBM,data[0].JM,data[0].LM,data[0].CM)
      let div = this.DLM+this.infantilA_m+this.infantilB_m+this.juvenil_h+this.libre_m+this.charroM_m+this.DLH+this.infantilA_h+this.infantilB_h+this.juvenil_h+this.libre_h+this.charroM_h
      console.log('data',parseInt(data[0].DlH+data[0].DlM))
      dataChartT.push(((parseInt(data[0].DlH+data[0].DlM)/div)*100).toFixed(),((parseInt(data[0].IAH+data[0].IAM)/div)*100).toFixed(),((parseInt(data[0].IBH+data[0].IBM)/div)*100).toFixed(),((parseInt(data[0].JH+data[0].JM)/div)*100).toFixed(),((parseInt(data[0].LH+data[0].LM)/div)*100).toFixed(),((parseInt(data[0].CH+data[0].CM)/div)*100).toFixed())
      this.barChartData= [
        
        { data: dataChartM ,label: 'Series Mujeres' },
        { data: dataChartH ,label: 'Series Hombres' }
       
       ];

       this.barChartData2= [
        
        { data: dataChartT ,label: 'Series C' },
      
       
       ];



       $("#dt").DataTable({
        
        deferLoading: false,
       
        bPaginate: false,
        ordering: false,
        bLengthChange: false,
        bFilter: false,
        bInfo: false,
        searching: false,
        bAutoWidth: true,
       
        scrollX:true,
        scrollCollapse: true,
     
      });
    }, 5000);
   

this.getAllUniones();


  }

getAllUniones(){
  this.api.get("/getAllUniones").subscribe((res) => {
    this.union=res.UnionArr
    console.log("Respuesta", this.union);
      
     });

}

  
}
