import { Component, OnInit } from "@angular/core";
import { FederadosService } from "../federados/services/federados.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-login-perfiles",
  templateUrl: "./login-perfiles.component.html",
  styleUrls: ["./login-perfiles.component.scss"],
})
export class LoginPerfilesComponent implements OnInit {
  public perfil:any;
  public visto: boolean = false;
  public asoc: string = "";
  public union: string = "";
  public curp: string = "";
  public folio: string = "";
  public asociacionName: any = [];
  public UnionName: any = [];
  constructor(
    public api: FederadosService,
    private router: Router,
    private _Activatedroute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.asociacionesGET();
    this.UnionesGET();
  }

  buscarDatos() {
    this._Activatedroute.params.subscribe((params: { Params }) => {
      if (params["id"] === "RegistroPresAsoc") {
        this.perfil="presidente asociacion";
       // console.log(params["id"]);
      }
      if (params["id"] === "RegistroPresUnion") {
        this.perfil="presidente union";
       // console.log(params["id"]);
      }
      if (params["id"] === "RegistroDeportista") {
        this.perfil="deportista";
       // console.log(params["id"]);
      }
      if (params["id"] === "RegistroJuez") {
        this.perfil="juez";
       // console.log(params["id"]);
      }
    });



    const regex = /[abcdeiou]/g;
    const formData = {
      union: this.union.toLowerCase(),
      asociacion: this.asoc.toLowerCase(),
      curp: this.curp.toUpperCase(),
      no_registroFMCH: this.folio.toUpperCase()
    };

    this.api.post("/getFederadoByCurpAsocUnionFolio", formData).subscribe((res) => {
        // console.log(res.federadoFound);
        if (res.federadoFound && res.federadoFound.length > 0) {
          console.log("Correcto");
          localStorage.setItem("nombreC", res.federadoFound[0].nombre_completo);
          this.router.navigateByUrl("/DetalleKiosko/" + res.federadoFound[0]._id+'/'+this.perfil);
        }
        
        if(res.federadoFound === undefined){
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: res.msg,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
        }

       
      });
  }
  asociacionesGET() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
         res.asocArr.forEach((element) => {
        const nombre = element.nombreAsociacion;
        
        this.asociacionName.push(nombre);
        console.log(this.asociacionName);
      });
    });
  }


  UnionesGET() {
    this.api.get("/getAllUniones").subscribe((res) => {
     
      res.UnionArr.forEach(e => {
        
        this.UnionName.push(e.nombre);
        
      });
      
    });
  }
}
