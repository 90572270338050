import { Subscription } from 'rxjs';
import { EventService } from './../services/event.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  templateUrl: 'pages.component.html',
  /* template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu" id="{{menu}}"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout> 
  `, */
})
export class PagesComponent implements OnInit, OnDestroy {

  public sus: Subscription;
  public activo: boolean = true;
  public w: number = 0;

  constructor(
    private eventService: EventService
  ) {
    this.w = window.innerWidth;
  }
  
  onResize(e){
    this.w = window.innerWidth;
  }

  menu = MENU_ITEMS;

  ngOnInit() {
    this.sus = this.eventService.get("sidemenu").subscribe(() => {
      this.activo = !this.activo;
    });
  }

  ngOnDestroy() {
    if (this.sus) {
      this.sus.unsubscribe();
    }
  }

  reload() {
    location.reload();
  }
}
