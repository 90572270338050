import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component,ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { Subscription } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';
import Swal from "sweetalert2";
import { FederadosService } from '../federados/services/federados.service';
import { environment } from './../../../environments/environment';
import { EventService } from './../../services/event.service';
import { LoaderService } from './../../services/loading-service';

@Component({
  selector: 'ngx-union',
  templateUrl: './union.component.html',
  styleUrls: ['./union.component.scss']
})
export class UnionComponent implements OnInit, OnDestroy {
  public datosUsuario:any;
  public suscription: Subscription;
  public suscription2: Subscription;
  public data: any = null;
  public generalForm: FormGroup;
  public uniones: any = [];
  public asociaciones: any = [];
  public create: boolean = false;
  public asocName:any=[];
  public unionName:any=[];
  public paises: any[] = [];

  public dataRender: any = [
    {
      id: "inp-nombre",
      formControl: "nombre",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-pais_origen",
      formControl: "pais_origen",
      label: "País",
      placeholder: "País...",
      required: true,
      defaultValue: "MEXICO",
      type: "autoCompletar",
      withError: false,
      dato: this.paises
    },
    {
      id: "inp-estado_origen",
      formControl: "estado_origen",
      label: "Estado",
      placeholder: "Jalisco...",
      required: true,
      defaultValue: "MEXICO",
      type: "autoCompletar",
      withError: false
    }, {
      id: "inp-calle",
      formControl: "calle",
      label: "Calle",
      placeholder: "Calle...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-colonia",
      formControl: "colonia",
      label: "Colonia",
      placeholder: "colonia...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-ciudad",
      formControl: "ciudad",
      label: "Ciudad",
      placeholder: "Guadalajara...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    }, {
      id: "inp-cp",
      formControl: "codigo_postal",
      label: "CP / ZIP CODE",
      placeholder: "89670...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "@facebook...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "@instagram...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-foto",
      formControl: "foto",
      label: "Logotipo",
      placeholder: "foto",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
      noVisual: true
    },

  ];

  /*
  {
      "cargo_integrante": "test integrante 1",
      "nombre_completo_integrante": "test integrante 1",
      "email_integrante": "test integrante 1",
      "telefono_integrante": "test integrante 1",
      "curp_integrante": "ERMO880918HGLDNR09"
    }
  */

  public mesa: any[] = [{
    cargo_integrante: "PRESIDENTE",
    curp_integrante: "",
    nombre_completo_integrante: "",
    telefono_integrante: "",
    email_integrante: ""
  },];

  public img: any = environment.urlServerimg;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private eventService: EventService,
    public api: FederadosService,
    private loadingService: LoaderService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    let objRender: any = {};
    this.dataRender.forEach(element => {
      let validators: any[] = [];
      validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
      if (element.required) {
        validators.push(Validators.required);
      }

      objRender[element.formControl] = validators;
    });

    //console.log(objRender);

    //this.generalForm = this.formBuilder.group(objRender);
  }

  mostrarpais() {
    this.api.get("/getAllPaises").subscribe((res) => {
      // console.log("equipoEXTERNO", res);

      res.paisArr.forEach((e:any) => {
        
        this.paises.push(e.nombrePais);
        
      });
      
    });
  }

  ngOnInit(): void {

    // Suscríbete al evento de actualización
    this.eventService.updateAsociacion$.subscribe((updatedData) => {
      // Actualiza la información local de la asociación en el array
      const index = this.asociaciones.findIndex(a => a.integrantes[0]._id === updatedData.id);
      if (index !== -1) {
        this.asociaciones[index].integrantes[0].nombre_completo_integrante = updatedData.nuevoNombrePresidente;
      }
      console.log(updatedData);
    });

    // // Suscribirse al evento de actualización para el botón "Sí"/"No"
    // this.eventService.toggleBotonSiNo$.subscribe((updatedData) => {
    //   // Actualiza la visibilidad del botón "Sí"/"No" específico
    //   const index = this.asociaciones.findIndex(a => a.fedId === updatedData.id);
    //   if (index !== -1) {
    //     this.asociaciones[index].isConfirmadoPres = updatedData.nuevoValor;

    //     // Forzar la detección de cambios manualmente
    //     this.cdr.detectChanges();
    //   }
    // });

    this.suscription2 = this.eventService.get("createUn").subscribe((data) => {
      this.create = true;
      this.dataRender.forEach(itm => {
        itm.defaultValue = "";
        if (itm.formControl == "foto") {
          itm.foto = "";
        }
      });

     

    });

    this.suscription = this.eventService.get("detalleUnion").subscribe((data) => {
      console.log("dtalle union");
      this.create = false;
      console.log("Test",data);

      this.data = data;
      this.mesa = this.data.current.integrantes;
      if (isArray(this.data.current.pais_origen)) {
        this.data.current.pais_origen = this.data.current.pais_origen[0];
      }
      if (isArray(this.data.current.estado_origen)) {
        this.data.current.estado_origen = this.data.current.estado_origen[0];
      }
      this.api.get(`/getAllAsociacionesByUnion/${this.data.id}`).subscribe(res => {
        if (res.message != "No hay union registrados" || !res.message) {
          this.asociaciones = res.asocArr;
        }
        res.asocArr.forEach(element => {
        
          this.asocName.push(element.nombreAsociacion);
          
        });
        console.log(this.asocName)

      });
      let objForm: any = {};
      this.dataRender.forEach(itm => {
        //if(itm.formControl.includes(itm[])){

        objForm[itm.formControl] = this.data.current[itm.formControl];
        if (itm.formControl == "foto") {
          this.dataRender[9]["foto"] = this.data.current[itm.formControl];
        } else {
          itm.defaultValue = this.data.current[itm.formControl];
        }
        //}
      });
      //console.log(this.dataRender[9]);

      //this.generalForm.setValue(objForm);

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scroll(0, 0); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);

    });
    this.mostrarUsario();
    this.mostrarpais();

    console.log("PAISES",this.paises);
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
    this.suscription2.unsubscribe();
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[9].defaultValue = files[0];
    this.dataRender[9].foto = "";

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.dataRender[9].fotob64 = reader.result;
    };

  }

  update() {
    this.loadingService.show("Actualizando...");
    let formData = new FormData();
    const headers = new HttpHeaders();
   
    headers.append("Content-Type", "multipart/mixed");
    headers.append("Accept", "application/json");


    let dataJson = {};
    this.dataRender.forEach(element => {
      if (element.formControl != "foto") {
        formData.append(element.formControl, element.defaultValue);
        dataJson[element.formControl] = element.defaultValue;
      }
    });

    if (this.dataRender[9].foto.length <= 0) {
      formData.append("importFile", this.dataRender[9].defaultValue);
      dataJson["importFile"] = this.dataRender[9].defaultValue;
    }else{
      formData.append("importFile", this.dataRender[9].foto);
      dataJson["importFile"] = this.dataRender[9].foto;
    }
    //console.log(formData);


    let urlupdate = "";
    let ss = null;

    if (this.mesa.length > 0) {
      dataJson["integrantes"] = [];
      this.mesa.forEach(m => {
        dataJson["integrantes"].push(m);
      });
    }

    //formData.append("integrantes", JSON.stringify(dataJson["integrantes"]));

    if (!this.create) {
      urlupdate = "/updateUnion/" + this.data.current._id;
      ss = this.api.update(urlupdate, dataJson, headers);
    } else {
      urlupdate = "/createUnion/";
      ss = this.api.post(urlupdate, dataJson);
    }


    this.changeDetectorRef.detectChanges();

    ss.subscribe((res: any) => {

      if (res) {
        // this.clearForm();
        // this.showWareHoouse();
        this.router.navigateByUrl('/usuario/u/warehouseCheck');
        let formDataImage = new FormData();
        formDataImage.append("importFile", this.dataRender[9].defaultValue);
        let idTmp = this.create ? res.newUnion._id : this.data.current._id;
        urlupdate = `/updateUnion/${idTmp}`;
        this.api.update(urlupdate, formData, headers).subscribe((resFile) => {
          this.loadingService.hide();
          // this.mesa = [{
          //   cargo_integrante: "PRESIDENTE",
          //   curp_integrante: "",
          //   nombre_completo_integrante: "",
          //   telefono_integrante: "",
          //   email_integrante: ""
          // },];
          
          Swal.fire({
            //position: "top-end",
            icon: "success",
            title: !this.create ? "Se actualizo la unión correctamente." : "Se creado la unión correctamente.",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
          
          setTimeout(() => {
            //location.reload();
          }, 1000);

        }, (error: HttpErrorResponse) => {
          this.loadingService.hide();
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: error.error.msg,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.clearForm();
          });
        });
      }


    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
      Swal.fire({
        //position: "top-end",
        icon: "error",
        title: error.error.msg,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        this.clearForm();
      });


    });
    
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará la unión permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingService.show("Eliminando...");
        const url2 = "/deleteUnion/" + this.data.id;
        this.api.delete(url2).subscribe((res3) => {
          this.loadingService.hide();
          //console.log("delete", res3);
          location.reload();
          //this.mostrarUnion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  clearForm() {
    this.create = false;
    this.data = null;
  }

  plus() {

    this.clearForm();
    this.router.navigateByUrl("/pages/union/registro");
  }

  mostrarFederado(id, curp) {

    if(this.mesa[id].curp_integrante.length === 18) {
      try {
        this.api.get(`/getFederadoByCurp/${curp}`).subscribe((res) => {

          this.mesa[id].nombre_completo_integrante = res[0].nombre_completo;
          this.mesa[id].email_integrante = res[0].email;
          this.mesa[id].telefono_integrante = res[0].cel;
  
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  nuevaMesa() {

    this.mesa.push({
      title: "",
      curp: "",
      nombre: "",
      celular: "",
      email: ""
    });

  }

  borraMesa(position) {
    this.mesa.splice(position, 1);
  }

  clicked(){
    document.getElementById('hf-logo').click();
  }

  userNamengmodelchange(value){

    this.api.get(`//getAllUniones/`).subscribe(res => {
      if (res.message != "No hay union registrados" || !res.message) {
        this.uniones = res.UnionArr;
        //console.log(this.asociaciones);

      }
      
      res.UnionArr.forEach(element => {
        const _id = element._id;
        const nombre = element.nombre;
        this.unionName.push({_id,nombre});
        
      });
      if (this.unionName.find(element => element.nombre ===value)) {
      const busqueda= this.unionName.find(element => element.nombre ===value);
      
        console.log(busqueda)
      }
      
      
    });
}

mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}


}
