import { AlertService } from './../../../services/alert.service';
import { TablaEquipoFedComponent } from './../../federados/tabla-by-equipo/tabla-by-equipo.component';
import { EventService } from './../../../services/event.service';
import { LoaderService } from './../../../services/loading-service';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericService } from './../../../services/generic.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { environment } from "../../../../environments/environment";
import { FederadosService } from "../../federados/services/federados.service";
import { Equipo } from "../../interface/interfaces";
import { MatDialog } from '@angular/material/dialog';
//import { TablaEquipoComponent } from '../../federados/tabla-by-equipo/tabla-by-equipo.component';
import { DetallesComponent } from '../../federados/detalles/detalles.component';
import { isArray } from 'rxjs/internal/util/isArray';
import { Subscription } from 'rxjs';

declare var $: any;
declare var XLSX;
@Component({
  selector: "ngx-tabla",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.scss"],
})
export class TablaEquipoComponent implements OnInit, OnDestroy {
  equipo: Equipo[] = [];
  public datosUsuario:any;
  public feds: any[] = [];

  hideModal: boolean;
  url: string;
  img: any;
  equipoId: any;

  public sus: Subscription = null;
  public sus2: Subscription = null;
  public sus3: Subscription = null;
  public data: any = null;
  public create: boolean = false;
  asociaciones: any;

  constructor(
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    private genericService: GenericService,
    private dialog: MatDialog,
    private loadingService: LoaderService,
    private eventService: EventService,
    private alertService: AlertService
  ) {
    this.img = environment.urlServerimg;
    this.url = "/getAllEquipos";
    this.mostrarEquipos();
    this.asociacionesGET();
  }

  ngOnInit(): void {
    // localStorage.removeItem('equipoId');
    this.sus = this.eventService.get("sss").subscribe((data) => {
      this.data = data;

      this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${this.data.current._id}`, {}).subscribe((response: any) => {
        this.feds = response.arrFed
        ;
      }, (error: HttpErrorResponse) => {

      });

    });

    this.sus3 = this.eventService.get("sss2").subscribe((data) => {
      this.create = true;
    });

    this.sus2 = this.eventService.get("rmv").subscribe((data) => {
      this.data = null;
      this.create = false;
    });
    this.mostrarUsario();
  }

  ngOnDestroy() {
    this.sus.unsubscribe();
    this.sus2.unsubscribe();
  }



  mostrarEquipos() {
    this.loadingService.show("Cargando...");
    this.api.get(this.url).subscribe((res) => {
      // console.log("Equipos", res);
      
      this.equipo = res?.eqArr;
      if (this.equipo && this.equipo.length > 50) {
        this.loadingService.hide();
        setTimeout(function () {
          $(function () {
            $("#dt").DataTable({
              bDestroy: true,

              bInfo: true,
              bProcessing: true,
              bDeferRender: true,
              iDisplayLength: 10,
              sPaginationType: "full_numbers",
              sDom: '<"top"i> T<"clear">lfrtip',
              sPageButtonActive: "paginate_active",
              sPageButtonStaticDisabled: "paginate_button",
              scrollY: "400px",
              scrollCollapse: true,
              stateSave: true,
              stateSaveCallback: function(settings,data) {
                  localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                },
              stateLoadCallback: function(settings) {
                return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
                }
            });
          });
        }, 3000);
      }


    });
  }

  /* edit(id) {
    this.router.navigateByUrl("pages/federados/detalles/" + id);
  } */

  edit2(id) {
    //console.log("entro editar union", "idunion", id);
    const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });
  }

  editNew(id) {
    //this.closeDialog();
    setTimeout(() => {
      //this.router.navigateByUrl("pages/federados/detalles/" + id);
      this.router.navigate(["pages", "federados", "detalles", id]);

    }, 500);
  }

  edit(id, item) {
    this.equipoId = id;
    console.log(item);

    console.log(id);


    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    this.loadingService.show("Cargando...");

    let url = "/getEquipoById/" + id;

    let data: any = {};
    data.id = id;
    this.api.get(url).subscribe((res) => {
      //console.log(res);
      localStorage.setItem('equipoPais', res.pais_origen);
      localStorage.setItem('nombreEquipo', `[${JSON.stringify(res.nombreEquipo)}]`);

      if (isArray(res?.estado_origen)) {
        res.estado_fundacion = res.estado_origen[0];
      }

      if (isArray(res?.asociacion)) {
        res.asociacion = res.asociacion[0];
      }

      if (isArray(res?.pais_origen)) {
        res.pais_fundacion = res.pais_origen[0];
      }

      if (isArray(res?.ciudad)) {
        res.ciudad = res.ciudad[0];
      }

      if (!res.equipo) {
        res.equipo = "";
      }

      if (isArray(res.equipo)) {
        res.equipo = res.equipo[0];
      }
      data.current = res;

      this.loadingService.hide();
      this.eventService.send("sss", data);
    });
    /* const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });
 
     dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
     });   */
  }

  delete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        const url2 = "/deleteEquipo/" + id;
        this.api.delete(url2).subscribe((res3) => {
          //console.log("delete", res3);
          this.mostrarEquipos();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  // getFederados(equipo: any) {
  //   this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${equipo._id}`, {}).subscribe((response: any) => {
  //     localStorage.setItem("federadosLoad", JSON.stringify(response));
  //     localStorage.setItem("nombreEquipo", JSON.stringify(equipo.nombreEquipo));
  //     const dialogRef = this.dialog.open(TablaEquipoFedComponent);

  //     dialogRef.afterClosed().subscribe(result => {
  //       //console.log(`Dialog result: ${result}`);
  //     });
  //   }, (error: HttpErrorResponse) => {

  //   });
  // }

  btnClick() {
    let id: any = document.getElementById("file");
    id.click();
  }

  fileChange(evt: any) {
    let file: any = evt?.target?.files[0];
    if (file && file?.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.alertService.errorAlert("Oops!", "Se espera un archivo .xlsx");
    } else {
      this.cargarMasivo(file);
    }
  }

  cargarMasivo(file: any): void {
    this.loadingService.show();
    const formData = new FormData();
    console.log(file);

    formData.append('importFile', file);
    this.genericService.sendPostRequest(environment.excelEquipos, formData).subscribe((r: any) => {
      this.alertService.successAlert("Bien!", "Archivo cargado correctamente");
      this.mostrarEquipos();
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }

  plus() {
    this.router.navigateByUrl("/pages/federados/detalles");
  }

  plus2() {
    //this.router.navigateByUrl("/pages/equipos/registro");
    console.log("------------->");

    this.eventService.send("sss2", null);
  }

  asociacionesGET() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      this.asociaciones = res;
    });
  }

  btnClick2() {
    let id: any = document.getElementById("fileFed");
    id.click();
  }

  fileChange2(evt: any) {
    let file: any = evt?.target?.files[0];
    if (file && file?.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.alertService.errorAlert("Oops!", "Se espera un archivo .xlsx");
    } else {
      this.cargarMasivo2(file);
    }
  }

  cargarMasivo2(file: any): void {
    this.loadingService.show();
    const formData = new FormData();
    console.log(file);

    formData.append('importFile', file);
    this.genericService.sendPostRequest(environment.importFederadosCSV, formData).subscribe((r: any) => {
      this.alertService.successAlert("Bien!", "Archivo cargado correctamente");
      this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${this.data.current._id}`, {}).subscribe((response: any) => {
        this.feds = response.arrFed;
      }, (error: HttpErrorResponse) => {

      });
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }


  mostrarUsario() {
    //this.api.get(this.url).subscribe((res) => {
     let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
     this.datosUsuario = res
    
    console.log(this.datosUsuario);
    // this.chang.detectChanges();
    //});
  
  }
}
