import { environment } from './../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericService } from './../../services/generic.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from './../../services/loading-service';
//import firebase from 'firebase'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginFeelComponent implements OnInit {

  public visto: boolean = false;   

  public user: string = "";
  public pass: string = "";

  public register: boolean = false;
  constructor(
    private loadingService: LoaderService,
    private alertService: AlertService,
    public router: Router,
    private genericService: GenericService,
    private dialog: MatDialog,
  ) {
  }



  ngOnInit() {

    localStorage.removeItem("userSessionFederacion");
    localStorage.removeItem("lastItems");
    localStorage.removeItem("language");
    localStorage.removeItem("DataTables_dewfeft");
    localStorage.removeItem("themeClass");
    localStorage.removeItem("theme");
   // localStorage.removeItem("tokenazo");
    localStorage.removeItem("DataTables_dt");
    
    setTimeout(() => {
      let objDiv = document.getElementById("btn-scroll");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
  }


  change() {
    let id: any = document.getElementById("input-pass");
    if (!this.visto) {
      id.type = "text";
      this.visto = true;
    } else {
      id.type = "password";
      this.visto = false;
    }
  }

  login() {
    if (this.user.length <= 0 || this.pass.length <= 0) {
      this.alertService.errorAlert("Oops", "Asegurate de haber ingresado usuario y contraseña");
    } else {
      let request: any = {
        email: this.user,
        password: this.pass
      }
      this.genericService.sendPostRequest(environment.login, request).subscribe((response: any) => {
        localStorage.setItem("tokenazo", JSON.stringify(response.token));
        this.genericService.sendGetRequestParams(`${environment.getFederadoById}/${response.id}`, request).subscribe((response2: any) => {
          response2[0].passwerd = this.pass;
          localStorage.setItem("userSessionFederacion", JSON.stringify(response2[0]));
          this.router.navigate(["/pages/estadisticas"]);
        }, (error: HttpErrorResponse) => {
          console.log(error);
          
          if (error?.error?.msg) {
            this.alertService.errorAlert("Oops", error?.error?.msg);
          } else {
            this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
          }
        });
      }, (error: HttpErrorResponse) => {
        console.log(error);
        if (error?.error?.msg) {
          this.alertService.errorAlert("Oops", error?.error?.msg);
        } else {
          this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
        }
      });
    }
  }

  openReg() {
   
      this.alertService.alertWithInputs(
        (valor: any) => {
          console.log(valor);
          this.loadingService.show();
          this.genericService.sendGetRequest(environment.urlServer+'/getAllFederados?limit=27000&page=1').subscribe((response: any) => {
          
            let busqueda=response.arrFed.find(element => element.email ===valor)
            console.log(busqueda)
            this.sendemail(valor,busqueda._id,busqueda.password);
            this.loadingService.hide();
          });
          
         //
        },
       
      );
    }

    sendemail(email,id, token){
   
    
      let request: any = {
        asunto: "Correo de Recuperacion",
        from: "soporte@fmch.org.mx",
        name: localStorage.getItem('nombreC'),
        to: email,
        cuerpo: `<section>
    <div style="background-color: #006b89;
    text-align: center;padding: 8px;">
        <p style="color: #fff;margin: 0;font-size: 20px;">Federacion Mexicana de Charreria</p>
    </div>
    <div style="padding: 10px;border: 1px solid #c8c8c8;">
      <p style="color: #000;">Se le ha mandado un Correo de Verificacion con el siguiente codigo ${token}  </p>
      
      <a href="http://localhost:8100/recuperar/${id}"><button style="color: #fff;
        background-color: #006b89;
        font-size: 16px;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 1px 1px 1px #123;
        margin-bottom: 20px;
        min-width: 200px;
        cursor: pointer;" >Validar Email</button></a>
  
      <p style="color: #000;">Da Click al Boton para entrar a validar tu correo</p>
      
    </div>
  </section>`,
      };
  
      this.genericService.sendPostRequest('https://api.fmch.org.mx/api/validateEmail', request) .subscribe(
          (response: any) => {
         
            //window.history.back();
            //se hace un update de token del usuario
            //this.fcmService.updateTokenUser();
            //
            this.alertService.successAlert(
              'Correo Enviado a Validacion',
              'Por Favor Revisa tu Correo para Cambiar tu Password'
            );})
    }

  }
