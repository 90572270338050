import { EventService } from './../../../../services/event.service';
import { LoaderService } from './../../../../services/loading-service';
import { Router } from '@angular/router';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { FederadosService } from '../../../federados/services/federados.service';
import { isArray } from 'rxjs/internal/util/isArray';
import { HttpHeaders } from '@angular/common/http';


declare var $: any;
@Component({
  selector: 'ngx-tabla-asociacion-union',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss']
})
export class TablaAsocUnioComponent implements OnInit {
  @Input() asociaciones: any[] = []; 

  hideModal: boolean;
  url: string;
  img: any;

  constructor(
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    private loadingService: LoaderService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef
  ) {
    this.img = environment.urlServerimg;
    this.url = '/getAllAsociaciones';
    console.log("---------------------------------------------");

    //this.mostrarAsociacion();

    setTimeout(function () {
       $(function () {
         $('#dtoiu').DataTable(
           {
             'bDestroy': true,
 
             "bInfo": true,
             "bProcessing": true,
             "bDeferRender": true,
             'iDisplayLength': 10,
             'sPaginationType': 'full_numbers',
             'sDom': '<"top"i> T<"clear">lfrtip',
             'sPageButtonActive': "paginate_active",
             'sPageButtonStaticDisabled': "paginate_button",
             "scrollY": "400px",
             "scrollCollapse": true,
           }
         );
       });
     }, 5990); 


  }

  ngOnInit(): void {

  }

  goToAsoc(item) {
    this.router.navigate(["pages", "asociacion", "tabla", item._id]);
  }


  getRandom() {
    return Math.ceil(Math.random() * (100 - 1) + 1);
  }

  edit(id) {

    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    //console.log("entro editar union", "idunion", id);
    this.loadingService.show("Cargando...");

    let url = "/getAsociacionById/" + id;



    let data: any = {};
    data.id = id;
    this.api.get(url).subscribe((res) => {
      //console.log(res);


      if (res.estado_origen && isArray(res.estado_origen)) {
        res.estado_fundacion = res.estado_origen[0];
      } else {
        res.estado_fundacion = res.estado_fundacion;
      }

      if (res.pais_origen && isArray(res.pais_origen)) {
        res.pais_fundacion = res.pais_origen[0];
      } else {
        res.pais_fundacion = res.pais_origen;
      }

      if (res.union && isArray(res.union)) {
        res.union = res.union[0];
      } else {
        res.union = res.union;
      }

      if (!res.propietario_ap_materno) {
        res.propietario_ap_materno = "";
      }

      if (!res.equipo) {
        res.equipo = "";
      }

      if (isArray(res.equipo)) {
        res.equipo = res.equipo[0];
      }

      if (!res.pais_fundacion) {
        res.pais_fundacion = "";
      }

      if (!res.estado_fundacion) {
        res.estado_fundacion = "";
      }
      data.current = res;
      //console.log(data);


      this.loadingService.hide();
      this.router.navigateByUrl("/pages/asociacion/tabla");
      setTimeout(() => {
        this.eventService.send("detalleAsociacionss", data);
      }, 500);
    });
    /* const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });
 
     dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
     });   */
  }

  editPresidente(id: string, confirmado: boolean, objAsoc: any){
    let urlFed = `/updateFederado/${id}`;
    let urlAsoc = `/updateAsociacion/${objAsoc._id}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    
    if(confirmado === true){
      this.api.update(urlFed, {confirmPresAsoc: confirmado, perfiles: [objAsoc.perfilPresAsoc] }, headers).subscribe((res) => {
        // console.log("Respuesta de actualización ", res);
      });
    }
    else {
      this.api.update(urlFed, {confirmPresAsoc: confirmado, perfiles: ['deportista'] }, headers).subscribe((res) => {
        // console.log("Respuesta de actualización ", res);
      });
    }

    const integrantes = [
      {
        cargo_integrante: objAsoc.integrantes[0].cargo_integrante,
        email_integrante: objAsoc.integrantes[0].email_integrante,
        nombre_completo_integrante: confirmado === true ? objAsoc.fedNombre : 'nombre completo test 1',
        telefono_integrante: objAsoc.integrantes[0].telefono_integrante,
        _id: objAsoc.integrantes[0]._id
      }
    ]

    this.api.update(urlAsoc, {integrantes}).subscribe((res) => {
      // console.log("Respuesta de actualización ", res);
      // Emitir el evento de actualización solo si hay cambios
      if (res.asociacionObj.integrantes && res.asociacionObj.integrantes.length > 0) {
        // Envía solo la información necesaria para actualizar
        const updatedData = {
          id: res.asociacionObj.integrantes[0]._id,
          nuevoNombrePresidente: res.asociacionObj.integrantes[0].nombre_completo_integrante,
        };

        // Emite el evento de actualización
        this.eventService.updateAsociacion(updatedData);
        // console.log(updatedData);
      }

      // Actualiza la visibilidad del botón "Sí"/"No" específico
      const asociacionIndex = this.asociaciones.findIndex(a => a.fedId === id);
      if (asociacionIndex !== -1) {
        this.asociaciones[asociacionIndex].isConfirmadoPres = confirmado;
        this.cdr.detectChanges(); // Forzar la detección de cambios
      }
      
    });
    
  }

  plus() {
    this.router.navigateByUrl("/pages/asociacion/registro");
  }
}
