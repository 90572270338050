import { Component, OnInit } from '@angular/core';
import { FederadosService } from "../federados/services/federados.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.scss'],
})
export class RecuperarPassComponent implements OnInit {
public codigo:any;
public pass:any;
public repass:any;
public codigoPass:any;
public ids: any = null;
  constructor(
    public api: FederadosService,
    public router: Router,
    private _Activatedroute: ActivatedRoute,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        this.ids = params["id"];
        
      })


    this.api.get(`/getFederadoById/${this.ids}`).subscribe((res) => {
      this.codigoPass=res[0].password;
      console.log(this.codigoPass)
    });
  }


login(){



}
confirmacion(){
  let JSON ={
    password:this.pass,
    
  }

  if(this.codigo==this.codigoPass&&this.pass==this.repass){

  
  let formData = new FormData();
  const headers = new HttpHeaders();
  headers.append("Content-Type", "multipart/form-data");
  headers.append("Accept", "application/json");
  formData.append("esPresAsoc", 'true');
  let url = "/updateFederado/" + this.ids;
  console.log(url)

  


  let sus = this.api.update(url, JSON, headers)
  sus.subscribe((res: any) => {
  
   console.log("Response del createFed => ",res);})
   this.router.navigate(["/login"]);
  }
  else{

    this.alertService.errorAlert("Oops", "Verifica que el codigo sea correcto y que los campos requerido esten correctos");
  }
  
}

mostrarcontrasena(tipo){
  if(tipo==1){
  let input
  input=document.getElementById('password')
  let span
  span=document.getElementById('spass')
  if(input.type=='password'){
    input.type='readOnly'
    span.innerreadOnly="visibility"
   // console.log(span.innerreadOnly)
  }else{
    input.type='password'
    span.innerreadOnly="visibility_off"
  //  console.log(span.innerreadOnly)

  }
  }

  if(tipo==2){
    let input
    input=document.getElementById('repassword')
    let span
    span=document.getElementById('srepass')
    if(input.type=='password'){
      input.type='readOnly'
      span.innerreadOnly="visibility"
     // console.log(span.innerreadOnly)
    }else{
      input.type='password'
      span.innerreadOnly="visibility_off"
    //  console.log(span.innerreadOnly)

    }
    }

}

}
