import { Injectable } from "@angular/core";
import { catchError } from "rxjs/internal/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { of } from "rxjs";
const endpoint = "https://api.fmch.org.mx/api/";
export interface Federados {
  nombre_completo: string;
  edad: string;
  email: string;
  password: string;
  nombre: string;
  ap_paterno: string;
  ap_materno: string;
  fecha_nac: string;
  curp: string;
  sexo: string;
  pais_origen: string;
  estado_origen: string;
  curp_tutor: string;
  foto: string;
  domicilio_pais: string;
  domicilio_cp: string;
  clave_pais: string;
  cel: string;
  tipo_federado: string;
  categoria_federado: string;
  seguro_costo: string;
  seguro_status: string;
  seguro_poliza: string;
  seguro_fecha_pago: string;
  seguro_vigencia: string;
  fecha_pago_cuota_federacion: string;
  cuota_costo: string;
  comprobante_pago_url: string;
  vencimiento: string;
  status_pago: string;
  asociacion: string;
  equipo: string;
  perfil1: string;
  perfil2: string;
}

@Injectable({
  providedIn: "root",
})
export class FederadosService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  public api = environment.urlServer;

  post(urlApi, data) {
    const url = environment.urlServer + urlApi;
    return this.http.post(url, data).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error.error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  post2(urlApi, data, headers) {
    const url = environment.urlServer + urlApi;
    return this.http.post(url, data, headers).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error.error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  postheaders(urlApi:string, headers) {
    const url =  urlApi;
    return this.http.post(url, headers).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error.error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  update(urlApi, data, headers?: any) {
    const url = environment.urlServer + urlApi;
   //console.log(data);
    return this.http.put(url, data, headers && headers).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error.error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }
  destroy(urlApi, data) {
    const url = environment.urlServer + urlApi;
    return this.http.delete(url, data).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error.error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  public exportXLSX(urlApi: string){
    const url = environment.urlServer + urlApi;
    return this.http.get(url, {observe:'response', responseType:'blob'});
  }

  get(urlApi: string) {
    const url = environment.urlServer + urlApi;
    return this.http.get<Federados>(url).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  get2(urlApi: string, data) {
    const url = environment.urlServer + urlApi;
    return this.http.get<Federados>(url, data).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  delete(urlApi: string) {
    const url = environment.urlServer + urlApi;
   //console.log(url);
    return this.http.delete<Federados>(url).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
         //console.log(`Error: ${error.error.message}`);
        } else {
         //console.log(error);
          return of(error.error);
        }
        return of([]);
      })
    );
  }

  getProducts(): Observable<any> {
    return this.http
      .get<Federados>(this.api + "/getAllFederados")
      .pipe(catchError(this.handleError));
  }

  getProduct(id: string): Observable<any> {
    return this.http
      .get<Federados>(this.api + "/getFederadoById/" + id)
      .pipe(catchError(this.handleError));
  }

  addProduct(federado: any): Observable<any> {
    return this.http
      .post(this.api + "/createFederadoUser", federado)
      .pipe(catchError(this.handleError));
  }

  updateProduct(id: string, federado: Federados): Observable<any> {
    return this.http
      .put<Federados>(this.api + "/updateFederado/" + id, federado)
      .pipe(catchError(this.handleError));
  }

  deleteProduct(id: string): Observable<any> {
    return this.http
      .delete<Federados>(this.api + "/getFederadoById/" + id)
      .pipe(catchError(this.handleError));
  }

  getNumeroConsecutivo(): Observable<any> {
    return this.http.get<any>(this.api + "/getNumeroConsecutivo")
      .pipe(catchError(this.handleError));
  }

  createNumeroConsecutivo(no_registroFMCH: string): Observable<string> {
    // console.log(no_registroFMCH);
    return this.http.post<any>(this.api + "/createNumeroConsecutivo", {no_registroFMCH})
      .pipe(catchError(this.handleError));
  }

  uploadPhoto(b64: string): Observable<string> {
    return this.http.post<any>(this.api + "/uploadPhoto", {b64})
      .pipe(catchError(this.handleError));
  }
}
