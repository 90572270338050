import { UtilService } from './../../services/util.service';

import { CredencialPage } from './../../pages/credencial/credencial.page';
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { isPlatformBrowser } from '@angular/common';
import { Component, EmbeddedViewRef, OnInit, Inject, PLATFORM_ID,ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { Asociacion, Equipo, Federados, Union } from "../interface/interfaces";
import { FederadosService } from "../federados/services/federados.service";
import { LoaderService } from './../../services/loading-service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ActionSheetController, MenuController, ModalController } from '@ionic/angular';
import { GenericService } from 'src/app/services/generic.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SqlGenericService } from 'src/app/services/sqlGenericService';
import { AppModule } from '../../app.module';
import { AlertService } from '../../services/alert.service';
import { Console } from 'console';
declare var $:any
@Component({
  selector: 'app-registrokiosko',
  templateUrl: './registrokiosko.component.html',
  styleUrls: ['./registrokiosko.component.scss'],
})
export class RegistrokioskoComponent implements OnInit {
 
 public repass: any=null
  // ls: any = JSON.parse(localStorage.getItem('userSessionFederacion'));
  // public userPerfil: String = this.ls.perfiles[0];
  public isCredencialBtnDisabled: Boolean = false;
  public anos:string[]=[];
  public meses:any[]=[];
  public dias:any[]=[];
  public b64Image: any = "";
  FederadosList: {};

  hideModal: boolean;
  url: string;
  urlupdate: string;
  filename2: any;
  file2: "";
  federadosList: Federados[] = [];
  asociacion: Asociacion[] = [];
  asociacionesList: string[];
  equipo: Equipo[] = [];
  equipoList: string[] = [];
  equipoData: any[];
  unionesList: Union[] = [];
  termSubscriber: Subscription;
  url1: string;
  url2: string;
  img: any;
  ids: any;
  aso: any;
  equi: any;

  public data: any = null;

  public dataRender: any = [
    {
      fieldset: [
        {
          id: "inp-nombre",
          formControl: "nombre",
          label: "Nombre",
          placeholder: "NOMBRE",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-ap_paterno",
          formControl: "ap_paterno",
          label: "Apellido Paterno",
          placeholder: "APELLIDO PATERNO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-ap_materno",
          formControl: "ap_materno",
          label: "Apellido Materno",
          placeholder: "APELLIDO MATERNO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-perfil",
          formControl: "perfiles",
          label: "Perfil",
          placeholder: "Perfil",
          required: false,
          defaultValue: "deportista",
          type: "text",
          withError: false
        },
       
      ],
      legend: "NOMBRE COMPLETO"
    },
    {
      fieldset: [
        {
          id: "inp-curp",
          formControl: "curp",
          label: "Curp",
          placeholder: "CURP",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-fecha_nac",
          formControl: "fecha_nac",
          label: "Fecha de Nacimiento",
          placeholder: "0000-00-00",
          required: true,
          defaultValue: "1984-01-01",
          type: "date",
          withError: false
        },  
        {
          id: "inp-sexo",
          formControl: "sexo",
          label: "Sexo",
          placeholder: "SEXO",
          required: true,
          defaultValue: "H",
          type: "autoCompletar",
          withError: false,
          dato: ['H', 'M']
        }, 
        {
          id: "inp-pais_origen",
          formControl: "pais_origen",
          label: "País de Union",
          placeholder: "PAIS UNION",
          required: true,
          defaultValue: "MEXICO",
          type: "autoCompletar",
          withError: false,
          dato: ['MEXICO', 'USA']
        },
        {
          id: "inp-estado_origen",
          formControl: "estado_origen",
          label: "Union",
          placeholder: "UNIÓN",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: []
        },
        {
          id: "inp-asociacion",
          formControl: "asociacion",
          label: "Asociación",
          placeholder: "ASOCIACIÓN",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: [],
        },
        {
          id: "inp-tipo_federado",
          formControl: "tipo_federado",
          label: "Tipo Federado",
          placeholder: "Tipo Federado",
          required: true,
          defaultValue: "Charro",
          type: "autoCompletar",
          withError: false,
          dato: ["Charro","ESCARAMUZA"]
        },
        {
          id: "inp-folio",
          formControl: "no_registroFMCH",
          label: "Folio FMCH",
          placeholder: "#####",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-equipo",
          formControl: "equipo",
          label: "Equipo",
          placeholder: "EQUIPO",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: []
        },
       

      ],

      legend: "FECHA Y LUGAR DE NACIMIENTO"
    },
    {
      fieldset: [
        {
          id: "inp-email",
          formControl: "email",
          label: "Email",
          placeholder: "CORREO ELECTRÓNICO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-password",
          formControl: "password",
          label: "Contraseña",
          placeholder: "CONTRASEÑA",
          required: true,
          defaultValue: "",
          type: "password",
          withError: false
        },
        {
          id: "inp-clave_pais",
          formControl: "clave_pais",
          label: "CÓDIGO DE PAÍS +52 (MX) ó +1 (USA)",
          placeholder: "CODIGO DE PAIS",
          required: true,
          defaultValue: "+52",
          type: "text",
          withError: false
        },
        {
          id: "inp-cel",
          formControl: "cel",
          label: "Cel",
          placeholder: "CELULAR (10 DÍGITOS)",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-domicilio_pais",
          formControl: "domicilio_pais",
          label: "País Residencia",
          placeholder: "DOMICILIO PAIS",
          required: true,
          defaultValue: "MEXICO",
          type: "autoCompletar",
          withError: false,
          dato: ['Mexico', 'USA']
        }, {
          id: "inp-domicilio_cp",
          formControl: "domicilio_cp",
          label: "CP/ZIP CODE de Residencia",
          placeholder: "CÓDIGO POSTAL / ZIP CODE",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-colonia",
          formControl: "domicilio_colonia",
          label: "COLONIA",
          placeholder: "COLONIA",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },

        {
          id: "inp-calle",
          formControl: "domicilio_calle",
          label: "CALLE Y NUMERO",
          placeholder: "CALLE Y NUMERO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },

        {
          id: "inp-entidad",
          formControl: "domicilio_entidad",
          label: "ENTIDAD DE NACIMIENTO",
          placeholder: "ENTIDAD DE NACIMIENTO",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: [
            'AGUASCALIENTES',
            'BAJA CALIFORNIA',
            'BAJA CALIFORNIA NORTE',
            'BAJA CALIFORNIA SUR',
            'CAMPECHE',
            'COAHUILA',
            'COLIMA',
            'CHIAPAS',
            'CHIHUAHUA',
            'CIUDAD DE MEXICO',
            'DISTRITO FEDERAL',
            'DURANGO',
            'GUANAJUATO',
            'GUERRERO',
            'HIDALGO',
            'JALISCO',
            'MEXICO',
            'MICHOACAN',
            'MORELOS',
            'NAYARIT',
            'NUEVO LEON',
            'OAXACA',
            'PUEBLA',
            'QUERETARO',
            'QUINTANA ROO',
            'SAN LUIS POTOSI',
            'SINALOA',
            'SONORA',
            'TABASCO',
            'TAMAULIPAS',
            'TLAXCALA',
            'VERACRUZ',
            'YUCATAN',
            'ZACATECAS',
            'NACIDO EN EL EXTRANJERO',
          ],
        },
      ], legend: "DOMICILIO ACTUAL"
    },

    {
      fieldset: [
        {
          id: "inp-nombre_completo",
          formControl: "nombre_tutor",
          label: "NOMBRE COMPLETO TUTOR",
          placeholder: "NOMBRE COMPLETO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },

        {
          id: "inp-parentesco",
          formControl: "parentesco_tutor",
          label: "Parentesco",
          placeholder: "PARENTESCO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-cel_emergencias",
          formControl: "cel_emergencias",
          label: "Cel para emergencias",
          placeholder: "CEL PARA EMERGENCIAS",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-curp_tutor",
          formControl: "curp_tutor",
          label: "CURP Benef o Tutor",
          placeholder: "CURP",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, 
        {
          id: "inp-escolaridad",
          formControl: "escolaridad",
          label: "Escolaridad",
          placeholder: "ESCOLARIDAD",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: [
            'Primaria',
            'Secundaria',
            "Media Superior",
            "Licenciatura",
            "Otro",
          ]
        },
      
      ], legend: "DATOS DE CONTACTO"
    },
    
    //4
    {
      fieldset: [
        {
          id: "inp-facebook",
          formControl: "facebook",
          label: "Facebook",
          placeholder: "@FACEBOOK",
          required: true,
          defaultValue: "@FACEBOOK",
          type: "text",
          withError: false
        },
       
        {
          id: "inp-instagram",
          formControl: "instagram",
          label: "instagram",
          placeholder: "@INSTAGRAM",
          required: true,
          defaultValue: "@INSTAGRAM",
          type: "text",
          withError: false
        },
       
        {
          id: "inp-twitter",
          formControl: "twitter",
          label: "twitter",
          placeholder: "@TIKTOK",
          required: true,
          defaultValue: "@TIKTOK",
          type: "text",
          withError: false
        },

      ], legend: "REDES SOCIALES  "
    },
   //5

   { 
    fieldset:[
      {      
      id: "inp-status_pago",
      formControl: "status_pago",
      label: "Estatus Pago Federación",
      placeholder: "Estatus pago",
      required: true,
      defaultValue: "Expirado",
      type: "autoCompletar",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-seguro_status",
      formControl: "seguro_status",
      label: "Seguro Estatus",
      placeholder: "Seguro Estatus",
      required: true,
      defaultValue: "Expirado",
      type: "autoCompletar",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-comprobante_pago_url",
      formControl: "comprobante_pago_url",
      label: "Comprobante",
      placeholder: "Comprobante...",
      required: true,
      defaultValue: "pendiente",
      type: "pago",
      withError: false,
      
    },
  ], legend: "DATOS DE AFILICION GENERADOS POR SISTEMAS"

   },
  //6
    {
      id: "inp-categoria_federado",
      formControl: "categoria_federado",
      label: "Categoría Federado",
      placeholder: "Categoría",
      required: true,
      defaultValue: "pendiente",
      type: "autoCompletar",
      withError: false,
      dato: [],
    },
    //7
    {
      id: "inp-cuota_costo",
      formControl: "cuota_costo",
      label: "Cuota Federación",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },
    //8
    {
      id: "inp-fecha_pago_cuota_federacion",
      formControl: "fecha_pago_cuota_federacion",
      label: "Cuota Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },

  //9
    {
      id: "inp-seguro_poliza",
      formControl: "seguro_poliza",
      label: "Seguro póliza",
      placeholder: "555555555",
      required: true,
      defaultValue: "pendiente",
      type: "autoCompletar",
      withError: false,
      dato: []
    },

    //10
    {
      id: "inp-seguro_costo",
      formControl: "seguro_costo",
      label: "Seguro Costo",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },

    //11
    {
      id: "inp-seguro_fecha_pago",
      formControl: "seguro_fecha_pago",
      label: "Seguro Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },
    //12
   
    {
      derecha: [
        {
          id: "inp-foto",
          formControl: "foto",
          label: "foto",
          placeholder: "foto",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false,
          noVisual: true
        },
        
       
        
      ]
    },
    
  ];

  public nombre_completo: any = "";

  public img2: any = environment.urlServerimg;

  public fed: any = null;

  public id: any = null;

  public datosFederadoByCurp: any = {};
  public federadoCurpValid: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public formBuilder: FormBuilder,
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    private loadingService: LoaderService,
    private modalCtrl: ModalController,
    private utilService: UtilService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private alertService: AlertService,    
    private genericService: GenericService,
    //@Inject(MAT_DIALOG_DATA) public data: any,
    //public dialogRef: MatDialogRef<any>,
  ) {
    
    let objRender: any = {};

    this.dataRender.forEach(element => {
      if (!element.fieldset) {
        let validators: any[] = [];
        validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
        if (element.required) {
          validators.push(Validators.required);
        }

        objRender[element.formControl] = validators;
      } else {
        element.fieldset.forEach(element => {
          let validators: any[] = [];
          validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
          if (element.required) {
            validators.push(Validators.required);
          }

          objRender[element.formControl] = validators;
        });
      }
    });

    // console.log(this.dataRender);
    
  }

  searchFederadoByCurp() {
    const inputCurpValue = this.dataRender[1].fieldset[0].defaultValue;
    // console.log("searchFederadoByCurp input curp ",inputCurpValue);
    
    if(inputCurpValue == 'AMERICANO') {
      this.federadoCurpValid = true;
      return;
    }

    if(inputCurpValue.length === 18) {
      this.api.get(`/getFederadoByCurp/${inputCurpValue}`).subscribe((res) => {

if (res[0]){
        let objForm: string ;
        this.dataRender[1] .fieldset.forEach(itm => {
          let objForm: any = {};
          
          objForm=res[0];
          let btn
          btn=document.getElementById('btn_generar')
          btn.style.display="none"
          if(itm.formControl!='no_registroFMCH'&&itm.formControl!='fecha_nac' ){
           // console.log(itm.formControl)  
          itm.defaultValue=objForm[itm.formControl];}
          if(itm.formControl=='fecha_nac' ){
            let fecha = objForm[itm.formControl];
            let partes = fecha.split("-");
            let mes = partes[1];
            let dia = partes[2];
            let ano = partes[0];
            let mesValue
            mesValue=document.getElementById('mes')
            mesValue.value=mes
            let diaValue
            diaValue=document.getElementById('dia')
            diaValue.value=dia
            let anoValue
            anoValue=document.getElementById('ano')
            anoValue.value=ano

               // console.log( )


          }



        });
        this.dataRender[0] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          
          if(itm.formControl!='perfiles'){
          itm.defaultValue=objForm[itm.formControl];}
        });

        this.dataRender[2] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });
        this.dataRender[3] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });
        this.dataRender[4] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });


        if(res.length >= 1) {
         // this.router.navigateByUrl("pages/federados/detalles/" + res[0]._id);
          this.federadoCurpValid = true;
        } else {
        //  console.log('Curp no Registrado');
          this.federadoCurpValid = false;
        }
      }
      else  {
        this.apiCurp(inputCurpValue)
      }
      });
    }
  }

  // buscarEstados() {
  //   this.api.get("/getAllEstados").subscribe((res) => {
  //     res.edoArr.forEach(element => {
  //       this.dataRender[1].fieldset[4].dato.push(element.nombreEstado);
  //     });
  //   });
  // }

  buscaEquipos(asociacion) {
    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    this.loadingService.show("Cargando...");
    this.dataRender[1].fieldset[8].dato=[]
    this.api.get("/getAllEquipos").subscribe((res) => {
      
      let a = res.eqArr.map(e=>{
      if(e.asociacion == asociacion){
     // console.log(e.nombreEquipo)
      this.dataRender[1].fieldset[8].defaultValue=e.nombreEquipo;
      this.dataRender[1].fieldset[8].dato.push(e.nombreEquipo)
      this.loadingService.hide();
    }
      
      });
      
    });
    
    console.log(this.dataRender[1].fieldset[8].dato)
  }

  buscaAsociacion(union){

   
    this.loadingService.show("Cargando...");
    this.dataRender[1].fieldset[5].dato=[]
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      
      let a = res.asocArr.map(e=>{
      if(e.union == union){
      //console.log(e.nombreAsociacion)
      this.dataRender[1].fieldset[5].defaultValue=e.nombreAsociacion;
      this.dataRender[1].fieldset[5].dato.push(e.nombreAsociacion)
      this.loadingService.hide();
    }
      
      });
      
    });
    
   // console.log(this.dataRender[1].fieldset[5].dato)

  }


  btn() {
    document.getElementById("hf-logo").click();
    let Credencial
    Credencial=document.getElementById('credencial')
    Credencial.classList.add('cover');
   
  }

  buscaAsociaciones() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      res.asocArr.forEach(element => {
        this.dataRender[1].fieldset[5].dato.push(element.nombreAsociacion);
      });
    });
  }

  buscaUniones(){
    this.api.get("/getAllUniones").subscribe((res) => {
      res.UnionArr.forEach(union => {
        this.dataRender[1].fieldset[4].dato.push(union.nombre);
          })
          this.disableInputs()
    })
  }

  buscarCategorias() {
    this.api.get("/getAllCategorias").subscribe((res) => {
      res.forEach(element => {
        this.dataRender[6].dato.push(element.descripcion_categoria);
      });
    });
  }

  buscarPolizas() {
    this.api.get("/getAllPolizas").subscribe((res) => {
      // console.log(res);
      
      res.forEach(element => {
        this.dataRender[9].dato.push(element.nombre_poliza);
      });
    });
  }

  public validatePerfil():any{
    // if(this.userPerfil === 'superadmin'){
    //   return this.isAsocEdoDisabled = false;
    // }
    // if(this.userPerfil !== 'superadmin' || this.userPerfil !== 'funcionario'){
      // console.log("datarender ",this.dataRender);
      // console.log("LS ==> ",this.ls);
      let newDataRenderObj = this.dataRender.map((arr: any) => {
      //  console.log("ARR.FIELDSET => ",arr);
        const newArr = {...arr};
        // let fSArr = [];

        if(arr.legend === 'DATOS DE AFILICION GENERADOS POR SISTEMAS'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-status_pago'){
              newFieldsetArr.type = 'disabled';
            }
            
            if(a.id === 'inp-seguro_status'){
              newFieldsetArr.type = 'disabled';
            }
            
            if(a.id === 'inp-comprobante_pago_url'){
              newFieldsetArr.type = 'disabled';
            }
            return newFieldsetArr;
          })
        }

        if(arr.legend === 'NOMBRE COMPLETO'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-perfil'){
              newFieldsetArr.type = 'disabled';          
            }
            return newFieldsetArr;
          })
        }
        
        if(arr.legend === 'FECHA Y LUGAR DE NACIMIENTO'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-folio'){
              newFieldsetArr.type = 'disabled';
              this.api.getNumeroConsecutivo().subscribe((res) => {
                if(res.length > 0){
                  newFieldsetArr.defaultValue = `${res.numCons[0].no_registroFMCH}`; 
                }                
              })
                       
            }
            return newFieldsetArr;
          })
        }
        
        
        if(arr.id === 'inp-categoria_federado'){
          newArr.type = 'disabled';
        }

        if(arr.id === 'inp-cuota_costo'){
          newArr.type = 'disabled';
        }
        
        if(arr.id === 'inp-fecha_pago_cuota_federacion'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_poliza'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_costo'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_fecha_pago'){
          newArr.type = 'disabled';          
        }        
        
        return newArr;
      })
      
      this.dataRender = newDataRenderObj;
      
    // } 
  }

  ngOnInit(): void {
    localStorage.removeItem('equipoPais');
    localStorage.removeItem('nombreEquipo');
    localStorage.removeItem('emailFed');
    this.addNumeros()
    $('.modal').insertAfter($('body'));
    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    if(localStorage.getItem('photoFileObjFront') !== null /*&& localStorage.getItem('photoFileObjBack') !== null*/){
      localStorage.removeItem('photoFileObjFront');
      // localStorage.removeItem('photoFileObjBack');
    }
    this.loadingService.show("Cargando...");
    this.utilService.scrollTo(document.getElementById("bodyt"), 0, 0);
    //this.buscaEquipos();
    this.buscaAsociaciones();
    this.buscaUniones();
    // this.buscarEstados();
    this.buscarCategorias();
    this.buscarPolizas();
    this.validatePerfil();
    this.hideModal = false;
   
    this.termSubscriber = this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        // console.log(params);
        
        this.ids = params["id"];
        if (this.ids) {
          this.id = this.ids;
          
        }


        if (!this.ids) {
          this.data = { id: this.ids };
          this.ids = this.data.id;
          
        }
        //console.log(this.ids);
        if (this.id) {
          this.url = "/getFederadoById/" + this.ids;
          this.api.get(this.url).subscribe((res) => {
            
            //console.log("federados", res);
            this.fed = res[0];

            this.nombre_completo = res[0].nombre_completo;

            res.forEach((res) => {
              // console.log(res);

              this.ids = res._id
              this.urlupdate = "/updateFederado/" + this.ids;
              this.img = res.foto
              if (res.foto) {
                this.img = environment.urlServerimg + res.foto;
                //console.log(this.img);
              }

              let objForm: any = {};
              this.dataRender.forEach(itm => {
                //if(itm.formControl.includes(itm[])){
                if (!itm.fieldset && !itm.derecha) {
                  objForm[itm.formControl] = res[itm.formControl];
                  itm.defaultValue = res[itm.formControl];

                  if (itm.formControl == "foto") {
                    //console.log("FOOOOOOTOOOOO");
                    //console.log(itm);

                    this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];

                  }
                } else if (itm.derecha) {
                  itm.derecha.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                      this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      this.loadingService.hide();
                    }
                  });

                } else {
                  itm.fieldset.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                      this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      
                    }
                  });

                }
                //}
              });
              // console.log(this.dataRender[12].derecha[0]);

              //this.generalForm.setValue(objForm);
            });
          });
          
        } else {
          this.dataRender[12].derecha[0]["foto"] = "";
          this.loadingService.hide();
        }
      }
      
    );
   
    // if(this.userPerfil === 'funcionario' || this.userPerfil === 'superadmin'){
    //   this.isCredencialBtnDisabled = false;
    // }
    // else this.isCredencialBtnDisabled = true;
 
  }


  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
  }

  onImageChange(e) {
    //console.log(e.target.files)
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[12].derecha[0].defaultValue = files[0];
    this.dataRender[12].derecha[0].foto = "";

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.dataRender[12].derecha[0].fotob64 = reader.result;
    };

  }

  validateInputs() {
    let isInputValid = true;
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
    this.dataRender.forEach(element => {
      if(element.fieldset) {
        element.fieldset.forEach(e => {
          if(e.defaultValue == '') {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });
          };
        });
      }else if(element.derecha) {
        
        element.derecha.forEach(e => {
          if(e.defaultValue == '' && e.label !== 'Comprobante' ) {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });

          };
        });
      }else {
        if(element.defaultValue == '' && element.id !== 'inp-facebook' && element.id !== 'inp-instagram' && element.id !== 'inp-twitter') {
          isInputValid = false;
          Swal.fire({
            //position: "top-end",
            icon: "success",
            title: "Faltan Datos por en  " + element.label,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
        };
      }
    });

    return isInputValid;
  }

  addFederados() {

   
    //console.log(this.validateInputs());
    let mes
      mes=document.getElementById('mes')
      
      let dia
      dia=document.getElementById('dia')
      
      let ano
      ano=document.getElementById('ano')
    

      this.dataRender[1].fieldset[1].defaultValue=ano.value+"-"+mes.value+"-"+dia.value
      //console.log(this.dataRender[1].fieldset[1].defaultValue)


    if(this.validateInputs()&&this.validarcontrasena()&&this.validarcheckbox()&&this.validacionUnion()) {
      this.loadingService.show("Actualizando");
      let formData = new FormData();
      const headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Accept", "application/json");
      //formData.append("email", this.generalForm.value.email);
      /*  formData.append("nombre", this.generalForm.value.nombre);
       formData.append("ap_paterno", this.generalForm.value.ap_paterno);
       formData.append("ap_materno", this.generalForm.value.ap_materno);
       formData.append("fecha_nac", this.generalForm.value.fecha_nac);
       formData.append("curp", this.generalForm.value.curp);
       formData.append("sexo", this.generalForm.value.sexo);
       formData.append("pais_origen", this.generalForm.value.pais_origen);
       formData.append("estado_origen", this.generalForm.value.estado_origen);
       formData.append("importFile", this.file2);
       formData.append("domicilio_pais", this.generalForm.value.domicilio_pais);
       formData.append("domicilio_cp", this.generalForm.value.domicilio_cp);
       formData.append("clave_pais", this.generalForm.value.clave_pais);
       formData.append("cel", this.generalForm.value.cel);
       formData.append("tipo_federado", this.generalForm.value.tipo_federado);
       formData.append(
         "categoria_federado",
         this.generalForm.value.categoria_federado
       );
       formData.append("seguro_costo", this.generalForm.value.seguro_costo);
       formData.append("seguro_status", this.generalForm.value.seguro_status);
       formData.append("seguro_poliza", this.generalForm.value.seguro_poliza);
       formData.append(
         "seguro_fecha_pago",
         this.generalForm.value.seguro_fecha_pago
       );
       formData.append("seguro_vigencia", this.generalForm.value);
       formData.append(
         "fecha_pago_cuota_federacion",
         this.generalForm.value.fecha_pago_cuota_federacion
       );
       formData.append("cuota_costo", this.generalForm.value.cuota_costo);
       formData.append(
         "comprobante_pago_url",
         this.generalForm.value.comprobante_pago_url
       );
       formData.append("vencimiento", this.generalForm.value.vencimiento);
       formData.append("status_pago", this.generalForm.value.status_pago);
       formData.append("asociacion", this.generalForm.value.asociacion);
       formData.append("equipo", this.generalForm.value.equipo);
       formData.append("perfil1", this.generalForm.value.perfiles);
    */
      //formData.append("perfil2", this.federadosForm.value.perfil2);
  
      let dataJson = {};
      this.dataRender.forEach(element => {
       
        if (!element.fieldset && !element.derecha ) {
          if (element.defaultValue == undefined) {
            element.defaultValue = "";
          }
       
          if (element.formControl == "foto") {
          } else {
            formData.append(element.formControl, String(element.defaultValue));
            dataJson[element.formControl] = element.defaultValue;
          }
  
        } else if (element.derecha) {
          element.derecha.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
             if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
          });
        } else {
       //   console.log("datos",element.fieldset)
          element.fieldset.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
            if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
            
            if(itm.formControl === 'password'){
              localStorage.setItem('pass', itm.defaultValue);
            }
  
          });
        }
  
      });
      formData.delete("foto");
      /* formData.append("importFile", this.dataRender[12].derecha[0].defaultValue); */
  
      if (this.dataRender[12].derecha[0].foto.length <= 0) {
        formData.append("importFile", this.dataRender[12].derecha[0].defaultValue);
        dataJson["importFile"] = this.dataRender[12].derecha[0].defaultValue;
      } else {
        formData.append("importFile", this.dataRender[12].derecha[0].foto);
        dataJson["importFile"] = this.dataRender[12].derecha[0].foto;
      }
      if(localStorage.getItem('photoFileObjFront') !== null){
        formData.append("photoFileRouteFront", localStorage.getItem('photoFileObjFront'));
      }
      // if(localStorage.getItem('photoFileObjBack') !== null){
      //   formData.append("photoFileRouteBack", localStorage.getItem('photoFileObjBack'));
      // }
  
      // console.log(localStorage.getItem('photoFileObjFront'));
    //  console.log(dataJson);
     // console.log(formData);
      
      let url = this.ids ? this.urlupdate : "/createFederadoUser";
  
      let sus = this.api.update(url, formData, headers);
      if (!this.ids) {
        sus = this.api.post(url, formData);
      }
      sus.subscribe((res: any) => {
        this.loadingService.hide();
      //  console.log("Response del createFed => ",res);

        const fedObj = {
          email: res.savedFederado.email,
          idEquipo: res.savedFederado.equipo[0]
        };
        localStorage.setItem('fedObj', JSON.stringify(fedObj));
        
        if (res.status === 200) {
          // this.clearForm();
          // this.showWareHoouse();
          // this.router.navigateByUrl('/usuario/u/warehouseCheck');

          let d:any = {
            //position: "top-end",
            icon: "success",
            title: url === '/createFederadoUser' ? `${res.msg}` : `${res.msg}`,
            showConfirmButton: false,
            timer: 1500,
          };
          
          Swal.fire(d).then((result) => {
            // this.goTable();
            this.openModal();
          });
        }
        else{
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: url === '/createFederadoUser' ? `${res.msg}` : "No hemos podido actualizar el federado, intenta nuevamente",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            // this.goTable(); 
          });
        }
      }
      , (error: HttpErrorResponse) => {
        console.error(error);
      });      
    }
  
  }

  clearForm() {
    //this.federadosForm.reset();
    //this.generalForm.reset();

    let res = this.fed;

    this.ids = res._id
    this.urlupdate = "/updateFederado/" + this.ids;
    this.img = res.foto
    if (res.foto) {
      this.img = environment.urlServerimg + res.foto;
      //console.log(this.img);
    }

    let objForm: any = {};
    this.dataRender.forEach(itm => {
      //if(itm.formControl.includes(itm[])){
      if (!itm.fieldset && !itm.derecha) {
        itm.defaultValue = "";
        if (itm.formControl == "foto") {
          this.dataRender[12].derecha[0].foto = "";
          delete this.dataRender[12].derecha[0].fotob64;
        }
      } else if (itm.derecha) {
        itm.derecha.forEach(itm => {
          itm.defaultValue = "";
          if (itm.formControl == "foto") {
            this.dataRender[12].derecha[0].foto = "";
            delete this.dataRender[12].derecha[0].fotob64;
          }
        });
      } else {
        itm.fieldset.forEach(itm => {
          itm.defaultValue = "";
          if (itm.formControl == "foto") {
            this.dataRender[12].derecha[0].foto = "";
            delete this.dataRender[12].derecha[0].fotob64;
          }
        });
      }
      //}
    });
    this.router.navigateByUrl("/pages/federados/tabla");

  }

  goTable() {
    //this.closeDialog();
    window.history.back();
  }

  closeDialog() {
    window.history.back();
  }

  plus() {
    this.clearForm();
    this.router.navigateByUrl("/pages/federados/registro");
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará el federado permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result:any) => {
      if (result.isConfirmed) {

        const url2 = "/deleteFederado/" + this.ids;
        this.api.delete(url2).subscribe((res3) => {
          //console.log("delete", res3);
          //this.mostrarFederados();
          location.reload();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  print() {
    localStorage.setItem("federado", JSON.stringify(this.fed));
    this.router.navigate(["/", "pages", "print", "3"]);
  }

  async openCredencial(){
    // console.log("Entró");
    const inputCurpValue = this.dataRender[1].fieldset[0].defaultValue;
    this.searchFederadoByCurp();
   // console.log(inputCurpValue, this.federadoCurpValid);
    if(inputCurpValue !== '' && this.federadoCurpValid) {
      this.utilService.scrollTo(document.getElementById("bodyt"), 0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(async () => {
        let modal = await this.modalCtrl.create({
          component: CredencialPage,
          componentProps: { federado: this.fed },
          cssClass: 'setting-modal',
          backdropDismiss: false,
        });
    
        modal.present();
      }, 500);
    }
  }

  clicked(){
    document.getElementById('hf-logo').click();
  }

  inputChange(event: Event) {
    
    // console.log(event);
   
    
  }

  inputAsociacion(evt){

   // console.log(evt);
    this.buscaEquipos(evt.trim())
    let Equipo
    Equipo=document.getElementById('inp-equipo')
    Equipo.removeAttribute('readonly');
  }

  inputUnion(evt){

   // console.log(evt);
    this.buscaAsociacion(evt.trim())
    let Asoc
      Asoc=document.getElementById('inp-Asoc')
      Asoc.removeAttribute('readonly');
      
  }



  back(){

    window.history.back();
  }

  openModal() {
    localStorage.removeItem('photoFileObjFront');
    // localStorage.removeItem('photoFileObjBack');
    Swal.fire({
      title: 'Selecciona la opción',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Nuevo Registro',
      denyButtonText: 'Pagar',
      denyButtonColor: '#11c22f'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload()
      } else if (result.isDenied) {
        const fedOb = JSON.parse(localStorage.getItem('fedObj'));
        const pass = localStorage.getItem('pass');
        localStorage.setItem('emailFed', JSON.stringify(fedOb.email));

        let url = "/getEquipoById/" + fedOb.idEquipo;

        this.api.get(url).subscribe((res) => {
          localStorage.setItem('equipoPais', res.pais_origen);
          localStorage.setItem('nombreEquipo', `[${JSON.stringify(res.nombreEquipo)}]`);
        })

        this.login(fedOb.email, pass, fedOb.idEquipo);        
      }
    })
    // this.api.getNumeroConsecutivo().subscribe((res) => {
    //   // res.forEach((element) => {
    //   //   console.log("Número Consecutivo => ",element);
    //   // });
    //   console.log("Número Consecutivo => ",res);
    //   if(res.length < 1){        
        // const modalDiv = document.getElementById('myModal');
        // if(modalDiv !== null){
        //   modalDiv.style.display = 'block';
        //   modalDiv.classList.add('show');
        //   modalDiv.setAttribute('aria-modal', 'true');
        // }
      // }
      // else {
      //   Swal.fire({
      //     //position: "top-end",
      //     icon: "error",
      //     title: `Ya existe un número consecutivo -> "${res.numCons[0].serie}-${res.numCons[0].no_registroFMCH}"`,
      //     showConfirmButton: false,
      //     timer: 3000,
      //   })
      // }
    // });
    
    // console.log(modalDiv.style.display);   

  }
  
  openModalCredOpt() {
    let INE
    INE=document.getElementById('INE')
    INE.classList.add('cover2');
    INE.classList.remove('remove');
    
    this.captureImage(true, true);
  }


  async captureImage(isCamera: boolean = true, frontCred: boolean) {
   // platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
  
    // Call the element loader after the platform has been bootstrapped
    defineCustomElements(window);
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos
    });
    
    this.b64Image = 'data:image/jpeg;base64,' + image.base64String/*.slice(0, 1000)*/;
    let photoFileObj: any = {
      b64:  this.b64Image ,
      id: null, //usuario de nueva creacion
      extension: "png",
      table: "Credencial",
    };

   // console.log("image => ", image);
   // console.log("requestFile => ", photoFileObj);
    
    

    //Endpoint para guardar solamente la foto tomada.
    this.api.uploadPhoto(photoFileObj.b64).subscribe((res: any) => {
      if(frontCred){
        localStorage.setItem('photoFileObjFront', res.rutaImagen);
      }
      // if(backCred){
      //   localStorage.setItem('photoFileObjBack', res.rutaImagen);
      // }
     })
    
    this.changeDetectorRef.detectChanges();
  }

  login(user: string, pass: string, idEq: string) {

    if (user.length <= 0 || pass.length <= 0) {
      this.alertService.errorAlert("Oops", "Asegurate de haber ingresado usuario y contraseña");
    } else {
      let request: any = {
        email: user,
        password: pass
      }
      this.genericService.sendPostRequest(environment.login, request).subscribe((response: any) => {
        localStorage.setItem("tokenazo", JSON.stringify(response.token));
        this.genericService.sendGetRequestParams(`${environment.getFederadoById}/${response.id}`, request).subscribe((response2: any) => {
          response2[0].passwerd = pass;
          localStorage.setItem("userSessionFederacion", JSON.stringify(response2[0]));
          this.router.navigate([`/pages/pagos/${idEq}/false`]);
        }, (error: HttpErrorResponse) => {
          console.log(error);
          
          if (error?.error?.msg) {
            this.alertService.errorAlert("Oops", error?.error?.msg);
          } else {
            this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
          }
        });
      }, (error: HttpErrorResponse) => {
        console.log(error);
        if (error?.error?.msg) {
          this.alertService.errorAlert("Oops", error?.error?.msg);
        } else {
          this.alertService.errorAlert("Oops", "Ocurrió un error, si persiste contacta al administrador");
        }
      });
    }
  }



  datosD(event:any){


    this.dataRender[2].fieldset[4].defaultValue=event.target.value
   // console.log(this.dataRender[2].fieldset[4].placeholder)
    //console.log(event.target.value)
  }

  datosUP(event:any){


    this.dataRender[1].fieldset[3].defaultValue=event.target.value
  //  console.log(this.dataRender[1].fieldset[3].placeholder)
   // console.log(event.target.value)
  }

  datoS(event:any){


    this.dataRender[1].fieldset[2].defaultValue=event.target.value
  //  console.log(this.dataRender[1].fieldset[2].placeholder)
  //  console.log(event.target.value)
    //this.apiCurp('GEMA700106HDFGRN01')
  }
  datoT(event:any){


    this.dataRender[1].fieldset[6].defaultValue=event.target.value
    console.log(this.dataRender[1].fieldset[6].placeholder)
   // console.log(event.target.value)
    //this.apiCurp('GEMA700106HDFGRN01')
  }


  apiCurp(dato){
    
  //  console.log(this.dataRender[1].fieldset[0].placeholder)
    let formData = new FormData();
    const reqHeader = new HttpHeaders().set('Authorization', 'Bearer fd7c9996-e070-4124-b89b-7dd3424f0190');

    
    const headers= new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control', '*')
      .set('Authorization', 'Bearer fd7c9996-e070-4124-b89b-7dd3424f0190');
  
  
    this.api.postheaders('https://apimarket.mx/api/renapo/grupo/valida-curp?curp='+dato.toUpperCase(),headers).subscribe((res: any) => {
    //  console.log(res.data)
      if(res.message=='Exitoso'){
      let btn
      btn=document.getElementById('btn_generar')
      btn.style.display="none"

      this.dataRender[2].fieldset[8].defaultValue=res.data.estadoNacimiento;
      this.dataRender[0].fieldset[0].defaultValue=res.data.nombres;
      this.dataRender[0].fieldset[1].defaultValue=res.data.apellidoPaterno;
      this.dataRender[0].fieldset[2].defaultValue=res.data.apellidoMaterno;

      if(res.data.sexo=='HOMBRE'){
        let hombre
        this.dataRender[1].fieldset[2].defaultValue='H'
        hombre=document.getElementById('HOMBRE')
       hombre.checked=true
       let mujer
        mujer=document.getElementById('MUJER')
       mujer.checked=false
      }else{
        this.dataRender[1].fieldset[2].defaultValue='M'
        let hombre
        hombre=document.getElementById('HOMBRE')
       hombre.checked=false
       let mujer
        mujer=document.getElementById('MUJER')
       mujer.checked=true
      }
      let fecha = res.data.fechaNacimiento;
      let partes = fecha.split("/");
      let mes = partes[1];
      let dia = partes[0];
      let ano = partes[2];
      let mesValue
      mesValue=document.getElementById('mes')
      mesValue.value=mes
      let diaValue
      diaValue=document.getElementById('dia')
      diaValue.value=dia
      let anoValue
      anoValue=document.getElementById('ano')
      anoValue.value=ano

      this.dataRender[1].fieldset[1].defaultValue=ano+"-"+mes+"-"+dia
     // console.log(this.dataRender[1].fieldset[1].defaultValue)

  }else{
    this.alertService.errorAlert("Oops", "Asegurate de haber ingresado correctamente la Curp");
  }
  })
  }

  addNumeros(){

    for (let i = 1900; i < 2024; i++) {
     
      this.anos.push(i.toString())
      //console.log( this.anos)
    }
    for (let j = 1; j <= 13; j++) {
      if(j<=9){
        this.meses.push(0+j.toString())
      }else{
        this.meses.push(j.toString())
      }
      
     
    }
    for (let k = 1; k <= 32; k++) {
      if(k<=9){
        this.dias.push(0+k.toString())
      }else{
        this.dias.push(k.toString())
      }
      
     
    }
   
    
  }

  mostrarcontrasena(tipo){
    if(tipo==1){
    let input
    input=document.getElementById('password')
    let span
    span=document.getElementById('spass')
    if(input.type=='password'){
      input.type='text'
      span.innerText="visibility"
     // console.log(span.innerText)
    }else{
      input.type='password'
      span.innerText="visibility_off"
    //  console.log(span.innerText)

    }
    }

    if(tipo==2){
      let input
      input=document.getElementById('repassword')
      let span
      span=document.getElementById('srepass')
      if(input.type=='password'){
        input.type='text'
        span.innerText="visibility"
       // console.log(span.innerText)
      }else{
        input.type='password'
        span.innerText="visibility_off"
      //  console.log(span.innerText)
  
      }
      }

  }
  
  validarcontrasena(){
    let isValid=true
    if(this.repass!=this.dataRender[2].fieldset[1].defaultValue){
      isValid = false;
      Swal.fire({
        //position: "top-end",
        icon: "success",
        title: "la Contrasena no coiciden  ",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        //this.clearForm();
      });
    }
    return isValid;
  }

  validarcheckbox(){

    let isValid=true
    let input
    input=document.getElementById('aviso')
    if(!input.checked){
      //console.log(input.checked)
      isValid = false;
      Swal.fire({
        //position: "top-end",
        icon: "success",
        title: "Tienes que Aceptar Aviso de Privacidad",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        //this.clearForm();
      });
    }
   
    return isValid;
  }

targetCurp(){
  let mes
  mes=document.getElementById('mes')
  
  let dia
  dia=document.getElementById('dia')
  
  let ano
  ano=document.getElementById('ano')
  let btn
  btn=document.getElementById('btn_buscar')
  btn.style.display="none"
  this.dataRender[1].fieldset[0].defaultValue=this.getCURP(this.dataRender[0].fieldset[0].defaultValue, this.dataRender[0].fieldset[1].defaultValue, this.dataRender[0].fieldset[2].defaultValue, dia.value, mes.value, ano.value,this.dataRender[2].fieldset[8].defaultValue, this.dataRender[1].fieldset[2].defaultValue)
}


///curp generador

  getFirstInternalVowel (word) {
    const vowels = word.substring(1).match(/[AEIOU]/);
    if (vowels) {
      return vowels[0] || 'X'
    } else {
      return 'X'
    }
   
  }
  getFirstInternalConsonant (word) {
    const consonant = word.substring(1).match(/[BCDFGHJKLMNPQRSTVWXYZ]/)
  
    if (consonant) {
      return consonant[0] || 'X'
    } else {
      return 'X'
    }
  }

  clearString  (word)  {

    const cleanWord = word.trim()
    return this.removeAccents(cleanWord.replace(/\s/g, ' ').toUpperCase())
  }

  removeAccents  (word) {
    const accents: any = {
      'Á': 'A',
      'É': 'E',
      'Í': 'I',
      'Ó': 'O',
      'Ú': 'U'
    }

    for (let accented in accents) {
      word = word.replace(new RegExp(accented), accents[accented])
    }
    return word
  }


 

   removeCommonNames  (currentName){

    const notAcceptedNames = [
      'MARIA DEL ',
      'MARIA DE LOS ',
      'MARIA ',
      'JOSE DE ',
      'JOSE ',
      'MA. ',
      'MA ',
      'M. ',
      'J. ',
      'J '
    ]
    const initialValue ='';
    const sumWithInitial = notAcceptedNames.reduce((name, notAccepted) => name.replace(new RegExp('^' + notAccepted), '')
    , currentName)
    return  sumWithInitial
    
       
   }


    removePrefixes  (currentName: string): string {
      const prefixes = [
        'DE ',
        'DEL '
      ]
      const initialValue ='';
      const sumWithInitial = prefixes.reduce((name, notAccepted) => name.replace(new RegExp('^' + notAccepted), '')
        , currentName)
        return  sumWithInitial
    }


   removeBadWords (word) {

    const badWordsCURP = {
      'BACA': 'BXCA',
      'LOCO': 'LXCO',
      'BAKA': 'BXKA',
      'BUEI': 'BXEI',
      'BUEY': 'BXEY',
      'CACA': 'CXCA',
      'CACO': 'CXCO',
      'CAGA': 'CXGA',
      'CAGO': 'CXGO',
      'CAKA': 'CXKA',
      'CAKO': 'CXKO',
      'COGE': 'CXGE',
      'COGI': 'CXGI',
      'COJA': 'CXJA',
      'COJE': 'CXJE',
      'COJI': 'CXJI',
      'COJO': 'CXJO',
      'COLA': 'CXLA',
      'CULO': 'CXLO',
      'FALO': 'FXLO',
      'FETO': 'FXTO',
      'GETA': 'GXTA',
      'GUEI': 'GXEI',
      'GUEY': 'GXEY',
      'JETA': 'JXTA',
      'JOTO': 'JXTO',
      'KACA': 'KXCA',
      'KACO': 'KXCO',
      'KAGA': 'KXGA',
      'KAGO': 'KXGO',
      'KAKA': 'KXKA',
      'KAKO': 'KXKO',
      'KOGE': 'KXGE',
      'KOGI': 'KXGI',
      'KOJA': 'KXJA',
      'KOJE': 'KXJE',
      'KOJI': 'KXJI',
      'KOJO': 'KXJO',
      'KOLA': 'KXLA',
      'KULO': 'KXLO',
      'LILO': 'LXLO',
      'LOKA': 'LXKA',
      'LOKO': 'LXKO',
      'MAME': 'MXME',
      'MAMO': 'MXMO',
      'MEAR': 'MXAR',
      'MEAS': 'MXAS',
      'MEON': 'MXON',
      'MIAR': 'MXAR',
      'MION': 'MXON',
      'MOCO': 'MXCO',
      'MOKO': 'MXKO',
      'MULA': 'MXLA',
      'MULO': 'MXLO',
      'NACA': 'NXCA',
      'NACO': 'NXCO',
      'PEDA': 'PXDA',
      'PEDO': 'PXDO',
      'PENE': 'PXNE',
      'PIPI': 'PXPI',
      'PITO': 'PXTO',
      'POPO': 'PXPO',
      'PUTA': 'PXTA',
      'PUTO': 'PXTO',
      'QULO': 'QXLO',
      'RATA': 'RXTA',
      'ROBA': 'RXBA',
      'ROBE': 'RXBE',
      'ROBO': 'RXBO',
      'RUIN': 'RXIN',
      'SENO': 'SXNO',
      'TETA': 'TXTA',
      'VACA': 'VXCA',
      'VAGA': 'VXGA',
      'VAGO': 'VXGO',
      'VAKA': 'VXKA',
      'VUEI': 'VXEI',
      'VUEY': 'VXEY',
      'WUEI': 'WXEI',
      'WUEY': 'WXEY'
    }
      let badWordsList
      badWordsList=badWordsCURP
      
      if (badWordsList[word]) {
        return badWordsList[word]
      }
  
      return word
    }


    getSpecialChar (bornYear) {
      if (bornYear[0] === '1') {
        return '0'
      } else {
        return 'A'
      }
    }


    getBornStateCode (stateName) {
      const states = {
        'AGUASCALIENTES': 'AS',
        'BAJA CALIFORNIA': 'BC',
        'BAJA CALIFORNIA NORTE': 'BC',
        'BAJA CALIFORNIA SUR': 'BS',
        'CAMPECHE': 'CC',
        'COAHUILA': 'CL',
        'COLIMA': 'CM',
        'CHIAPAS': 'CS',
        'CHIHUAHUA': 'CH',
        'CIUDAD DE MEXICO': 'DF',
        'DISTRITO FEDERAL': 'DF',
        'DURANGO': 'DG',
        'GUANAJUATO': 'GT',
        'GUERRERO': 'GR',
        'HIDALGO': 'HG',
        'JALISCO': 'JC',
        'MEXICO': 'MC',
        'MICHOACAN': 'MN',
        'MORELOS': 'MS',
        'NAYARIT': 'NT',
        'NUEVO LEON': 'NL',
        'OAXACA': 'OC',
        'PUEBLA': 'PL',
        'QUERETARO': 'QT',
        'QUINTANA ROO': 'QR',
        'SAN LUIS POTOSI': 'SP',
        'SINALOA': 'SL',
        'SONORA': 'SR',
        'TABASCO': 'TC',
        'TAMAULIPAS': 'TS',
        'TLAXCALA': 'TL',
        'VERACRUZ': 'VZ',
        'YUCATAN': 'YN',
        'ZACATECAS': 'ZS',
        'NACIDO EN EL EXTRANJERO':'NE'
      }

      const parsedStateName = this.removeAccents(this.clearString(stateName))
      return states[parsedStateName]
    }


    getCommonPart (name: string, surnameFather: string, surnameMother: string, bornDay, bornMonth, bornYear) {
      let commonPart = surnameFather[0]
      commonPart += this.getFirstInternalVowel(surnameFather)
      commonPart += surnameMother[0] || 'X'
      commonPart += name[0]
      commonPart = this.removeBadWords(commonPart)
      commonPart += bornYear.substring(2)
      commonPart += bornMonth
      commonPart += bornDay
      return commonPart
    }

    getGenderLetter (gender) {
      switch (gender) {
        case 'H':
          return 'H'
        case 'M':
          return 'M'
      }
    }
    getLastCURPDigit  (incompleteCurp)  {
      const dictionary = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'
      let lnSum = 0.0
      let lnDigt = 0.0
  
      for (let i = 0; i < 17; i++) {
        lnSum = lnSum + dictionary.indexOf(incompleteCurp.charAt(i)) * (18 - i)
      }
  
      lnDigt = 10 - lnSum % 10
      if (lnDigt === 10) return 0
      return lnDigt
    }


    getCURP  (name, surnameFather, surnameMother, bornDay, bornMonth, bornYear, bornState, gender)  {

      name = this.clearString(name)
      name = this.removeCommonNames(name)
      surnameFather = this.clearString(surnameFather)
      surnameFather = this.removePrefixes(surnameFather)
      surnameMother = this.clearString(surnameMother)
      surnameMother = this.removePrefixes(surnameMother)
      bornDay = this.clearString(bornDay)
      bornMonth = this.clearString(bornMonth)
      bornYear = this.clearString(bornYear)
  
      let curp = this.getCommonPart(name, surnameFather, surnameMother, bornDay, bornMonth, bornYear)
      curp = this.removeBadWords(curp)
      curp += this.getGenderLetter(gender)
      curp += this.getBornStateCode(bornState)
      curp += this.getFirstInternalConsonant(surnameFather)
      curp += this.getFirstInternalConsonant(surnameMother)
      curp += this.getFirstInternalConsonant(name)
  
      curp += this.getSpecialChar(bornYear)
      curp += this.getLastCURPDigit(curp)
      return curp
    }

    disableInputs(){
      let Asoc
      Asoc=document.getElementById('inp-Asoc')
      Asoc.setAttribute("readonly", "true");
      let Equipo
      Equipo=document.getElementById('inp-equipo')
      Equipo.setAttribute("readonly", "true");
     // console.log(Asoc)
     
    }

    validacionUnion(){
     let isValid=true
     let union=this.dataRender[1].fieldset[4].defaultValue
     //console.log(union)
    //  this.dataRender[1].fieldset[4].dato.map(e=>{
      //console.log(e)
        // if(e != union){
        //   isValid = false;
        //   Swal.fire({
        //     //position: "top-end",
        //     icon: "success",
        //     title: "La Union No existe",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   }).then((result) => {
        //     //this.clearForm();
        //   });
        // }
        
        // });
        return isValid;
    }

}