import { EventoComponent } from './pages/evento/evento.component';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IonicModule } from '@ionic/angular';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbMenuModule, NbRadioModule, NbUserModule } from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from 'src/app/material.module';
import { ButtonTandemComponent } from './components/button-tandem/button-tandem';
import { HeaderCharrosComponent } from './components/charreria-components/header-ux/header.component';
import { SpinnerComponent } from './components/charreria-components/spinner/spinner.component';
import { ContainerAppComponent } from './components/container-app/container-app';
import { ContainerComponent } from './components/container/container';
import { FabButton } from './components/fab-button/fab-button';
import { FmchInputComponent } from './components/fmch-input/fmch-input.component';
import { GalleryUrlComponent } from './components/gallery-url/gallery.component';
import { HeaderComponent } from './components/header/header';
import { InputFloatComponent } from './components/input-float/input-float';
import { InputTandemComponent } from './components/input-tandem/input-tandem';
import { ItemListAvatarComponent } from './components/item-list-avatar/item-list-avatar';
import { MapsComponent } from './components/maps/maps.component';
import { OpcionesComponent } from './components/opciones/opciones.component';
import { ScrollingCardsComponent } from './components/scrolling-cards/scrolling-cards';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { TabsComponent } from './components/tabs/tabs';
import { AdDirective } from './directives/ad.directive';
import { AsociacionComponent } from './pages/asociacion/asociacion.component';
import { TablaAsocComponent } from './pages/asociacion/federadosTabla/tabla/tabla.component';
import { RegistroComponent } from './pages/asociacion/registro/registro.component';
import { TablaEquipoAsocComponent } from './pages/asociacion/tabla-by-asoc/tabla-by-equipo.component';
import { TablaAsociacionComponent } from './pages/asociacion/tabla/tabla.component';
import { ConstruccionComponent } from './pages/construccion/construccion.component';
import { CredencialPage } from './pages/credencial/credencial.page';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EquiposComponent } from './pages/equipos/equipos.component';
import { RegistroEquipoComponent } from './pages/equipos/registro/registro.component';
import { TablaEquipoComponent } from './pages/equipos/tabla/tabla.component';
import { EstadisticasComponent } from './pages/estadisticas/estadisticas.component';
import { DetallesComponent } from './pages/federados/detalles/detalles.component';
import { FederadosComponent } from './pages/federados/federados.component';
import { RegistroFederadosComponent } from './pages/federados/registro/registro.component';
import { TablaEquipoFedComponent } from './pages/federados/tabla-by-equipo/tabla-by-equipo.component';
import { TablaFederadosComponent } from './pages/federados/tabla/tabla.component';
import { UploadFileComponent } from './pages/federados/upload-file/upload-file.component';
import { UploadImgComponent } from './pages/federados/upload-img/upload-img.component';
import { LoginFeelComponent } from './pages/login/login.component';
import { RegistroerComponent } from './pages/login/registro/registro.component';
import { PagesComponent } from './pages/pages.component';
import { PagosComponent } from './pages/pagos/pagos.component';
import { PrintComponent } from './pages/print/print.component';
import { RecintosComponent } from './pages/recintos/recintos.component';
import { TablaAsocUnioComponent } from './pages/union/asociaciones/tabla/tabla.component';
import { DetallesUnionComponent } from './pages/union/detalles/detalles.component';
import { RegistroUnionComponent } from './pages/union/registro/registro.component';
import { TablaUnionComponent } from './pages/union/tabla/tabla.component';
import { UnionComponent } from './pages/union/union.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { TablaEventoComponent } from './pages/evento/tabla/tabla.component';
import { EventosHistoricosComponent } from './pages/eventos-historicos/eventos-historicos.component';
import { RegistrokioskoComponent } from './pages/registrokiosko/registrokiosko.component';
import { CredencialEquipoComponent } from './pages/credencial-equipo/credencial-equipo.component';
import { DetallesKioskoComponent } from './pages/detalles-kiosko/detalles-kiosko.component';
import { ChartsModule } from 'ng2-charts';
import { LoginPerfilesComponent } from './pages/login-perfiles/login-perfiles.component';
import { ValidacionComponent } from './pages/validacion/validacion.component';
import { RecuperarPassComponent } from './pages/recuperar-pass/recuperar-pass.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        RecuperarPassComponent,
        ValidacionComponent,
        LoginPerfilesComponent,
        DetallesKioskoComponent,
        CredencialEquipoComponent,
        EstadisticasComponent,
        RegistrokioskoComponent,
        SpinnerOverlayComponent,
        SpinnerComponent,
        TabsComponent,
        HeaderComponent,
        ContainerComponent,
        ContainerAppComponent,
        OpcionesComponent,
        ScrollingCardsComponent,
        AdDirective,
        InputFloatComponent,
        ItemListAvatarComponent,
        FabButton,
        MapsComponent,
        InputTandemComponent,
        ButtonTandemComponent,
        GalleryUrlComponent,

        AsociacionComponent,
        RegistroComponent,
        TablaAsociacionComponent,
        TablaEquipoAsocComponent,
        TablaAsocComponent,
        HeaderCharrosComponent,
        PagesComponent,
        DashboardComponent,

        UnionComponent,
        RegistroUnionComponent,
        TablaUnionComponent,
        DetallesUnionComponent,

        TablaEquipoComponent,
        RegistroEquipoComponent,
        EquiposComponent,
        PrintComponent,

        RecintosComponent,
        UsuariosComponent,
        ConstruccionComponent,
        TablaEventoComponent,
        EventosHistoricosComponent,
        CredencialPage,
        FmchInputComponent,
        TablaFederadosComponent,
        TablaEquipoFedComponent,
        RegistroFederadosComponent,
        UploadFileComponent,
        UploadImgComponent,
        DetallesComponent,
        FederadosComponent,
        EventoComponent,
        LoginFeelComponent,
        TablaAsocUnioComponent,
        RegistroerComponent,

        PagosComponent
    ],
    exports: [
        RecuperarPassComponent,
        ValidacionComponent,
        LoginPerfilesComponent,
        DetallesKioskoComponent,
        CredencialEquipoComponent,
        EstadisticasComponent,
        RegistrokioskoComponent,
        SpinnerOverlayComponent,
        SpinnerComponent,
        TabsComponent,
        HeaderComponent,
        ContainerComponent,
        ContainerAppComponent,
        OpcionesComponent,
        ScrollingCardsComponent,
        AdDirective,
        InputFloatComponent,
        ItemListAvatarComponent,
        FabButton,
        MapsComponent,
        InputTandemComponent,
        ButtonTandemComponent,
        GalleryUrlComponent,
        FmchInputComponent,
        AsociacionComponent,
        RegistroComponent,
        TablaAsociacionComponent,
        TablaEquipoAsocComponent,

        HeaderCharrosComponent,
        PagesComponent,
        DashboardComponent,

        UnionComponent,
        RegistroUnionComponent,
        TablaUnionComponent,
        DetallesUnionComponent,

        TablaEquipoComponent,
        RegistroEquipoComponent,
        EquiposComponent,
        PrintComponent,

        RecintosComponent,
        UsuariosComponent,
        ConstruccionComponent,

        CredencialPage,

        TablaFederadosComponent,
        TablaEquipoFedComponent,
        RegistroFederadosComponent,
        UploadFileComponent,
        UploadImgComponent,
        DetallesComponent,
        FederadosComponent,
        EventoComponent,
        EventosHistoricosComponent,
        LoginFeelComponent,
        TablaAsocUnioComponent,
        RegistroerComponent,


        TablaEventoComponent,



        PagosComponent
    ],
    imports: [
        ChartsModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        MaterialModule,
        CKEditorModule,
        TranslateModule.forChild(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [HttpClient]
                }
            }
        ),
        NbMenuModule,

        NbActionsModule,
        NbButtonModule,
        NbCardModule,
        NbCheckboxModule,
        NbDatepickerModule, NbIconModule,
        NbInputModule,
        NbRadioModule,
        NbUserModule,
        NbCardModule,

    ]
})
export class ComponentsModule { }
