import { Component, Input, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
})

export class PaypalComponent implements OnInit {
  @Input() paypalUrl: string;
  @Input() totalDeFederados: number;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,

  ) { 
    this.paypalUrl = navParams.get('paypalUrl');
    this.totalDeFederados = navParams.get('totalDeFederados');
  }

  ngOnInit() {
    if(this.paypalUrl) {
      const paypalFrame = document.getElementById('paypalFrame');
      const errorSection = document.getElementById('pagoError');

      paypalFrame["src"] = this.paypalUrl;
      //errorSection.classList.remove('disabled');
    } else {
      const paypalFrame = document.getElementById('paypalFrame');
      paypalFrame.classList.add('disabled');
      const errorSection = document.getElementById('pagoError');

     console.log (document.getElementById('pagoError'))
     errorSection.classList.remove('disabled');
      errorSection.classList.add('enable');
    }

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
