import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'input-tandem',
  templateUrl: './input-tandem.html',
  styleUrls: ['./input-tandem.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class InputTandemComponent implements OnDestroy, OnInit {

  @Input() extraClass: string;
  @Input() id: string = "";
  @Input() inputType: string = "text";
  @Input() inputPlaceholder: string = "";
  @Input() inputMaxLength: number = 10;
  @Input() values: any[] = [];
  @Input() withAutocomplete: boolean = false;
  @Input() multipler: boolean = false;
  @Input() float: boolean = false;
  @Input() selectWithImg: boolean = false;

  // Output prop name must be Input prop name + 'Change'
  // Use in your component to write an updated value back out to the parent
  @Input() tandemModel: string = "";
  @Output() tandemModelChange = new EventEmitter<string>();
  @Output() multipleSelectionChange = new EventEmitter<any>();
  @Output() lastSelectionChange = new EventEmitter<any>();
  @Output() realValueAutocomplete = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<any>();

  @Input() seleccion: any[] = [];
  public selected = -1;;


  private inputValue: any;
  public isArea: boolean = false;

  formControl = new FormControl();
  autoFilter: Observable<any[]>;
  //Items: string[] = ['BoJack Horseman', 'Stranger Things', 'Ozark', 'Big Mouth'];

  constructor(
  ) {


  }

  public ngOnInit(): void {
   // console.log(this.seleccion);
    if (this.withAutocomplete) {
      this.autoFilter = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this.mat_filter(value))
      );
    }

    if (this.seleccion && this.seleccion.length > 0) {
      this.seleccion.forEach(e => {
        this.values.forEach(a => {
          if (e.value == a.value) {
            a.checked = true;
          }
        });
      });

    //  console.log(this.values);

    }
  }

  private mat_filter(value: any): any[] {
    try {
      console.log(value);

      const filterValue = value?.toLowerCase();
      let ele: any = this.values.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
      try {
        this.tandemModelChange.emit(ele.value);
      } catch (error) {

      }
      this.realValueAutocomplete.emit(ele);
      return ele;
    } catch (error) {

    }
  }


  public ngOnDestroy(): void {

  }

  /*checkbox change event*/
  onChange(event) {
   // console.log("-----------------");
    let remove: boolean = false;
    let index: any = this.seleccion.findIndex(x => x.value === event.value);
    if (index != -1) {
     // console.log(index);
      if (this.seleccion.length == 1) {
        remove = true;
        this.seleccion = [];
      } else {
        remove = true;
        this.seleccion = this.seleccion.slice(index, this.seleccion.length);
      }
    } else {
      this.seleccion.push(event);
    }
   // console.log(this.seleccion);


    this.multipleSelectionChange.emit(this.seleccion);// se quita temporalmente
    this.lastSelectionChange.emit([event, remove]);
  }

}
