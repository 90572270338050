import { GenericService } from './../../services/generic.service';
import { environment } from './../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoaderService } from './../../services/loading-service';
import { Subscription } from 'rxjs';
import { EventService } from './../../services/event.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FederadosService } from '../federados/services/federados.service';
import Swal from "sweetalert2";
@Component({
  selector: 'ngx-asociacion',
  templateUrl: './asociacion.component.html',
  styleUrls: ['./asociacion.component.scss']
})
export class AsociacionComponent implements OnInit, OnDestroy {

  public asoc: any = null;
  public suscription: Subscription;
  public data: any = null;
  //public generalForm: FormGroup;
  public datosUsuario:any;
  public equipos: any[] = [];
  public uniones: any[] = [];
  public recintos: any[] = [];
  public rencintoData:any=[];
  public dataRender: any = [
    {
      id: "inp-nombreAsociacion",
      formControl: "nombreAsociacion",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-union",
      formControl: "union",
      label: "Unión",
      placeholder: "Unión...",
      required: true,
      defaultValue: null,
      type: "autoCompletar",
      withError: false,
      values: [
        {
          value: "",
          label: "[-- Selecciona --]"
        }
      ]
    },

    {
      id: "inp-pais_fundacion",
      formControl: "pais_fundacion",
      label: "País",
      placeholder: "México",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-domicilio",
      formControl: "domicilio",
      label: "Calle y número",
      placeholder: "Calle",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-colonia",
      formControl: "colonia",
      label: "Colonia",
      placeholder: "Constitución",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-ciudad_fundacion",
      formControl: "ciudad_fundacion",
      label: "Municipio",
      placeholder: "Monterrey",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-estado_fundacion",
      formControl: "estado_origen",
      label: "Entidad",
      placeholder: "Jalisco",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-codigo_postal",
      formControl: "codigo_postal",
      label: "CP / ZIP CODE",
      placeholder: "89670",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-telefono",
      formControl: "telefono",
      label: "Teléfono",
      placeholder: "5598765432",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },{
      id: "inp-email",
      formControl: "email",
      label: "Correo",
      placeholder: "charros@jalisco.com",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-fecha_fundacion",
      formControl: "fecha_fundacion",
      label: "Fecha de Fundación",
      placeholder: "0000-00-00",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, 
    {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "Facebook",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "Instagram",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-w",
      formControl: "w",
      label: "w",
      placeholder: "w",
      required: true,
      white: true,
      defaultValue: 0,
      type: "autoCompletar",
      withError: false,
      noVisual: true,
      values: [
        {
          value: 0,
          label: "[-- Selecciona --]"
        }
      ]
    },
    {
      id: "inp-w",
      formControl: "w",
      label: "w",
      placeholder: "w",
      required: true,
      white: true,
      defaultValue: 0,
      type: "autoCompletar",
      withError: false,
      noVisual: true,
      values: [
        {
          value: 0,
          label: "[-- Selecciona --]"
        }
      ]
    },
    
    {
      id: "inp-nombre_lienzo",
      formControl: "nombre_lienzo",
      label: "Lienzo Sede",
      placeholder: "Lienzo",
      required: true,
      defaultValue: null,
      type: "autoCompletar",
      withError: false
    },{
      id: "inp-domicilio_lienzo",
      formControl: "domicilio_lienzo",
      label: "Domicilio Lienzo Sede",
      placeholder: "Calle...",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },

    {
      id: "inp-logotipo",
      formControl: "logotipo",
      label: "Logotipo",
      placeholder: "logotipo",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false,
      noVisual: true
    }
  ];
  public integrantes: any[] = [];

  public federadosList: any;

  public img: any = environment.urlServerimg;
  public UnionName:any=[];
  public recintoName:any=[];
  /*
  {
      "cargo_integrante": "cargo integrante test 2",
      "nombre_completo_integrante": "nombre completo integrante test 2",
      "email_integrante": "emailIntegrante2@mail.com",
      "telefono_integrante": "3164978264"
    }
  */

  

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    public api: FederadosService,
    private loadingService: LoaderService,
    private router: Router,
    private genericService: GenericService,
    private route: ActivatedRoute
  ) {
    let objRender: any = {};
    this.dataRender.forEach(element => {
      if (!element.noVisual) {
        let validators: any[] = [];
        validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
        if (element.required) {
          validators.push(Validators.required);
        }

        objRender[element.formControl] = validators;
      }
    });
    //console.log(objRender);

    //this.generalForm = this.formBuilder.group(objRender);
  }

  mostrarFederado(id, curp) {

    if(this.integrantes[id].curp_integrante.value.length === 18) {
      try {
        this.api.get(`/getFederadoByCurp/${curp}`).subscribe((res) => {
          console.log(res);
          
          this.integrantes[id].nombre_completo_integrante.value = res[0].nombre_completo;
          this.integrantes[id].email_integrante.value = res[0].email;
          this.integrantes[id].telefono_integrante.value = res[0].cel;
  
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  ngOnInit(): void {
    this.mostrarUsario();
    this.suscrip();

    /* this.router.events.subscribe((val) => {
      // see also 
     //console.log(val);
      
      if(val instanceof NavigationEnd){
       //console.log("change");
        
      }
    }); */

    //this.mostrarEquipo();
    this.mostrarUniones();
    this.mostrarRecintos();
  }

  suscrip(){
    this.suscription = this.eventService.get("detalleAsociacionss").subscribe((data) => {
      console.log("dtalle asociacion");
      // console.log("data",data);
      this.asoc = data;

      this.data = data;

      if(this.data.current.integrantes && this.data.current.integrantes.length > 0){
        this.data.current.integrantes.forEach(m => {
        //  console.log(m);
          this.integrantes.push({
            cargo_integrante: {
              value: m.cargo_integrante,
              placeholder: "Cargo"
            },
            nombre_completo_integrante: {
              value: m.nombre_completo_integrante,
              placeholder: "Nombre completo"
            },
            curp_integrante: {
              value: m.curp_integrante,
              placeholder: "CURP"
            },
            email_integrante: {
              value: m.email_integrante,
              placeholder: "Email"
            },
            telefono_integrante: {
              value: m.telefono_integrante,
              placeholder: "Teléfono"
            }
          });
        });
      }

      this.api.get(`/getAllEquiposByAsociacion/${this.data.id}`).subscribe(res => {
        if (res.message != "No hay equipos registrados" || !res.message) {
          this.equipos = res.eqArr;
        }
      });

      let objForm: any = {};
      //console.log(this.dataRender);
      
      this.dataRender.forEach(itm => {
        //if(itm.formControl.includes(itm[])){
        if (!itm.noVisual) {
          itm.defaultValue = this.data.current[itm.formControl];
        }

        //}
      });
      console.log(objForm);



      //this.generalForm.setValue(objForm);

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scroll(0, 0); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);

    });
  }

  nuevaMesa() {
    this.integrantes.push({
      cargo_integrante: {
        value: "",
        placeholder: "Cargo"
      },
      nombre_completo_integrante: {
        value: "",
        placeholder: "Nombre completo"
      },
      curp_integrante: {
        value: "",
        placeholder: "CURP"
      },
      email_integrante: {
        value: "",
        placeholder: "Email"
      },
      telefono_integrante: {
        value: "",
        placeholder: "Teléfono"
      }
    });
  }


  borraMesa(position){
    this.integrantes.splice(position, 1);
  }

  mostrarEquipo() {
    this.api.get("/getAllEquipos").subscribe((res) => {
      //console.log("equipoEXTERNO", res);
      this.equipos = res.eqArr;
      this.equipos.forEach(element => {
        this.dataRender[12].values.push({ label: element.nombreEquipo, value: element.nombreEquipo });
      });
    });
  }

mostrarRecintos(){
  this.api.get(`/getAllRecintos`).subscribe(res => {
    if (res.message != "No hay union registrados" || !res.message) {
      this.recintos = res.recArr;
    }
    
    this.recintos.forEach(element => {
      const _id = element._id;
      const nombre = element.nombre_recinto;
      this.recintoName.push(nombre);
    });
    


    
    
    
  });


}



  mostrarUniones() {
    this.api.get("/getAllUniones").subscribe((res) => {
      //console.log("equipoEXTERNO", res);
      this.uniones = res.UnionArr;
      this.uniones.forEach(element => {
        this.dataRender[1].values.push({ label: element.nombre, value: element.nombre });
          
      });
      res.UnionArr.forEach(e => {
        
        this.UnionName.push(e.nombre);
        
      });
      
    });
  }

  ngOnDestroy() {
    //this.suscription.unsubscribe();
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[17].defaultValue = files[0];

    var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.dataRender[17].b64 = reader.result;
      };

    
  }


  update() {
    this.loadingService.show("Actualizando...");
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    let dataJson = {};
    this.dataRender.forEach(element => {
      formData.append(element.formControl, element.defaultValue);
      dataJson[element.formControl] = element.defaultValue;
    });
    formData.append("importFile",this.dataRender[17].defaultValue);
    let urlupdate = "/updateAsociacion/" + this.data.current._id;

    dataJson["integrantes"] = [];

    if (this.integrantes.length > 0) {      
      dataJson["integrantes"] = [];

      this.integrantes.forEach(m => {
          dataJson["integrantes"].push({
              cargo_integrante: m.cargo_integrante.value,
              curp_integrante: m.curp_integrante.value,
              nombre_completo_integrante: m.nombre_completo_integrante.value,
              telefono_integrante: m.telefono_integrante.value,
              email_integrante: m.email_integrante.value
            });
          });
    }

    this.api.update(urlupdate, dataJson, headers).subscribe((res) => {
      this.loadingService.hide();
      if (res) {
        // this.clearForm();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
        Swal.fire({
          //position: "top-end",
          icon: "success",
          title: "Se actualizo la asociación correctamente.",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          this.clearForm();
        });
      }


    });
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará la asociación permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadingService.show("Eliminando...");
        const url2 = "/deleteAsociacion/" + this.data.id;
        this.api.delete(url2).subscribe((res3) => {
          this.loadingService.hide();
          //console.log("delete", res3);
          location.reload();
          //this.mostrarUnion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  clearForm() {
    this.eventService.send("rmv", null);
    //this.generalForm.reset();
    this.data = null;
   //this.generalForm.reset();
    //this.router.navigate(["pages", "asociacion", "tabla"], { replaceUrl: true });
    
    //console.log("clean");
   location.reload();
    // if(!this.suscription){
    //   this.suscrip();
    // }
    
  }

  plus() {
    this.clearForm();

    this.router.navigateByUrl("/pages/asociacion/registro");
  }

  seleccionaEquipo(equipo: any) {
    //console.log(equipo);
    this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${equipo._id}`, {}).subscribe((response: any) => {
      //console.log(response);
      this.federadosList = response.arrFed;
      //console.log(this.federadosList);

    }, (error: HttpErrorResponse) => {

    });
  }

  print() {
    localStorage.setItem("asoc", JSON.stringify(this.asoc));
    this.router.navigate(["/", "pages", "print", "2"]);
  }


  userNamengmodelchange(value){

    this.api.get(`/getAllRecintos`).subscribe(res => {
      if (res.message != "No hay union registrados" || !res.message) {
        this.recintos = res.recArr;
      }

      
      
      this.recintos.forEach(element => {
        const _id = element._id;
        const nombre = element.nombre_recinto;
        const domicilio = element.direccion;
        this.rencintoData.push({_id,nombre,domicilio});
      });


      if (this.rencintoData.find(element => element.nombre === value)) {
      let busqueda= this.rencintoData.find(element => element.nombre ===value);
      this.dataRender[16].defaultValue=busqueda.domicilio
      console.log(busqueda);
      }
     
      
    });
  }


mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}

}
