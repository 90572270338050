import { GenericService } from 'src/app/services/generic.service';
// import { EventService } from '@core/services/event.service';
// import { LocalStorageEncryptService } from '@core/services/utils/local-storage-encrypt.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { LoginService } from "@core/services/login/login.service";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class InterceptorCharros implements HttpInterceptor {

    constructor(
        private genericService: GenericService
    ) { }



    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token = JSON.parse(localStorage.getItem("tokenazo"));
        //console.log(token);

        let authReq = null;
        if (token && !req.url.includes("login")) {
            authReq = req.clone({
                headers: req.headers.set('x-access-token', token)
                .set('Authorization', 'Bearer fd7c9996-e070-4124-b89b-7dd3424f0190'),
            });
        } else {
            authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer fd7c9996-e070-4124-b89b-7dd3424f0190')            });
        }
       
        /*
        headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
        */

       // console.log('Intercepted HTTP call', authReq);
        return next.handle(authReq ?? req).pipe(tap(event => {
            
        }, (error: HttpErrorResponse) => {
            // http response status code
            if (error.status == 401) {
                this.renovateToken();
            }
        }));
    }

    renovateToken() {

        let feder = JSON.parse(localStorage.getItem("userSessionFederacion"));

        if(feder){
            let request: any = {
                email: feder.email,
                password: feder.passwerd
            }
            this.genericService.sendPostRequest(environment.login, request).subscribe((response: any) => {
              //  console.log(response);
                
                localStorage.setItem("tokenazo", JSON.stringify(response.token));
    
            }, (error: HttpErrorResponse) => {
                console.log(error);
            });
        }
    }
}
