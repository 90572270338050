
import { GenericService } from './../../../services/generic.service';
import { AlertService } from './../../../services/alert.service';
import { LoaderService } from './../../../services/loading-service';
import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { EventService } from '../../../services/event.service';
import { FederadosService } from '../../federados/services/federados.service';

@Component({
  selector: "ngx-registroer",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.scss"],
})
export class RegistroerComponent implements OnInit {

  @Input() us: any;


  public data: any = [
    {
      required: true,
      value: ""
    }, {
      required: true,
      value: ""
    }, {
      required: false,
      value: ""
    }, {
      required: false,
      value: ""
    }, {
      required: true,
      value: ""
    }, {
      required: true,
      value: ""
    }, {
      required: true,
      value: ""
    }, {
      required: true,
      value: ""
    }, {
      required: true,
      value: ""
    }, 
  ];
  public asocs: any[] = [];

  public check: boolean = false;
  public check2: boolean = false;

  constructor(
    private loadingService: LoaderService,
    private alertService: AlertService,
    private genericService: GenericService,
    private eventService: EventService,
    public api: FederadosService,
  ) {

  }

  ngOnInit() {
    if (this.us) {
      /*
      
      let request: any = {
              email: this.data[4].value,
              password: this.data[3].value,
              nombre: this.data[0].value,
              ap_paterno: this.data[1].value,
              ap_materno: this.data[2].value,

              curp: this.data[5].value,
              cel: this.data[8].value,
              asociacion: this.data[9].value,
              equipo: [`federacion`],
              tipo_federado: "Charro"
            };
            */
      console.log(this.us);
      this.data[4].value = this.us.email;
      this.data[7].value = this.us.email;
      this.data[3].required = false;
      this.data[6].required = false;

      this.data[3].value = "false";
      this.data[6].value = "false";

      this.data[0].value = this.us.nombre;
      this.data[1].value = this.us.ap_paterno;
      this.data[2].value = this.us.ap_materno;
      this.data[8].value = this.us.cel;
      this.data[5].value = this.us.curp;
    }
    this.cargarAsociaciones();
  }

  cargarAsociaciones() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      this.asocs = res.asocArr;
    });
  }

  create() {
    let err: number = 0;
    console.log(this.check);
    console.log(this.data);

    this.data.forEach(element => {
      if (element.required && element.value.length <= 0) {
        err++;
      }
    });

    if (err > 0) {
      this.alertService.warnAlert("Espera!", "Los campos (*) son requeridos");
    } else {

      if (this.data[3].value != this.data[6].value) {
        this.alertService.warnAlert("Espera!", "Las contraseñas deben coincidir");
      } else {
        if (!this.check) {
          this.alertService.warnAlert("Espera!", "Comprueba que no eres un robot");
        } else {

          if (this.data[4].value != this.data[7].value) {
            this.alertService.warnAlert("Espera!", "Los correos deben coincidir");
          } else {
            console.log(this.data);
            //this.loadingService.show();
            let request: any = {
              email: this.data[4].value,
              password: this.data[3].value,
              nombre: this.data[0].value,
              ap_paterno: this.data[1].value,
              ap_materno: this.data[2].value,

              curp: this.data[5].value,
              cel: this.data[8].value,
             // asociacion: `federacion`,
            // equipo: `federacion`,
              tipo_federado: "Charro",
             // perfiles: ["superadmin"],
             // pais_origen:'mexico',
              //estado_origen:'mexico'
            };

            let sus = this.genericService.sendPostRequest(environment.createFederadoUser, request);

            if (this.us) {
              request.password;
              request.password;
              sus = this.genericService.sendPutRequest(`${environment.updateFederado}/${this.us._id}`, request);
            }

            



            sus.subscribe((r: any) => {
              this.alertService.successAlert("Bien!", `Usuario ${this.us ? 'actualizado' : 'creado'} correctamente`);
              setTimeout(() => {
                this.eventService.send("cancel", 1);
              }, 500);
            }, (error: HttpErrorResponse) => {
              this.loadingService.hide();
            });
          }

        }
      }
    }
  }

  ver() {
    let i: any = document.getElementById("1");
    let i2: any = document.getElementById("2");

    if (!this.check2) {
      i.type = "text";
      i2.type = "text";
    } else {
      i.type = "password";
      i2.type = "password";
    }
    this.check2 = !this.check2;
  }

  volver() {
    this.eventService.send("cancel", null);
  }
}

