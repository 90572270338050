import { Router } from '@angular/router';
import { AdDirective } from './../directives/ad.directive';
import { ComponentFactoryResolver, Injectable, OnDestroy } from "@angular/core";
import { AdComponent } from '../interfaces/ad.interface';

/**Clase provider que se utiliza para generar mensajes de error, alerta o éxito
 * Se hizo de forma genérica para evitar repetir esta clase de código
 */
@Injectable({
  providedIn: "root"
})
export class UtilService implements OnDestroy {
  /**Constructor del servicio en el que se inyecta el controlador de alertas de ionic
   * y eventos de escucha para el momento de un cierre de sesión inesperado
   */
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  getRandomText(length) {
    var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".match(/./g);
    var text = "";
    for (var i = 0; i < length; i++)
      text += charset[Math.floor(Math.random() * charset.length)];
    return text;
  }

  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  renderDynamicComponent(component: any, host: AdDirective, data: any = null) {
    const componentFactory: any = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = host.viewContainerRef;

    viewContainerRef.clear();

    const componentRef: any = viewContainerRef.createComponent<AdComponent>(componentFactory);

    componentRef.instance.data = data;

  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /**Método para llevar a top en una view con animación */
  scrollTo(element, to, duration) {
    let start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    let animateScroll = () => {
      currentTime += increment;
      let val = this.calculo(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  /**Calculo de variables height */
  calculo(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }
  
}
