import { AfterViewInit, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FederadosService } from "../federados/services/federados.service";
import { Router } from '@angular/router';
import { AlertService } from "src/app/services/alert.service";
import { GenericService } from "src/app/services/generic.service";
import { LoaderService } from "src/app/services/loading-service";
import { environment } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-eventos-historicos",
  templateUrl: "./eventos-historicos.component.html",
  styleUrls: ["./eventos-historicos.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EventosHistoricosComponent implements OnInit, AfterViewInit {
  public events: any = [];
  public events2: any[] = [];
  

  constructor(
    private router: Router, 
    private api: FederadosService, 
    private alertService: AlertService, 
    private genericService: GenericService,
    private loadingService: LoaderService) {}

  ngOnInit() {
    this.getEventosHistoricos();
  }

  ngAfterViewInit() {
    // this.destroyDataTable();
    if(this.events2.length > 0){
      this.initializeDataTable();
    }
  }

  getEventosHistoricos() {

    this.api.get("/getAllEventosHistoricos")
      .subscribe((res) => {
        let date 

        if(res.hay_eventos){
          this.events = res.eventos;
          this.events2 = this.events.map((arr)=>{
            const newArr = {...arr};
            // console.log(newArr.fecha_inicio_evento)
            var dateObject = new Date(arr.fecha_inicio_evento);
            
            newArr.fecha_inicio_evento=dateObject.toISOString().slice(0, 10)
            return newArr;
          })         
          
          
        }
        // else{
        //   this.initializeDataTable();
        // }
        this.initializeDataTable();

        
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editEvent(item: { _id: string }) {
		this.router.navigate(['/pages/evento', item._id])
	}

  importEventosHistoricos() {
    let id: any = document.getElementById("file");
    id.click();
  }

  fileChange(evt: any) {
    let file: any = evt?.target?.files[0];
    if (file && file?.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.alertService.errorAlert("Oops!", "Se espera un archivo .xlsx");
    } else {
      this.cargarMasivo(file);
    }
  }

  cargarMasivo(file: any): void {
    this.loadingService.show();
    const formData = new FormData();
    console.log(file);

    formData.append('importFile', file);
    this.genericService.sendPostRequest(environment.importEventos, formData).subscribe((r: any) => {
      this.alertService.successAlert("Bien!", "Archivo cargado correctamente");
      if(this.events2.length > 0){
        // this.initializeDataTable();
        this.getEventosHistoricos();
      }
      else {
        location.reload();
      }
      
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }

  // Destruir la tabla DataTable existente, si hay una
  destroyDataTable() {
    const dataTable = $("#dt").DataTable();
    if (dataTable) {
      dataTable.destroy();
    }
  }

  initializeDataTable() {
    console.log(this.events2);
      
      
    setTimeout(function () {
      $(function () {
        $("#dt").DataTable({
          bDestroy: true,
          retrieve: true,
          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 10,
          sPaginationType: "full_numbers",
          sDom: '<"top"i> T<"clear">lfrtip',
          sPageButtonActive: "paginate_active",
          sPageButtonStaticDisabled: "paginate_button",
          scrollY: 400,
          scrollX: true,
          scrollCollapse: true,
          stateSave: true,
          stateSaveCallback: function (settings, data) {
            localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
          },
          stateLoadCallback: function (settings) {
            return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
          },
          language: {
            emptyTable: "No hay eventos disponibles",
            // Otras opciones de lenguaje...
          },
          drawCallback: function (settings) {
            if (settings.fnRecordsDisplay() === 0) {
              // Si no hay registros, mostrar el mensaje personalizado
              $("#dt").html(`<div class="no-elements-message">No hay eventos disponibles</div>`);
            }
          },
        })
        // if (eventoRes?.hay_eventos && eventoRes.eventos.length > 50) {
        //   // Si hay eventos y la cantidad es mayor que 50, crear DataTable
        //   $("#dt").DataTable(dtOptions);
        // } else {
        //   // Mostrar mensaje si no hay eventos o la cantidad es menor o igual a 50
        //   // $('#dt').DataTable().draw().destroy();
        //   $("#dt").DataTable(dtOptions);
        // }
      });
    }, 1000);
    $('#dt').DataTable().draw().destroy();
  }
  
  
}
