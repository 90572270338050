import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FederadosService } from "../federados/services/federados.service";
import { environment } from 'src/environments/environment.prod';
import { GenericService } from './../../services/generic.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from "./../../services/loading-service";
import * as PDF417 from 'pdf417-generator';
declare var JsBarcode;
declare var jsPDF;
declare var html2pdf;
@Component({
  selector: 'app-credencial-equipo',
  templateUrl: './credencial-equipo.component.html',
  styleUrls: ['./credencial-equipo.component.scss'],
})
export class CredencialEquipoComponent implements OnInit {
  public feds: any[] = [];
  public data:any[]=[];
  @Input() federado: any;
  public eq: any = null;

  public img2: any = environment.urlServerimg;
  constructor(
    public api: FederadosService,
    private genericService: GenericService,
    private loadingService: LoaderService,
   
  ) { }

  ngOnInit() {
    
    this.eq = JSON.parse(localStorage.getItem("eq")).current;
    console.log(this.eq._id)
    this.getAllFed(this.eq._id);
   
   
    const code =
    `HRVHUB30
    HRK
    000000000012355
    PETAR KORETIĆ
    PREVOJ DD
    10000 Zagreb
    FIRMA J.D.O.O
    PREVOJ DD
    10000 ZAGREB
    HR5041240000000000
    HR01
    7336-68949637625-00001
    COST
    Uplata za 1. mjesec`
    
    let canvas=document.getElementById("barcode")
    
    
      
       // JsBarcode("#barcode", "Hi world!");
      }
      print(nme: string = "file") {
       // console.log(this.feds[0]._id)

       
      
        console.log("print");
        let canvas=document.getElementById("barcode")
        
        PDF417.draw(this.feds[0].no_registroFMCH+" "+this.feds[0].nombre_completo+" "+this.feds[0].asociacion+" "+this.feds[0].equipo+" "+"2024", document.getElementById('barcode'+this.feds[0]._id));
      
        // let id: any = document.getElementById("btn-prt");
        // id.style.display = "none";
        var printer2 = document.getElementById('printer2'+this.feds[5]._id);
       // console.log(printer2 )
        //printer2.style.transform = "rotateY(0deg)";
        printer2.style.height = "310px";
    
        var printer = document.getElementById('printer'+this.feds[0]._id);
        printer.style.position = "relative";
        printer.style.height = "310px";
        let ctn: any = Array.from(document.getElementsByClassName("flip-card-inner"));
       // console.log(ctn);
    
        ctn[0].children[1].classList.remove("flip-card-front");
        ctn[0].children[0].classList.remove("flip-card-back");
    
        var opt = {
          margin: -0.5,
          filename: `${nme}.pdf`,
          image: { type: 'jpg', quality:10 },
          html2canvas: { scale: 3, useCORS: true },
          jsPDF: { unit: 'mm', format: [125,81.05851], orientation: 'l',compress: true },
          activeFontSize: 20,
    
        };
        //html2pdf(element);
        
      let i 
        var worker = html2pdf().set(opt).from(printer).toPdf().get('pdf');
        this.loadingService.show("Actualizando...");
        for (let j = 1; j < this.feds.length; j++) {
        //  PDF417.draw(this.feds[j].seguro_poliza, document.getElementById('barcode'+this.feds[j]._id));
          PDF417.draw(this.feds[j].no_registroFMCH+" "+this.feds[j].nombre_completo+" "+this.feds[j].asociacion+" "+this.feds[j].equipo+" "+"2024", document.getElementById('barcode'+this.feds[j]._id));
          worker = worker.get('pdf').then(
            pdf => { pdf.addPage() }
          ).from(document.getElementById('printer2'+this.feds[j-1]._id)).toContainer().toCanvas().toPdf()
          worker = worker.get('pdf').then(
            pdf => { pdf.addPage() }
          ).from(document.getElementById('printer'+this.feds[j]._id)).toContainer().toCanvas().toPdf()
          i=j
        }
        worker = worker.get('pdf').then(
          pdf => { pdf.addPage() }
        ).from(document.getElementById('printer2'+this.feds[i]._id)).toContainer().toCanvas().toPdf()
        
       
        
       
        worker.save();
        this.loadingService.hide();
        setTimeout(() => {
          console.log("reset");


          
          
       //   ctn[0].children[0].classList.add("flip-card-front");
          printer2.style.transform = "rotateY(0deg)";
         // ctn[0].children[1].classList.add("flip-card-back"); 
        }, 2000);
    
    
      
       
    
  }
  
  getAllFed(id){



    this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${id}`, {}).subscribe((response: any) => {
      this.feds=response.arrFed
      

      
      ;
    }, (error: HttpErrorResponse) => {

    });
  }
}
