import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FederadosService } from "../services/federados.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { environment } from "../../../../environments/environment";
import { Asociacion } from "../../interface/interfaces";
import { Equipo, Federados } from "../../interface/interfaces";
import { MatDialog } from "@angular/material/dialog";
import { DetallesComponent } from "../detalles/detalles.component";
import { HttpHeaders } from "@angular/common/http";

import { GenericService } from "src/app/services/generic.service";
import { ModalBusquedaFedComponent } from "src/app/modal-busqueda-fed/modal-busqueda-fed.component";
declare var $: any;
@Component({
  selector: "ngx-tabla",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.scss"],
})
export class TablaFederadosComponent implements OnInit {
  public datosUsuario:any;
  federadosList: Federados[] = [];
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  public federadosForm: FormGroup;
  hideModal: boolean;
  url: string;
  url1: string;
  url2: string;
  elementsNumber: number = 10;
  img: any;
  pager: any = {};
  entriesInit: number;
  page: number = 1;
  public dataFilters: any = [];

  pagesPosition1: HTMLElement;
  pagesPosition2: HTMLElement;
  pagesPosition3: HTMLElement;
  pagesPosition4: HTMLElement;
  pagesPosition5: HTMLElement;
  pagesPosition6: HTMLElement;
  pagesPosition7: HTMLElement;
  
  positions: HTMLElement[];

  pagesButtonFirst: HTMLElement;
  pagesButtonPrevious: HTMLElement;
  pagesButtonNext: HTMLElement;
  pagesButtonLast: HTMLElement;

  buttons: HTMLElement[];

  public dataRender: any = [
    {
      id: "inp-nombre",
      formControl: "nombre",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-apellido_paterno",
      formControl: "ap_paterno",
      label: "Apellido Paterno",
      placeholder: "Apellido Paterno...",
      required: true,
      defaultValue:  "",
      type: "text",
      withError: false
    },
    {
      id: "inp-apellido_materno",
      formControl: "ap_materno",
      label: "Apellido Materno",
      placeholder: "Apellido Materno...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-equipo",
      formControl: "equipo",
      label: "Equipo",
      placeholder: "Equipo Federado...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    },
    {
      id: "inp-categoria_federado",
      formControl: "categoria_federado",
      label: "Categoria de Federado",
      placeholder: "Categoria de Federado...",
      required: true,
      defaultValue:  "",
      type: "text",
      withError: false
    },    
    {
      id: "inp-tipo_federado",
      formControl: "tipo_federado",
      label: "Tipo de Federado",
      placeholder: "Tipo de Federado...",
      required: true,
      defaultValue:  "",
      type: "text",
      withError: false
    },
    {
      id: "inp-fecha_nac",
      formControl: "fecha_nac",
      label: "Fecha Nacimiento",
      placeholder: "1979-01-24...",
      required: true,
      defaultValue:  "",
      type: "text",
      withError: false
    },
    {
      id: "inp-no_registroFMCH",
      formControl: "no_registroFMCH",
      label: "no_registroFMCH",
      placeholder: "154654...",
      required: true,
      defaultValue:  "",
      type: "text",
      withError: false
    },
    
  ]

  constructor(
    public rest:GenericService,
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.img = environment.urlServerimg;

    this.url = `/getAllFederados?page=1&size=`;
    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";

    this.dataFilters = [{
      nombre: 'jesus felix',
      ap_paterno: 'mata',
      ap_materno: 'x',
      pais_origen: '',
      categoria_federado: '',
      tipo_federado: '' 
    }];
  }

  ngOnInit(): void {
    this.hideModal = false;
this.mostrarUsario();
    this.mostrarFederados();

    this.positions = [
      this.pagesPosition1 = document.getElementById('position-1'),
      this.pagesPosition2 = document.getElementById('position-2'),
      this.pagesPosition3 = document.getElementById('position-3'),
      this.pagesPosition4 = document.getElementById('position-4'),
      this.pagesPosition5 = document.getElementById('position-5'),
      this.pagesPosition6 = document.getElementById('position-6'),
      this.pagesPosition7 = document.getElementById('position-7'),
    ];

    this.buttons = [
      this.pagesButtonFirst = document.getElementById('pageBtnFirst'),
      this.pagesButtonPrevious = document.getElementById('pageBtnPrevious'),
      this.pagesButtonNext = document.getElementById('pageBtnNext'),
      this.pagesButtonLast = document.getElementById('pageBtnLast'),
    ];
  }
  

  clearForm() {
    this.federadosForm.reset();
  }

  mostrarFederados(btnUpdate?: boolean) {  
    let dataJson = {};
    let filters = false;
    const sectionPages = document.getElementById('section-pages');

    this.dataRender.forEach(element => {
        dataJson[element.formControl] = element.defaultValue;
        if(element.defaultValue !== '') filters = true;
    });

    console.log(dataJson);
    

    if (filters) {
      this.api.post(`/getFederadosBy_Pais_Cat_Tipo?page=${this.page}&limit=${Number(this.elementsNumber)}`, dataJson).subscribe((res) => {
        this.federadosList = res.arrFed;
        this.pager = res;

        if(this.pager.totalPages == 1) {
          sectionPages.classList.add('disabled');
        } else {
          sectionPages.classList.remove('disabled');
        }
        
        if(btnUpdate) {
          this.page = 1;
        }
        this.changePagesPositions();
      });  
    } else {
      this.api.get(`/getAllFederados?page=${this.page || 1}&limit=${Number(this.elementsNumber)}`).subscribe((res) => {
        this.federadosList = res.arrFed;
        this.pager = res;

        if(this.pager.totalPages == 1) {
          sectionPages.classList.add('disabled');
        } else {
          sectionPages.classList.remove('disabled');
        }

        if(btnUpdate) {
          this.page = 1;
        }
        this.changePagesPositions();
      }); 
    }
  }

  clearFilters() {
    this.dataRender.forEach(filter => {
      filter.defaultValue = '';
    });

    this.mostrarFederados();
  }

  clickOnNumberPage(event: Event) {
    const numberElement = event.target as HTMLElement;
    if(numberElement.innerText == '...') return;
    this.page = Number(numberElement.innerText);

    this.togglePagesButtonsActive();
    this.changePagesPositions();
    this.changePageActive();
    this.mostrarFederados();
  }

  firstPage(){
    if(this.page != 1) {
      this.page = 1;
      this.togglePagesButtonsActive();
      this.changePagesPositions();
      this.changePageActive();
      this.mostrarFederados();
    }
  }

  previousPage(){
    if(this.page > 1) {
      this.page = this.page - 1;
      this.togglePagesButtonsActive();
      this.changePagesPositions();
      this.changePageActive();
      this.mostrarFederados();
    }
  }

  nextPage(){
    if(this.page < this.pager.totalPages) {
      this.page = this.page + 1;
      this.togglePagesButtonsActive();
      this.changePagesPositions();
      this.changePageActive();
      this.mostrarFederados();
    }
  }

  lastPage(){
    if(this.page != this.pager.totalPages) {
      this.page = this.pager.totalPages;
      console.log(this.page);
      this.togglePagesButtonsActive();
      this.changePagesPositions();
      this.changePageActive();
      this.mostrarFederados();
    }
  }

  changePageActive(){
    this.positions.forEach(position => {
      if(position.classList.contains('active')) {
        position.classList.remove('active');
      }
    });

    this.positions.forEach(position => {
      if(Number(position.innerText) == this.page) {
        position.classList.add('active');
      }
    });
  }

  // Función para interactuar con las posiciones de las páginas
  changePagesPositions() {
    switch(true) {
      case this.pager.totalPages > 7:
        this.positions.forEach(position => {
          position.classList.remove('disabled');
          position.classList.add('pages-number');
        });

        if(this.page > 4 && this.page < this.pager.totalPages - 3) {

          this.pagesPosition2.innerText = '...';
          this.pagesPosition2.classList.remove('pages-number');

          this.pagesPosition3.innerText = `${this.page - 1}`;          
          this.pagesPosition4.innerText = `${this.page}`;           
          this.pagesPosition5.innerText = `${this.page + 1}`;
          
          this.pagesPosition6.innerText = '...';
          this.pagesPosition6.classList.remove('pages-number');

          this.pagesPosition7.innerText = `${this.pager.totalPages}`;
        }

        if (this.page <= 4) {
          let i = 1;
          
          this.positions.slice(0, 5).forEach(position => {
            position.innerText = `${i}`;
            i++
          });

          this.pagesPosition6.innerText = '...';
          this.pagesPosition6.classList.remove('pages-number');

          this.pagesPosition7.innerText = `${this.pager.totalPages}`
        }

        if (this.page > this.pager.totalPages -4) {
          let i = this.pager.totalPages - 4;

          this.pagesPosition2.innerText = '...';
          this.pagesPosition2.classList.remove('pages-number');
          
          this.positions.slice(2).forEach(position => {
            position.innerText = `${i}`;
            i++
          });
        }
      break;

      case this.pager.totalPages <= 7:
        let i = 1;
        this.positions.forEach(position => {
          if(this.positions.indexOf(position) <= this.pager.totalPages - 1) {
            position.innerText = `${i}`;
            i++;
          } else {
            position.classList.add('disabled');
          }
        });

      break;
    }
  }

  togglePagesButtonsActive() {
    if(this.page > 1 && this.page < this.pager.totalPages) {
      this.buttons.forEach(btn => {
        btn.classList.add('pages-btn-active');
      });

    } else if(this.page == 1) {
      this.pagesButtonFirst.classList.remove('pages-btn-active');
      this.pagesButtonPrevious.classList.remove('pages-btn-active');

      this.pagesButtonNext.classList.add('pages-btn-active');
      this.pagesButtonLast.classList.add('pages-btn-active');

    } else if(this.page == this.pager.totalPages) {
      this.pagesButtonFirst.classList.add('pages-btn-active');
      this.pagesButtonPrevious.classList.add('pages-btn-active');

      this.pagesButtonNext.classList.remove('pages-btn-active');
      this.pagesButtonLast.classList.remove('pages-btn-active');

    } else if(this.pager.totalPages == 1) {
      this.pagesButtonFirst.classList.remove('pages-btn-active');
      this.pagesButtonPrevious.classList.remove('pages-btn-active');

      this.pagesButtonNext.classList.remove('pages-btn-active');
      this.pagesButtonLast.classList.remove('pages-btn-active');
    }
  }

  edit(id) {
    this.router.navigateByUrl("pages/federados/detalles/" + id);
  }

  plus() {
    this.router.navigateByUrl("/pages/federados/detalles");
  }

  delete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {

        const url2 = "/deleteFederado/" + id;
        this.api.delete(url2).subscribe((res3) => {
         //console.log("delete", res3);
          this.mostrarFederados();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}

  openModal() {
    const dialogRef = this.dialog.open(ModalBusquedaFedComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
