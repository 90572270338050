import { Router } from '@angular/router';
import { LoaderService } from './../../services/loading-service';
import { SqlGenericService } from './../../services/sqlGenericService';
import { LocalStorageEncryptService } from './../../services/local-storage-encrypt.service';
import { AlertService } from './../../services/alert.service';
import { EventService } from 'src/app/services/event.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavParams, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ThemeService } from 'src/app/services/theme.service';
import * as PDF417 from 'pdf417-generator';


import { map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

declare var JsBarcode;
declare var jsPDF;
declare var html2pdf;
@Component({
  selector: 'app-credencial',
  templateUrl: './credencial.page.html',
  styleUrls: ['./credencial.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CredencialPage implements OnInit {

  @Input() federado: any;

  public img2: any = environment.urlServerimg;


  constructor(
    public themeService: ThemeService,
    public platform: Platform,
    private eventService: EventService,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private localStorageEncryptService: LocalStorageEncryptService,
    private loadingService: LoaderService,
    private router: Router,
    private navParams: NavParams
  ) {

    this.federado = navParams.get("federado");
    console.log(this.federado);
  }

  ngOnInit() {
    
    const code =
`HRVHUB30
HRK
000000000012355
PETAR KORETIĆ
PREVOJ DD
10000 Zagreb
FIRMA J.D.O.O
PREVOJ DD
10000 ZAGREB
HR5041240000000000
HR01
7336-68949637625-00001
COST
Uplata za 1. mjesec`

let canvas=document.getElementById("barcode")
canvas.style.color='red'

    PDF417.draw(this.federado.no_registroFMCH+" "+this.federado.nombre_completo+" "+this.federado.asociacion+" "+this.federado.equipo+" "+"2024", canvas);
   // JsBarcode("#barcode", "Hi world!");
  }
  print(nme: string = "file") {
    console.log("print");
    this.loadingService.show();
    let id: any = document.getElementById("btn-prt");
    id.style.display = "none";
    var printer2 = document.getElementById('printer2');

    printer2.style.transform = "rotateY(0deg)";
    printer2.style.height = "auto";

    var printer = document.getElementById('printer');
    printer.style.position = "relative";
    printer.style.height = "auto";
    let ctn: any = Array.from(document.getElementsByClassName("flip-card-inner"));
    console.log(ctn);

    ctn[0].children[1].classList.remove("flip-card-front");
    ctn[0].children[0].classList.remove("flip-card-back");

    var opt = {
      margin: -0.5,
      filename: `${nme}.pdf`,
      image: { type: 'jpg', quality:0.95 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: 'mm', format: [125,81.05851], orientation: 'l',compress: true },
      activeFontSize: 20,

    };
    //html2pdf(element);
    var worker = html2pdf().set(opt).from(printer).toPdf().get('pdf').then(function (pdf) {
      console.log("pdf");
      id.style.display = "block";
      pdf.addPage();


    }).from(printer2).toContainer().toCanvas().toPdf().save();
    setTimeout(() => {
      console.log("reset");
      this.loadingService.hide();
      ctn[0].children[0].classList.add("flip-card-front");
      printer2.style.transform = "rotateY(0deg)";
      ctn[0].children[1].classList.add("flip-card-back"); 
    }, 2000);



  }


  back() {
    window.history.back();

  }
}
