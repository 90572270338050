import { Component, OnInit, Input } from '@angular/core';
import { FederadosService, Federados } from '../services/federados.service';
import {  Router } from '@angular/router';
import {  HttpHeaders } from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { Asociacion } from '../../interface/interfaces';
import { Equipo } from '../../interface/interfaces';
import { formatDate } from '@angular/common';
import { AlertService } from '../../../services/alert.service';


@Component({
  selector: 'ngx-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroFederadosComponent implements OnInit {



  asociacion:Asociacion []=[];
  equipo:Equipo []=[];
   FederadosList: {};
   public federadosForm: FormGroup;
   hideModal: boolean;
   url: string;
   filename2: any;
   file2: '';
   min: Date;
   max: Date;
   url1: string;
   url2: string;
   b64: any = null;

   public dataRender: any = [
    {
      id: "inp-nombre",
      formControl: "nombre",
      label: "Nombre",
      placeholder: "Nombre",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-apellidoPaterno",
      formControl: "apellidoPaterno",
      label: "Apellido Paterno",
      placeholder: "Apellido Paterno",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
    {
      id: "inp-apellidoMaterno",
      formControl: "apellidoMaterno",
      label: "Apellido Materno",
      placeholder: "Apellido Materno",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    },
  ]

  constructor(
    protected dateService: NbDateService<Date>,
    public rest: FederadosService,
    private router: Router,
    public formBuilder: FormBuilder,
    public api: FederadosService,
    private alertService: AlertService
    
    ) { 
    this.min = this.dateService.addDay(this.dateService.today(), -5);
    this.max = this.dateService.addDay(this.dateService.today(), 5);
    
    this.url1 = '/getAllAsociaciones';
    this.url2 = '/getAllEquipos';
    
    this.federadosForm=this.formBuilder.group({
      email:   ['testFed5@mail.com'],
      password:['123456'],
      nombre:	    ['testferedado2'],
      ap_paterno:	[' testApPat2'],
      ap_materno:	[ 'testApMat2'],
      fecha_nac:	[ '2021-7-19'],
      curp:		    [' wome900919HNLDNR03'],
      sexo:		    [' M'],
      pais_origen:['México'],
      estado_origen:	 ['Nuevo León'],
      curp_tutor:	     ['WOOR670726HNLDNR02'],
      foto:		    [''],
      domicilio_pais:	 ['México'],
      domicilio_cp:	  [ '64400'],
      clave_pais:	     ['+52'],
      cel:		        [ '8112009432'],
      tipo_federado:	['Charro'],
      categoria_federado:['Infantil B'],
      seguro_costo:      [ '500'],
      seguro_status:     	['Pagado'],
      seguro_poliza:	    ['mbr131254687'],
      seguro_fecha_pago: ['2021-8-17'],
      seguro_vigencia:	['2021-8-17'],
      fecha_pago_cuota_federacion:['2021-8-17'],
      cuota_costo:	    ['1200'],
      comprobante_pago_url:	     [ '/pago-realizado'],
      vencimiento:	  ['2021-8-17'],
      status_pago:  	[' Sin pagar'],
      asociacion:   	['rancho el pitayo 1'],
      equipo:		      ['rancho el pitayo 1'],
      perfil1:	      ['SuperAdmin'], 
      perfil2:	     ['federado'], 
      importFile:[''],
  
    });

this.mostrarAsociacion();
      this.mostrarEquipo();



    
  }

  ngOnInit(): void {
    this.url = '/createFederadoUser';
    this.hideModal = false;
  }
  
  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
   //console.log(files);

    if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
      this.filename2 = "Selected file: " + files[0].name;
      this.file2 = files[0];

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.b64 = reader.result;
      };
    } else {
      this.alertService.errorAlert("Oops!", "Solo formatos de imagen permitido (png, jpg, jpeg)");
    }
  }

  mostrarAsociacion() {
     this.api.get(this.url1).subscribe(res => {
     //console.log('asociacion', res);
      this.asociacion = res.arrAsoc;
     
    });
   
  }

  clickInput(){
    let id:any = document.getElementById("hf-logo");
    id.click();
  }

  
  mostrarEquipo() {
    this.api.get(this.url2).subscribe(res => {
     //console.log('equipo', res);
      this.equipo = res.equipoArr;
     
    });
   
  }
 

  addFederados(){
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append('email', this.federadosForm.value.email);
    formData.append('nombre', this.federadosForm.value.nombre);
    formData.append('ap_paterno', this.federadosForm.value.ap_paterno);
    formData.append('ap_materno', this.federadosForm.value.ap_materno);
    formData.append('fecha_nac', this.federadosForm.value.fecha_nac);
    formData.append('curp', this.federadosForm.value.curp);
    formData.append('sexo', this.federadosForm.value.sexo);
    formData.append('pais_origen', this.federadosForm.value.pais_origen);
    formData.append('estado_origen', this.federadosForm.value.estado_origen);
    formData.append('importFile', this.file2);
    formData.append('domicilio_pais', this.federadosForm.value.domicilio_pais);
    formData.append('domicilio_cp', this.federadosForm.value.domicilio_cp);
    formData.append('clave_pais', this.federadosForm.value.clave_pais);
    formData.append('cel', this.federadosForm.value.cel);
    formData.append('tipo_federado', this.federadosForm.value.tipo_federado);
    formData.append('categoria_federado', this.federadosForm.value.categoria_federado);
    formData.append('seguro_costo', this.federadosForm.value.seguro_costo);
    formData.append('seguro_status', this.federadosForm.value.seguro_status);
    formData.append('seguro_poliza', this.federadosForm.value.seguro_poliza);
    formData.append('seguro_fecha_pago', this.federadosForm.value.seguro_fecha_pago);
    formData.append('seguro_vigencia', this.federadosForm.value);
    formData.append('fecha_pago_cuota_federacion', this.federadosForm.value.fecha_pago_cuota_federacion);
    formData.append('cuota_costo', this.federadosForm.value.cuota_costo);
    formData.append('comprobante_pago_url', this.federadosForm.value.comprobante_pago_url);
    formData.append('vencimiento', this.federadosForm.value.vencimiento);
    formData.append('status_pago', this.federadosForm.value.status_pago);
    formData.append('asociacion', this.federadosForm.value.asociacion);
    formData.append('equipo', this.federadosForm.value.equipo);
    formData.append('perfil1', this.federadosForm.value.perfil1);
    formData.append('perfil2', this.federadosForm.value.perfil2);
    this.api.post2(this.url, formData, headers).subscribe(res => {
      if (res) {
        this.clearForm();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
      }
    });
  }

  clearForm() {
    this.federadosForm.reset();
  }

  

}


