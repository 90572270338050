import { isArray } from "rxjs/internal-compatibility";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService } from "../../services/alert.service";
import { EventService } from "../../services/event.service";
import { LoaderService } from "../../services/loading-service";
import { FederadosService } from "../federados/services/federados.service";
import Swal from "sweetalert2";
declare var $: any;
declare var XLSX;
import * as leaflet from "leaflet";
import { Subscription } from "rxjs";

@Component({
  selector: "ngx-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.scss"],
})
export class UsuariosComponent implements OnInit, OnDestroy {
  public datosUsuario:any;
  public feds: any[] = [];
  public federadosRender: any[] = [];
  public perfilesRender: any[] = [];
  public addFederadoCURP: string;
  public addFederadoFMCH;
  public addFederadoName;
  public addFederadoTeam;
  public newFederado;
  public txtPerfiles;
  public txtFiltro;
  public txtFiltro1;
  public no_registroFMCH: string;
  yaExisteNumCons:Boolean = false

  ls: any = JSON.parse(localStorage.getItem("userSessionFederacion"));
  public userPerfil: String = this.ls.perfiles[0];
  public isPerfilDropDownDisabled: Boolean = false;

  public data: any = null;
  public create: boolean = false;
  public usuarios: any[] = [];
  public map: any = null;
  public dataAdicional: any = {
    lat: 19.1986,
    long: -103.7583,
    img1: {
      url: "",
      b64: "",
      file: null,
    },
    img2: {
      url: "",
      b64: "",
      file: null,
    },
  };

  public dataRender: any = [
    {
      id: "inp-nombre_recinto",
      formControl: "nombre_recinto",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-pais_origen",
      formControl: "pais_origen",
      label: "País",
      placeholder: "USA...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-codigo_postal",
      formControl: "codigo_postal",
      label: "CP / ZIP CODE",
      placeholder: "89670",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-estado_origen",
      formControl: "estado_origen",
      label: "Entidad",
      placeholder: "Jalisco...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-municipio_condado",
      formControl: "municipio_condado",
      label: "Delegación ó municipio",
      placeholder: "Aldama...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-ciudad_localidad",
      formControl: "ciudad_localidad",
      label: "Colonia",
      placeholder: "Brownsville...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },

    {
      id: "inp-calle_numero",
      formControl: "calle_numero",
      label: "Calle y número",
      placeholder: "Calle Mina #459...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },

    {
      id: "inp-categoria",
      formControl: "categoria",
      label: "Categoría",
      placeholder: "Categoría...",
      required: true,
      defaultValue: "",
      type: "select",
      withError: false,
      values: [{ label: "Lienzo Charro", value: "lienzo charro" }],
    },
    {
      id: "inp-asociacion",
      formControl: "asociacion",
      label: "Asociación",
      placeholder: "Asociación",
      required: true,
      defaultValue: "FEDERACION",
      type: "select",
      withError: false,
      values: [],
    },
    {
      id: "inp-telefono",
      formControl: "telefono",
      label: "Teléfono",
      placeholder: "5598765432",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-email",
      formControl: "email",
      label: "Corro",
      placeholder: "charros@jalisco.com",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-poblacion_municipio",
      formControl: "poblacion_municipio",
      label: "Población INEGI",
      placeholder: "876",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false,
    },
    {
      id: "inp-fecha_fundacion",
      formControl: "fecha_fundacion",
      label: "Fecha de Fundación",
      placeholder: "0000-00-00",
      required: true,
      defaultValue: null,
      type: "date",
      withError: false,
    },
    {
      id: "inp-capacidad",
      formControl: "poblacion_municipio",
      label: "Capacidad",
      placeholder: "873",
      required: true,
      defaultValue: "",
      defaultValueb64: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-calificacion",
      formControl: "rating",
      label: "Calificación",
      placeholder: "5",
      required: true,
      defaultValue: "",
      defaultValueb64: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-ubicacion",
      formControl: "ubicacion",
      label: "Link Google Maps",
      placeholder: "https://goo.gl/maps/PZSPPyFjoP7H9CtV8",
      required: true,
      defaultValue: "",
      defaultValueb64: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-website",
      formControl: "website",
      label: "Sitio Web",
      placeholder: "https://www.charreria.com",
      required: true,
      type: "text",
      withError: false,
    },
    /* {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "Facebook",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "Instagram",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    } */
  ];

  public sus: Subscription;

  constructor(
    private eventService: EventService,
    public api: FederadosService,
    private loadingService: LoaderService,
    private router: Router,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    //this.cargarRecintos();
    // this.cargarAsociaciones();
    this.mostrarUsario();
    this.cargarPerfiles();
    this.showOrHideNumCons();

    this.sus = this.eventService.get("cancel").subscribe((data) => {
      if (data) {
        this.cargarRecintos();
        this.usuarios.length = 0;
      }

      this.create = false;
      this.data = null;
      this.usuarios.length = 0;
    });

    if(this.userPerfil === 'presidente asociacion'){
      this.usuarios.push(this.ls);
    }

  }
  cargarPerfiles() {
    this.api.get("/getAllPerfiles").subscribe((res) => {
      res.forEach((element) => {
        this.perfilesRender = res;
      });
    });
  }
  cargarAsociaciones() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      res.asocArr.forEach((element) => {
        this.dataRender[8].values.push({
          label: element.nombreAsociacion,
          value: element.nombreAsociacion,
        });
      });
    });
  }

  cambioPerfil() {
    this.txtFiltro1 = this.txtFiltro["_id"];
    console.log(this.txtFiltro["_id"]);
    this.cargarRecintos();
  }
  cargarRecintos() {
    this.api.get(`/getAllFederadosByPerfil/` + this.txtFiltro1).subscribe(
      (response: any) => {
        if (response.length === 0) {
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: "Sin Federados Con ese Perfil.",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
          this.usuarios.length = 0;
        } else {
          this.usuarios = response;
          setTimeout(function () {
            $(function () {
              $("#dtRec2").DataTable({
                bDestroy: true,
                retrieve: true,
                bInfo: true,
                bProcessing: true,
                bDeferRender: true,
                iDisplayLength: 50,
                sPaginationType: "full_numbers",
                Dom: '<"top"i> T<"clear">bfrtip',
                PageButtonActive: "paginate_active",
                PageButtonStaticDisabled: "paginate_button",
                scrollY: "650px",
                scrollCollapse: true,
                stateSave: true,
                stateSaveCallback: function (settings, data) {
                  localStorage.setItem(
                    "DataTables_" + settings.sInstance,
                    JSON.stringify(data)
                  );
                },
                stateLoadCallback: function (settings) {
                  return JSON.parse(
                    localStorage.getItem("DataTables_" + settings.sInstance)
                  );
                },
              });
            });
          }, 1000);
          $("#dewfeft").DataTable().clear().destroy();
        }
      },
      (error: HttpErrorResponse) => {}
    );
  }

  ngOnDestroy() {
    this.sus.unsubscribe();
  }

  plus2() {
    this.create = true;
  }

  cargaMapa() {
    //this.loadingService.show();
    console.log("Se inicia la carga de mapa");
    var greenIcon = leaflet.icon({
      iconUrl: "assets/css/images/marker-icon-2x.png",
    });
    if (!this.dataAdicional.lat || this.dataAdicional.lat.length <= 0) {
      this.dataAdicional.lat = 22.28519;
      this.dataAdicional.long = -97.87777;
    }
    this.map = leaflet
      .map("map")
      .setView([this.dataAdicional.lat, this.dataAdicional.long], 15);

    let a: any = document.getElementsByClassName("leaflet-control-attribution");
    setTimeout(() => {
      a[0].children[0].style.display = "none";
    }, 500);
    // set map tiles source

    let osmLayer = new leaflet.TileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 19,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        attribution:
          'Mapa de Juanshwo &copy; <a href="https://www.juanshwo.com/">Juanshwo</a>',
      }
    );

    this.map.addLayer(osmLayer);

    // add marker to the map
    let marker = leaflet
      .marker([this.dataAdicional.lat, this.dataAdicional.long], {
        draggable: true,
        icon: greenIcon,
      })
      .addTo(this.map);
    marker.on("drag", function (e) {
      console.log("marker drag event");
    });
    marker.on("dragstart", function (e) {});
    marker.on("dragend", (e) => {
      var marker = e.target;
      var position = marker.getLatLng();
      this.dataAdicional.lat = position.lat;
      this.dataAdicional.long = position.lng;
      console.log(position);
      marker
        .setLatLng(position, { draggable: "true" })
        .bindPopup(position)
        .update();
    });

    this.loadingService.hide();
  }

  clearForm() {
    this.data = null;
    this.create = false;
    this.dataRender.forEach((element) => {
      element.defaultValue = "";
    });
    location.reload();
  }

  edit(recinto: any) {
    console.log(recinto);

    this.data = recinto;
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará el recinto permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        this.api.delete(`/deleteRecinto/${this.data._id}`).subscribe((res) => {
          this.loadingService.hide();
          location.reload();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  update() {
    this.loadingService.show("Actualizando...");
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");

    let dataJson = {};
    this.dataRender.forEach((element) => {
      dataJson[element.formControl] = element.defaultValue;
    });

    dataJson["latitud"] = this.dataAdicional.lat;
    dataJson["longitud"] = this.dataAdicional.long;

    let sus = this.api.post2(`/createRecinto`, dataJson, headers);
    if (!this.create) {
      sus = this.api.update(
        `/updateRecinto/${this.data._id}`,
        dataJson,
        headers
      );
    }

    sus.subscribe(
      (res: any) => {
        this.loadingService.hide();
        if (res) {
          Swal.fire({
            icon: "success",
            title: this.create
              ? "Se generó el recinto correctamente."
              : "Se actualizo el recinto correctamente.",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.clearForm();
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.alertService.errorAlert(
          "Oops!",
          "Ocurrió un error, contacta al administrador"
        );
        this.loadingService.hide();
      }
    );
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataAdicional.img1.file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.dataAdicional.img1.b64 = reader.result;
    };
  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataAdicional.img2.file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.dataAdicional.img2.b64 = reader.result;
    };
  }

  addFederado() {
    if (
      !this.federadosRender.find((obj) => obj._id == this.newFederado._id) &&
      !this.feds.find((obj) => obj._id == this.newFederado._id) &&
      this.newFederado
    ) {
      this.newFederado.ngModelCounter = 1;

      this.federadosRender.push(this.newFederado);
      this.feds.push(this.newFederado);
    }

    this.addFederadoFMCH = "";
    this.addFederadoName = "";
    this.addFederadoTeam = "";

    this.newFederado = "";
  }

  searchFederadoByCurp() {
    try {
      if (this.addFederadoCURP.length === 18) {
        this.api
          .get(`/getFederadoByCurp/${this.addFederadoCURP}`)
          .subscribe((res) => {
            if (res.length >= 1) {
              console.log(res);

              this.addFederadoFMCH = res[0].no_registroFMCH;
              this.addFederadoName = res[0].nombre_completo;
              this.addFederadoTeam = res[0].equipo[0];

              this.newFederado = res[0];
            } else {
              console.log("Curp no Registrado");
            }
          });
      }
    } catch (error) {}
  }

  borraMesa(position) {
    this.federadosRender.splice(position, 1);
    this.feds.splice(position, 1);
    console.log(this.federadosRender);
    console.log(this.feds);
  }

  exportarFederados(): void {
    this.api.exportXLSX("/exportFederadosXLSX").subscribe((response) => {
      // let filename = response.headers.get('Content-Disposition')?.split(':')[1].split('=')[1];
      let blob = response.body as Blob;
      let a = document.createElement("a");
      a.download = "federados-file";
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }

  actualizarPerfil() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    this.feds.forEach((element) => {
      let dataJson = {};
      if (
        this.txtPerfiles === undefined ||
        this.txtPerfiles === "Selecciona Perfil..."
      ) {
        Swal.fire({
          //position: "top-end",
          icon: "error",
          title: "Seleccionar Perfil Correctamente.",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          //this.clearForm();
        });
      } else {
        dataJson["perfiles"] = [this.txtPerfiles];
        console.log(dataJson);

        this.api
          .update(`/updateFederado/${element._id}`, dataJson, headers)
          .subscribe((res) => {
            console.log(res);
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Se actualizo el Perfil correctamente.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });
          });
      }
    });
  }

  public validatePerfil(): boolean {
    if (this.userPerfil === "superadmin") {
      return (this.isPerfilDropDownDisabled = false);
    } else return (this.isPerfilDropDownDisabled = true);
  }

  openModal() {
    
      this.api.getNumeroConsecutivo().subscribe((res) => {
        // res.forEach((element) => {
        //   console.log("Número Consecutivo => ",element);
        // });
        console.log("Número Consecutivo => ",res);
        if(res.length < 1){
          const modalDiv = document.getElementById('myModal');
          if(modalDiv !== null){
            modalDiv.style.display = 'block';
            modalDiv.classList.add('show');
            modalDiv.setAttribute('aria-modal', 'true');
          }
        }
        // else {
          
        //   Swal.fire({
        //     //position: "top-end",
        //     icon: "error",
        //     title: `Ya existe un número consecutivo -> "${res.numCons[0].no_registroFMCH}"`,
        //     showConfirmButton: false,
        //     timer: 3000,
        //   })
        // }
      });
    
    
    // console.log(modalDiv.style.display);   

  }
  closeModal() {
    const modalDiv = document.getElementById('myModal');
    if(modalDiv !== null){
      modalDiv.style.display = 'none';
      modalDiv.classList.remove('show');
    }    
  }

  showOrHideNumCons() {
    this.api.getNumeroConsecutivo().subscribe((res) => {
      res.length > 0 ? this.yaExisteNumCons = true : this.yaExisteNumCons = false;
    });
    console.log(this.yaExisteNumCons);
  }

  createNumeroConsecutivo() {
    
    this.api.createNumeroConsecutivo(this.no_registroFMCH).subscribe((res: any) => {
      Swal.fire({
        //position: "top-end",
        icon: "success",
        title: `${res.msg}, -> ${res.savedNumeroConsecutivo.no_registroFMCH}`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.closeModal();
      })
    },
    error => {
      console.error(error);
    });
  }


mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}
}
