import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-busqueda-fed',
  templateUrl: './modal-busqueda-fed.component.html'
  // styleUrls: ['./modal-busqueda-fed.component.scss'],
})
export class ModalBusquedaFedComponent {

  constructor(public dialog: MatDialog) { }

  closeModal() {
    this.dialog.closeAll();
  }

}
