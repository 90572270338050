import { LoaderService } from './../../../services/loading-service';
import { AlertService } from './../../../services/alert.service';
import { Component, OnInit, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Asociacion, Equipo } from "../../interface/interfaces";
import { NbDateService } from "@nebular/theme";
import { FederadosService } from "../../federados/services/federados.service";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "ngx-registro",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.scss"],
})
export class RegistroEquipoComponent implements OnInit {
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  public equipoForm: FormGroup;
  hideModal: boolean;
  url: string;
  filename2: any;
  file2: string = "";
  min: Date;
  max: Date;
  url1: string;
  url2: string;
  camposIntegrante: boolean = false;
  alineacion: boolean = false;
  idEquipo: number;
  nuevoMiembro: any = {};
  miembros: any[] = [];
  equipoSeleccionado: any = {};

  b64: any = null;

  enter = null;

  constructor(
    protected dateService: NbDateService<Date>,
    public api: FederadosService,
    private router: Router,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private loadingService: LoaderService
  ) {
    this.min = this.dateService.addDay(this.dateService.today(), -5);
    this.max = this.dateService.addDay(this.dateService.today(), 5);

    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";

    this.equipoForm = this.formBuilder.group({
      nombreEquipo: ["Rancho el Pitayo 1"],
      asociacion: ["rancho el pitayo 1"],
      union: ["union test 2"],
      pais_origen: ["USA"],
      estado_origen: ["CALIFORNIA"],
      propietario_No_Registro_FMCH: ["35135181"],
      propietario_Nombre_Completo: ["propietario equipo test 1"],
      fecha_fundacion: ["1995-5-15"],
      ciudad: ["LA"],
      facebook: ["face.book"],
      instagram: ["@instagram"],
      nuevoCURP: [""],
      nuevoNombreCompleto: [""],
      importFile: [""],
    });
  }

  ngOnInit(): void {
    this.url = "/createEquipo";
    this.hideModal = false;
    this.mostrarEquipo();
  }
  
  mostrarEquipo() {
    this.api.get(this.url2).subscribe((res) => {
     //console.log("equipoEXTERNO", res);
      this.equipo = res.equipoArr;
    });
  }

  onImageChange3(e) {
    const files = e.target.files || e.dataTransfer.files;
   //console.log(files);

    if (files[0].type == "image/png" || files[0].type == "image/jpg" || files[0].type == "image/jpeg") {
      this.filename2 = "Selected file: " + files[0].name;
      this.file2 = files[0];

      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.b64 = reader.result;
        //console.log(this.b64);

      };
    } else {
      this.alertService.errorAlert("Oops!", "Solo formatos de imagen permitido (png, jpg, jpeg)");
    }
  }

  addEquipos() {
    this.loadingService.show("Generando equipo...");
    let formData = new FormData();
    const headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("nombreEquipo", this.equipoForm.value.nombreEquipo);
    formData.append("asociacion", this.equipoForm.value.asociacion);
    formData.append("union", this.equipoForm.value.union);
    formData.append("pais_origen", this.equipoForm.value.pais_origen);
    formData.append("estado_origen", this.equipoForm.value.estado_origen);
    formData.append(
      "propietario_No_Registro_FMCH",
      this.equipoForm.value.propietario_No_Registro_FMCH
    );
    formData.append(
      "propietario_Nombre_Completo",
      this.equipoForm.value.propietario_Nombre_Completo
    );
    formData.append("fecha_fundacion", this.equipoForm.value.fecha_fundacion);
    formData.append("ciudad", this.equipoForm.value.ciudad);
    formData.append("facebook", this.equipoForm.value.facebook);
    formData.append("instagram", this.equipoForm.value.instagram);
    formData.append("importFile", this.file2);

    this.api.post2(this.url, formData, headers).subscribe((res) => {
      if (res) {
        this.loadingService.hide();
        this.alertService.successAlert("Bien!", res.msg);
        this.clearForm();
        location.reload();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
      }
    }, (error: HttpErrorResponse) => {
      this.alertService.errorAlert("Error!", "Contacta al administrador");
    });
  }
  addCamposIntegrante() {
    this.camposIntegrante = !this.camposIntegrante;
  }
  addNuevoIntegranteEquipo() {
   //console.log(this.equipoSeleccionado);
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    const actualizarfederados = "/updateFederado/" + this.nuevoMiembro._id;
    this.nuevoMiembro.asociacion = null;
    this.nuevoMiembro.equipo = this.equipoSeleccionado.nombreEquipo;
    this.nuevoMiembro.estado_origen = null;
    this.nuevoMiembro.pais_origen = null;

    this.api
      .update(actualizarfederados, this.nuevoMiembro, headers) //
      .subscribe((res) => {
        if (res) {
          this.camposIntegrante = false;
          this.equipoForm.controls["nuevoCURP"].reset();
          this.equipoForm.controls["nuevoNombreCompleto"].reset();
          this.elegirMiembros(this.equipoSeleccionado);
        }
      });
  }
  elegirMiembros(equipo: any) {
   //console.log(equipo);

    this.equipoSeleccionado = equipo;
    const urlmiembros = "/getAllFederadosByEquipo/" + equipo._id;
    this.api.get(urlmiembros).subscribe((res) => {
      this.alineacion = true;
      this.miembros = res.arrFed;

    });
  }
  buscarCURP(event: any) {
    const CURP = event.target.value;
    const urlCURP = "/getFederadoByCurp/" + CURP;
    this.api.get(urlCURP).subscribe((res) => {


      this.equipoForm
        .get("nuevoNombreCompleto")
        .setValue(
          res[0].nombre_completo
        );


      this.nuevoMiembro = res[0];
     //console.log(this.nuevoMiembro.nombre_completo);
     //console.log(this.nuevoMiembro);
      //this.equipo = res;
    });
  }
  clearForm() {
    this.equipoForm.reset();
  }
}
