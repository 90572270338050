import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FederadosService } from '../../federados/services/federados.service'

@Component({
	selector: 'app-tabla-evento',
	templateUrl: './tabla.component.html',
	styleUrls: ['./tabla.component.scss'],
})
export class TablaEventoComponent implements OnInit {
	public events: any = [];
	public dias: any = [];

	constructor(private router: Router, private api: FederadosService) {}

	ngOnInit() {
		this.getEvents();
	}

	plus2() {
		this.router.navigate(['/pages/evento']);
	}
	
	historicos() {
		this.router.navigate(['/pages/eventos-historicos']);
	}

	getEvents() {

		let dataJson={}
	
		this.api.get('/getAllEventos').subscribe((res) => {
			if(res.hay_eventos){
				this.events = res.eventos
				console.log(this.events)
				let i=0
				this.events.forEach(element => {

					
					const [year,month, day,] = element.fecha_inicio_evento.split('-');
					const [year2,month2, day2,] = element.fecha_fin_evento.split('-');
					const date = new Date(+year, +month - 1, +day);
					const date2 = new Date(+year2, +month2 - 1, +day2);
					this.dias=date2.getDate()-date.getDate();
					this.events[i].dias=this.dias
				
					i++;

		
				});
			}
			else{
				this.events = [];
				console.log(res);
			}
				
			
		},(err) => {
				console.log(err)
		})
		
		//	this.dias=dataJson
		//	console.log(this.dias)
		console.log(this.dias);
	}

	editEvent(item: { _id: string }) {
		this.router.navigate(['/pages/evento', item._id])
	}
}
