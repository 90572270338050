import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-upload-img',
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.scss']
})
export class UploadImgComponent  {


	public form: {
		name: string;
		email: string;
		resume: File | null;
	};
 
	// I initialize the app component.
	constructor() {
 
		this.form = {
			name: "",
			email: "",
			resume: null
		};
 
	}
 
	// ---
	// PUBLIC METHODS.
	// ---
 
	// I handle the form processing.
	public processForm() : void {
 
		console.group( "Form View-Model" );
		console.log( "Name:", this.form.name );
		console.log( "Email:", this.form.email );
		console.log( "Resume:", this.form.resume );
		console.groupEnd();
 
	}
 
}