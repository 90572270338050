
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { LoaderService } from 'src/app/services/loading-service';
import { GenericService } from 'src/app/services/generic.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
declare var jsPDF;
declare var html2pdf;

@Component({
  selector: 'ngx-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  public create: boolean = false;

  public data: any = null;

  public id: Number = 0;

  public img = environment.urlServerimg;

  public equipos: any[] = [{}];

  public fed: any = null;
  public feds: any = null;

  public eq: any = null;

  public asoc: any = null;

  public year: number;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private loadingService: LoaderService,
    private genericService: GenericService,
    private router: Router
  ) { 
    
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.id = this.route.snapshot.params.type;
    console.log(this.id);

    switch (Number(this.id)) {
      case 1:
        this.eq = JSON.parse(localStorage.getItem("eq")).current;
        this.loadingService.show();
        this.genericService.sendGetParams(`${environment.getAllFederadosByEquipo}${this.eq._id}`, {}).subscribe((response: any) => {
          this.feds = response;
          this.loadingService.hide();
        }, (error: HttpErrorResponse) => {
          this.loadingService.hide();
        });
        //getAllFederadosByEquipo
        break;
      case 2:

        this.asoc = JSON.parse(localStorage.getItem("asoc")).current;
        console.log("THIS.ASOC",this.asoc)
        break;
      case 3:
        console.log("-----------------");

        this.fed = JSON.parse(localStorage.getItem("federado"));
        console.log(this.fed);

        break;
    }
  }

  print(nme: string = "file") {
    console.log("print");
    let id: any = document.getElementById("btn-prt");
    id.style.display = "none";
    var element = document.getElementById('printer');
    console.log(element);

    var opt = {
      margin: 0.5,
      filename: `${nme}.pdf`,
      image: { type: 'jpg', quality: 1 },
      html2canvas: { scale: 1, useCORS: true },
      jsPDF: { unit: 'cm', format: 'a2', orientation: 'portrait',compress: true },
      activeFontSize: 1,
    };
    //html2pdf(element);
    var worker = html2pdf().set(opt).from(element).save().then(()=>{
      console.log("pdf");
      id.style.display = "block";
    });

    

    
  }


  plus1() {

    
    window.history.back();

  }
  plus2() {

    
    window.history.back();

  }
  plus3() {

    
    window.history.back();

  }

}
