import { UtilService } from './../../../services/util.service';
import { ModalController } from '@ionic/angular';
import { CredencialPage } from './../../credencial/credencial.page';
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Component, EmbeddedViewRef, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { environment } from "../../../../environments/environment";
import { Asociacion, Equipo, Federados, Union } from "../../interface/interfaces";
import { FederadosService } from "../services/federados.service";
import { LoaderService } from './../../../services/loading-service';
@Component({
  selector: "ngx-detalles",
  templateUrl: "./detalles.component.html",
  styleUrls: ["./detalles.component.scss"],
})
export class DetallesComponent implements OnInit {
  public dataINEFront:any
  public dataINEBack:any
  ls: any = JSON.parse(localStorage.getItem('userSessionFederacion'));
  public userPerfil: String = this.ls.perfiles[0];
  public isCredencialBtnDisabled: Boolean = false;

  FederadosList: {};

  hideModal: boolean;
  url: string;
  urlupdate: string;
  filename2: any;
  file2: "";
  federadosList: Federados[] = [];
  asociacion: Asociacion[] = [];
  asociacionesList: string[];
  equipo: Equipo[] = [];
  equipoList: string[] = [];
  equipoData: any[];
  unionesList: Union[] = [];
  termSubscriber: Subscription;
  url1: string;
  url2: string;
  img: any;
  ids: any;
  aso: any;
  equi: any;

  public data: any = null;

  public dataRender: any = [
    {
      fieldset: [
        {
          id: "inp-nombre",
          formControl: "nombre",
          label: "Nombre",
          placeholder: "Nombre...",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-ap_paterno",
          formControl: "ap_paterno",
          label: "Apellido Paterno",
          placeholder: "Apellido Paterno...",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        }, {
          id: "inp-ap_materno",
          formControl: "ap_materno",
          label: "Apellido Materno",
          placeholder: "Apellido Materno...",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
       
      ],
      legend: "NOMBRE COMPLETO"
    },
    {
      fieldset: [
        {
          id: "inp-curp",
          formControl: "curp",
          label: "Curp",
          placeholder: "CURP",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-fecha_nac",
          formControl: "fecha_nac",
          label: "Fecha de Nacimiento",
          placeholder: "0000-00-00",
          required: true,
          defaultValue: "1984-01-01",
          type: "date",
          withError: false
        },  
        {
          id: "inp-sexo",
          formControl: "sexo",
          label: "Sexo",
          placeholder: "M",
          required: true,
          defaultValue: "",
          type: "autoCompletar",
          withError: false,
          dato: ['H', 'M']
        }, 
        {
          id: "inp-pais_origen",
          formControl: "pais_origen",
          label: "País de Union",
          placeholder: "México",
          required: true,
          defaultValue: "MEXICO",
          type: "autoCompletar",
          withError: false,
          dato: ['MEXICO', 'USA']
        },
        {
          id: "inp-estado_origen",
          formControl: "estado_origen",
          label: "Union",
          placeholder: "Jalisco",
          required: true,
          defaultValue: "MEXICO",
          type: "autoCompletar",
          withError: false,
          dato: []
        },
        {
          id: "inp-asociacion",
          formControl: "asociacion",
          label: "Asociación",
          placeholder: "Asociación",
          required: true,
          defaultValue: "federacion",
          type: "autoCompletar",
          withError: false,
          dato: [],
        },
        {
          id: "inp-tipo_federado",
          formControl: "tipo_federado",
          label: "Tipo Federado",
          placeholder: "Tipo Federado",
          required: true,
          defaultValue: "pendiente",
          type: "autoCompletar",
          withError: false,
          dato: ["Charro"]
        },
        {
          id: "inp-folio",
          formControl: "no_registroFMCH",
          label: "Folio FMCH",
          placeholder: "#####",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-equipo",
          formControl: "equipo",
          label: "Equipo",
          placeholder: "Equipo",
          required: true,
          defaultValue: "federacion",
          type: "autoCompletar",
          withError: false,
          dato: []
        },
       

      ],

      legend: "FECHA Y LUGAR DE NACIMIENTO"
    },
    {
      fieldset: [
        {
          id: "inp-email",
          formControl: "email",
          label: "Email",
          placeholder: "charros@jalisco.com",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-clave_pais",
          formControl: "clave_pais",
          label: "CÓDIGO DE PAÍS +52 (MX) ó +1 (USA)",
          placeholder: "+52",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
        {
          id: "inp-cel",
          formControl: "cel",
          label: "Cel",
          placeholder: "5551721447",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
        {
          id: "inp-domicilio_pais",
          formControl: "domicilio_pais",
          label: "País Residencia",
          placeholder: "Mexico",
          required: true,
          defaultValue: "pendiente",
          type: "autoCompletar",
          withError: false,
          dato: ['Mexico', 'USA']
        }, {
          id: "inp-domicilio_cp",
          formControl: "domicilio_cp",
          label: "CP/ZIP CODE de Residencia",
          placeholder: "89670",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        }, {
          id: "inp-calle",
          formControl: "domicilio_colonia",
          label: "Calle y número",
          placeholder: "Calle ...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
      ], legend: "DOMICILIO ACTUAL"
    },

    {
      fieldset: [
        {
          id: "inp-nombre_completo",
          formControl: "nombre_tutor",
          label: "NOMBRE COMPLETO TUTOR",
          placeholder: "Nombre...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },

        {
          id: "inp-parentesco",
          formControl: "parentesco_tutor",
          label: "Parentesco",
          placeholder: "Familiar...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
        {
          id: "inp-cel",
          formControl: "cel_emergencias",
          label: "Cel para emergencias",
          placeholder: "5551721447",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
        {
          id: "inp-curp_tutor",
          formControl: "curp_tutor",
          label: "CURP Benef o Tutor",
          placeholder: "CURP",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        }, 
        {
          id: "inp-escolaridad",
          formControl: "escolaridad",
          label: "Escolaridad",
          placeholder: "Escolaridad",
          required: true,
          defaultValue: "federacion",
          type: "autoCompletar",
          withError: false,
          dato: [
            'Primaria',
            'Secundaria',
            "Media Superior",
            "Licenciatura",
            "Otro",
          ]
        },
      
      ], legend: "DATOS DE CONTACTO"
    },
    
    //4
    {
      fieldset: [
        {
          id: "inp-facebook",
          formControl: "facebook",
          label: "Facebook",
          placeholder: "@Facebook...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
       
        {
          id: "inp-instagram",
          formControl: "instagram",
          label: "instagram",
          placeholder: "@Instagram...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },
       
        {
          id: "inp-twitter",
          formControl: "twitter",
          label: "twitter",
          placeholder: "@twitter...",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false
        },

      ], legend: "REDES SOCIALES  "
    },
   //5

   { 
    fieldset:[
      {      
      id: "inp-status_pago",
      formControl: "status_pago",
      label: "Estatus Pago Federación",
      placeholder: "Estatus pago",
      required: true,
      defaultValue: "Expirado",
      type: "autoCompletar",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-seguro_status",
      formControl: "seguro_status",
      label: "Seguro Estatus",
      placeholder: "Seguro Estatus",
      required: true,
      defaultValue: "Expirado",
      type: "autoCompletar",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-comprobante_pago_url",
      formControl: "comprobante_pago_url",
      label: "Comprobante",
      placeholder: "Comprobante...",
      required: true,
      defaultValue: "pendiente",
      type: "pago",
      withError: false,
      
    },
  ], legend: "DATOS DE AFILICION GENERADOS POR SISTEMAS"

   },
//6
    {
      id: "inp-categoria_federado",
      formControl: "categoria_federado",
      label: "Categoría Federado",
      placeholder: "Categoría",
      required: true,
      defaultValue: "pendiente",
      type: "autoCompletar",
      withError: false,
      dato: [],
    },
    //7
    {
      id: "inp-cuota_costo",
      formControl: "cuota_costo",
      label: "Cuota Federación",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },
    //8
    {
      id: "inp-fecha_pago_cuota_federacion",
      formControl: "fecha_pago_cuota_federacion",
      label: "Cuota Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },

//9
    {
      id: "inp-seguro_poliza",
      formControl: "seguro_poliza",
      label: "Seguro póliza",
      placeholder: "555555555",
      required: true,
      defaultValue: "pendiente",
      type: "autoCompletar",
      withError: false,
      dato: []
    },

    //10
    {
      id: "inp-seguro_costo",
      formControl: "seguro_costo",
      label: "Seguro Costo",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },

    //11
    {
      id: "inp-seguro_fecha_pago",
      formControl: "seguro_fecha_pago",
      label: "Seguro Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },
    //12
   
    {
      derecha: [
        {
          id: "inp-foto",
          formControl: "foto",
          label: "foto",
          placeholder: "foto",
          required: true,
          defaultValue: "pendiente",
          type: "text",
          withError: false,
          noVisual: true
        },
        
       
        
      ]
    },
    
  ];

  public nombre_completo: any = "";

  public img2: any = environment.urlServerimg;

  public fed: any = null;

  public id: any = null;

  public datosFederadoByCurp: any = {};
  public federadoCurpValid: boolean;

  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    private loadingService: LoaderService,
    private modalCtrl: ModalController,
    private utilService: UtilService
    //@Inject(MAT_DIALOG_DATA) public data: any,
    //public dialogRef: MatDialogRef<any>,
  ) {

    let objRender: any = {};

    this.dataRender.forEach(element => {
      if (!element.fieldset) {
        let validators: any[] = [];
        validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
        if (element.required) {
          validators.push(Validators.required);
        }

        objRender[element.formControl] = validators;
      } else {
        element.fieldset.forEach(element => {
          let validators: any[] = [];
          validators.push(element.defaultValue ? element.defaultValue : ""); //en caso de tener valor default colocarlo
          if (element.required) {
            validators.push(Validators.required);
          }

          objRender[element.formControl] = validators;
        });
      }
    });

    // console.log(this.dataRender);
    
  }

  searchFederadoByCurp() {
    const inputCurpValue = this.dataRender[1].fieldset[0].defaultValue;
    // console.log("searchFederadoByCurp input curp ",inputCurpValue);
    
    if(inputCurpValue == 'AMERICANO') {
      this.federadoCurpValid = true;
      return;
    }

    if(inputCurpValue.length === 18) {
      this.api.get(`/getFederadoByCurp/${inputCurpValue}`).subscribe((res) => {


        let objForm: string ;
        this.dataRender[1] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });
        this.dataRender[0] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });

        this.dataRender[2] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });
        this.dataRender[3] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });
        this.dataRender[4] .fieldset.forEach(itm => {
          let objForm: any = {};
          objForm=res[0];
          itm.defaultValue=objForm[itm.formControl];
        });


        if(res.length >= 1) {
          this.router.navigateByUrl("pages/federados/detalles/" + res[0]._id);
          this.federadoCurpValid = true;
        } else {
          console.log('Curp no Registrado');
          this.federadoCurpValid = false;
        }
      });
    }
  }

  // buscarEstados() {
  //   this.api.get("/getAllEstados").subscribe((res) => {
  //     res.edoArr.forEach(element => {
  //       this.dataRender[1].fieldset[4].dato.push(element.nombreEstado);
  //     });
  //   });
  // }

  buscaEquipos(asociacion) {
    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    this.loadingService.show("Cargando...");
    this.dataRender[1].fieldset[8].dato=[]
    this.api.get("/getAllEquipos").subscribe((res) => {
      
      let a = res.eqArr.map(e=>{
      if(e.asociacion == asociacion){
      console.log(e.nombreEquipo)
      this.dataRender[1].fieldset[8].defaultValue=e.nombreEquipo;
      this.dataRender[1].fieldset[8].dato.push(e.nombreEquipo)
      this.loadingService.hide();
    }
      
      });
      
    });
    
    console.log(this.dataRender[1].fieldset[8].dato)
  }

  btn() {
    document.getElementById("hf-logo").click();
  }

  buscaAsociaciones() {
    this.api.get("/getAllAsociaciones").subscribe((res) => {
      res.asocArr.forEach(element => {
        this.dataRender[1].fieldset[5].dato.push(element.nombreAsociacion);
      });
    });
  }

  buscaUniones(){
    this.api.get("/getAllUniones").subscribe((res) => {
      res.UnionArr.forEach(union => {
        this.dataRender[1].fieldset[4].dato.push(union.nombre);
      })
    })
  }

  buscarCategorias() {
    this.api.get("/getAllCategorias").subscribe((res) => {
      res.forEach(element => {
        this.dataRender[6].dato.push(element.descripcion_categoria);
      });
    });
  }

  buscarPolizas() {
    this.api.get("/getAllPolizas").subscribe((res) => {
      // console.log(res);
      
      res.forEach(element => {
        this.dataRender[9].dato.push(element.nombre_poliza);
      });
    });
  }

  public validatePerfil():any{
    // if(this.userPerfil === 'superadmin'){
    //   return this.isAsocEdoDisabled = false;
    // }
    if(this.userPerfil !== 'superadmin' || this.userPerfil !== 'funcionario'){
      // console.log("datarender ",this.dataRender);
      // console.log("LS ==> ",this.ls);
      let newDataRenderObj = this.dataRender.map((arr: any) => {
        console.log("ARR.FIELDSET => ",arr);
        const newArr = {...arr};
        // let fSArr = [];

        if(arr.legend === 'DATOS DE AFILICION GENERADOS POR SISTEMAS'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-status_pago'){
              newFieldsetArr.type = 'disabled';
            }
            
            if(a.id === 'inp-seguro_status'){
              newFieldsetArr.type = 'disabled';
            }
            
            if(a.id === 'inp-comprobante_pago_url'){
             // newFieldsetArr.type = 'disabled';
            }
            return newFieldsetArr;
          })
        }

        if(arr.legend === 'NOMBRE COMPLETO'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-perfil'){
              newFieldsetArr.type = 'disabled';          
            }
            return newFieldsetArr;
          })
        }
        
        if(arr.legend === 'FECHA Y LUGAR DE NACIMIENTO'){
          newArr.fieldset = arr.fieldset.map((a: any) => {
            const newFieldsetArr = {...a};
            
            if(a.id === 'inp-folio'){
              newFieldsetArr.type = 'disabled';
              this.api.getNumeroConsecutivo().subscribe((res) => {
                if(res.length > 0){
                  newFieldsetArr.defaultValue = `${res.numCons[0].no_registroFMCH}`; 
                }                
              })
                       
            }
            return newFieldsetArr;
          })
        }
        
        
        if(arr.id === 'inp-categoria_federado'){
          newArr.type = 'disabled';
        }

        if(arr.id === 'inp-cuota_costo'){
          newArr.type = 'disabled';
        }
        
        if(arr.id === 'inp-fecha_pago_cuota_federacion'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_poliza'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_costo'){
          newArr.type = 'disabled';          
        }
        
        if(arr.id === 'inp-seguro_fecha_pago'){
          newArr.type = 'disabled';          
        }
        return newArr;
      })
      
      this.dataRender = newDataRenderObj;
      
    } 
  }

  ngOnInit(): void {
    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    this.loadingService.show("Cargando...");
    this.utilService.scrollTo(document.getElementById("bodyt"), 0, 0);
    //this.buscaEquipos();
    this.buscaAsociaciones();
    this.buscaUniones();
    // this.buscarEstados();
    this.buscarCategorias();
    this.buscarPolizas();
    this.validatePerfil();
    this.hideModal = false;
   
    this.termSubscriber = this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        // console.log(params);
        
        this.ids = params["id"];
        if (this.ids) {
          this.id = this.ids;
          
        }


        if (!this.ids) {
          this.data = { id: this.ids };
          this.ids = this.data.id;
          
        }
        //console.log(this.ids);
        if (this.id) {
          this.url = "/getFederadoById/" + this.ids;
          this.api.get(this.url).subscribe((res) => {
            console.log("federados", res);
            this.fed = res[0];

            this.nombre_completo = res[0].nombre_completo;

            res.forEach((res) => {
              // console.log(res);

              this.ids = res._id
              this.urlupdate = "/updateFederado/" + this.ids;
              this.img = res.foto
              if (res.foto) {
                this.img = environment.urlServerimg + res.foto;
                //console.log(this.img);
              }

              let objForm: any = {};
              this.dataRender.forEach(itm => {
                //if(itm.formControl.includes(itm[])){
                if (!itm.fieldset && !itm.derecha) {
                  objForm[itm.formControl] = res[itm.formControl];
                  itm.defaultValue = res[itm.formControl];

                  if (itm.formControl == "foto") {
                    console.log("FOOOOOOTOOOOO");
                    console.log(itm);

                    this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];

                  }
                } else if (itm.derecha) {
                  itm.derecha.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                      this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      this.loadingService.hide();
                    }
                  });

                } else {
                  itm.fieldset.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                      this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      
                    }
                  });

                }
                //}
              });
              // console.log(this.dataRender[12].derecha[0]);

              //this.generalForm.setValue(objForm);
            });
          });
          
        } else {
          this.dataRender[12].derecha[0]["foto"] = "";
          this.loadingService.hide();
        }
      }
      
    );
    this.api.get(`/getIneByFederadoId/${this.ids}`).subscribe((res) => {

        console.log(res[0].rutaFotoDelantera)
      
      this.dataINEFront=res[0].rutaFotoDelantera.replace('uploads/', '');
     
       console.log('----',this.dataINEFront)
     });
     
    if(this.userPerfil === 'funcionario' || this.userPerfil === 'superadmin'){
      this.isCredencialBtnDisabled = false;
    }
    else this.isCredencialBtnDisabled = true;
    
  }


  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[12].derecha[0].defaultValue = files[0];
    this.dataRender[12].derecha[0].foto = "";

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.dataRender[12].derecha[0].fotob64 = reader.result;
    };

  }

  validateInputs() {
    let isInputValid = true;

    this.dataRender.forEach(element => {
      if(element.fieldset) {
        element.fieldset.forEach(e => {
          if(e.defaultValue == '') {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });
          };
        });
      }else if(element.derecha) {
        
        element.derecha.forEach(e => {
          if(e.defaultValue == '' && e.label !== 'Comprobante' ) {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });

          };
        });
      }else {
        if(element.defaultValue == '' && element.id !== 'inp-facebook' && element.id !== 'inp-instagram' && element.id !== 'inp-twitter') {
          isInputValid = false;
          Swal.fire({
            //position: "top-end",
            icon: "success",
            title: "Faltan Datos por en  " + element.label,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
        };
      }
    });

    return isInputValid;
  }

  addFederados() {
    console.log(this.validateInputs());
    
    if(this.validateInputs()) {
      this.loadingService.show("Actualizando");
      let formData = new FormData();
      const headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Accept", "application/json");
      //formData.append("email", this.generalForm.value.email);
      /*  formData.append("nombre", this.generalForm.value.nombre);
       formData.append("ap_paterno", this.generalForm.value.ap_paterno);
       formData.append("ap_materno", this.generalForm.value.ap_materno);
       formData.append("fecha_nac", this.generalForm.value.fecha_nac);
       formData.append("curp", this.generalForm.value.curp);
       formData.append("sexo", this.generalForm.value.sexo);
       formData.append("pais_origen", this.generalForm.value.pais_origen);
       formData.append("estado_origen", this.generalForm.value.estado_origen);
       formData.append("importFile", this.file2);
       formData.append("domicilio_pais", this.generalForm.value.domicilio_pais);
       formData.append("domicilio_cp", this.generalForm.value.domicilio_cp);
       formData.append("clave_pais", this.generalForm.value.clave_pais);
       formData.append("cel", this.generalForm.value.cel);
       formData.append("tipo_federado", this.generalForm.value.tipo_federado);
       formData.append(
         "categoria_federado",
         this.generalForm.value.categoria_federado
       );
       formData.append("seguro_costo", this.generalForm.value.seguro_costo);
       formData.append("seguro_status", this.generalForm.value.seguro_status);
       formData.append("seguro_poliza", this.generalForm.value.seguro_poliza);
       formData.append(
         "seguro_fecha_pago",
         this.generalForm.value.seguro_fecha_pago
       );
       formData.append("seguro_vigencia", this.generalForm.value);
       formData.append(
         "fecha_pago_cuota_federacion",
         this.generalForm.value.fecha_pago_cuota_federacion
       );
       formData.append("cuota_costo", this.generalForm.value.cuota_costo);
       formData.append(
         "comprobante_pago_url",
         this.generalForm.value.comprobante_pago_url
       );
       formData.append("vencimiento", this.generalForm.value.vencimiento);
       formData.append("status_pago", this.generalForm.value.status_pago);
       formData.append("asociacion", this.generalForm.value.asociacion);
       formData.append("equipo", this.generalForm.value.equipo);
       formData.append("perfil1", this.generalForm.value.perfiles);
    */
      //formData.append("perfil2", this.federadosForm.value.perfil2);
  
      let dataJson = {};
      this.dataRender.forEach(element => {
       
        if (!element.fieldset && !element.derecha ) {
          if (element.defaultValue == undefined) {
            element.defaultValue = "";
          }
       
          if (element.formControl == "foto") {
          } else {
            formData.append(element.formControl, String(element.defaultValue));
            dataJson[element.formControl] = element.defaultValue;
          }
  
        } else if (element.derecha) {
          element.derecha.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
             if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
          });
        } else {
          console.log("datos",element.fieldset)
          element.fieldset.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
            if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
  
  
          });
        }
  
      });
      formData.delete("foto");
      /* formData.append("importFile", this.dataRender[12].derecha[0].defaultValue); */
  
      if (this.dataRender[12].derecha[0].foto.length <= 0) {
        formData.append("importFile", this.dataRender[12].derecha[0].defaultValue);
        dataJson["importFile"] = this.dataRender[12].derecha[0].defaultValue;
      } else {
        formData.append("importFile", this.dataRender[12].derecha[0].foto);
        dataJson["importFile"] = this.dataRender[12].derecha[0].foto;
      }
  
      console.log(dataJson);
  
      console.log(formData);
  
      let url = this.ids ? this.urlupdate : "/createFederadoUser";
  
      let sus = this.api.update(url, formData, headers);
      if (!this.ids) {
        sus = this.api.post(url, formData);
      }
      sus.subscribe((res: any) => {
        this.loadingService.hide();
        console.log("Response del createFed => ",res);
        
        if (res.status === 200) {
          // this.clearForm();
          // this.showWareHoouse();
          // this.router.navigateByUrl('/usuario/u/warehouseCheck');

          let d:any = {
            //position: "top-end",
            icon: "success",
            title: url === '/createFederadoUser' ? `${res.msg}` : `${res.msg}`,
            showConfirmButton: false,
            timer: 3000,
          };
          
          Swal.fire(d).then((result) => {
            this.goTable();
          });
        }
        else {
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: url === '/createFederadoUser' ? `${res.msg}` : "No hemos podido actualizar el federado, intenta nuevamente",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            // this.goTable(); 
          });
        }
      }
      , (error: HttpErrorResponse) => {
        
      });
    }
  }

  clearForm() {
    //this.federadosForm.reset();
    //this.generalForm.reset();

    let res = this.fed;

    this.ids = res._id
    this.urlupdate = "/updateFederado/" + this.ids;
    this.img = res.foto
    if (res.foto) {
      this.img = environment.urlServerimg + res.foto;
      //console.log(this.img);
    }

    let objForm: any = {};
    this.dataRender.forEach(itm => {
      //if(itm.formControl.includes(itm[])){
      if (!itm.fieldset && !itm.derecha) {
        itm.defaultValue = "";
        if (itm.formControl == "foto") {
          this.dataRender[12].derecha[0].foto = "";
          delete this.dataRender[12].derecha[0].fotob64;
        }
      } else if (itm.derecha) {
        itm.derecha.forEach(itm => {
          itm.defaultValue = "";
          if (itm.formControl == "foto") {
            this.dataRender[12].derecha[0].foto = "";
            delete this.dataRender[12].derecha[0].fotob64;
          }
        });
      } else {
        itm.fieldset.forEach(itm => {
          itm.defaultValue = "";
          if (itm.formControl == "foto") {
            this.dataRender[12].derecha[0].foto = "";
            delete this.dataRender[12].derecha[0].fotob64;
          }
        });
      }
      //}
    });
    this.router.navigateByUrl("/pages/federados/tabla");

  }

  goTable() {
    //this.closeDialog();
    window.history.back();
  }

  closeDialog() {
    window.history.back();
  }

  plus() {
    this.clearForm();
    this.router.navigateByUrl("/pages/federados/registro");
  }

  delete() {
    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará el federado permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result:any) => {
      if (result.isConfirmed) {

        const url2 = "/deleteFederado/" + this.ids;
        this.api.delete(url2).subscribe((res3) => {
          //console.log("delete", res3);
          //this.mostrarFederados();
          this.goTable();
          // location.reload();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  print() {
    localStorage.setItem("federado", JSON.stringify(this.fed));
    this.router.navigate(["/", "pages", "print", "3"]);
  }

  async openCredencial(){
    // console.log("Entró");
    const inputCurpValue = this.dataRender[1].fieldset[0].defaultValue;
    this.searchFederadoByCurp();
    console.log(inputCurpValue, this.federadoCurpValid);
    if(inputCurpValue !== '' && this.federadoCurpValid) {
      this.utilService.scrollTo(document.getElementById("bodyt"), 0, 0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(async () => {
        let modal = await this.modalCtrl.create({
          component: CredencialPage,
          componentProps: { federado: this.fed },
          cssClass: 'setting-modal',
          backdropDismiss: false,
        });
    
        modal.present();
      }, 500);
    }
  }

  clicked(){
    document.getElementById('hf-logo').click();
  }

  inputChange(event: Event) {
    
    // console.log(event);
   
    
  }

  inputAsociacion(evt){

    console.log(evt);
    this.buscaEquipos(evt.trim())
  }


  back(){

    window.history.back();
  }
}

