import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { FederadosService } from '../federados/services/federados.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { PaypalComponent } from '../paypal/paypal.component';
import { Router } from '@angular/router';
import { LoaderService } from './../../services/loading-service';
declare var html2pdf;

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss'],
})
export class PagosComponent implements OnInit {
  public feds: any[] = [];
  public federadosRender: any[] = [];
  public cuotaFederado;
  public equipoId: any = null;
  public img: any;
  public polizas: any[];
  public nombres_polizas: any[] = [];
  public federadosToDelete: any[] = [];
  public federadosPagados = [];
  public alineacion = [];
  public equipoPais: String ="";
  public count = 0;

  public unionName;
  public asociacionName;
  public equipoName;
  public fechaDePago;
  public totalPagado;

  public payerName;
  public payerEmail;
  public description;
  public transactionId;

  public subTotal: number = 0;
  public costoTotalEquipo: number = 0;

  private paypalUrl: string;
  private pagoId: any;

  private alineacionDeEquipoActiva: boolean;

  public addFederadoCURP: string;
  public addFederadoFMCH;
  public addFederadoName;
  public addFederadoTeam;
  public newFederado;

  termSubscriber: Subscription;

  constructor(
    private router: Router,
    private loadingService: LoaderService,
    private modalCtrl: ModalController,
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    private http: HttpClient
  ) { 
    this.img = environment.urlServerimg;
  }

  async ngOnInit() {
    const errorSection = document.getElementById('btnBack');
    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    this.loadingService.show("Cargando...");
    console.log(this.router.url)
    const sectionPagos = document.getElementById('pagos');
    const sectionAlineacion = document.getElementById('alineacion-pagada');
    const printBtn = document.getElementById('printBtn');

    this.termSubscriber = this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        this.equipoId = params["id_Equipo"];
        this.pagoId = params["id_PAGO"];
      }


    );

    this.equipoPais = localStorage.getItem('equipoPais');

    await this.mostrarFederados();
    await this.getPolizas();
    await this.getCuotasAfiliacion();
    this.setDefaultPoliza();
    this.setFederadoCostoTotal();
    this.sumaSubtotal();
    this.calcularCostoTotal();

    if(this.pagoId == 'false') {
      sectionAlineacion.classList.add('disabled');
      printBtn.classList.add('disabled');
      this.loadingService.hide();
    } else {
      errorSection.classList.remove('disabled');
      await this.cambiarStatusPago();
      this.cambiarFederadosDeEquipo();
      sectionPagos.classList.add('disabled');
      this.loadingService.hide();
    } 
  }

  searchFederadoByCurp() {
    try {
      if(this.addFederadoCURP.length === 18) {
        this.api.get(`/getFederadoByCurp/${this.addFederadoCURP}`).subscribe((res) => {
          if(res.length >= 1) {
            console.log(res);
  
            this.addFederadoFMCH = res[0].no_registroFMCH;
            this.addFederadoName = res[0].nombre_completo;
            this.addFederadoTeam = res[0].equipo[0];
  
            this.newFederado = res[0];
          } else {
           // console.log('Curp no Registrado');
            
          }
        });
      }
      else{
        this.api.get(`/getFederadoByRegistroFMCH/${this.addFederadoCURP.toUpperCase()}`).subscribe((res) => {
          if(res.length >= 1) {
            console.log(res);
  
            this.addFederadoFMCH = res[0].no_registroFMCH;
            this.addFederadoName = res[0].nombre_completo;
            this.addFederadoTeam = res[0].equipo[0];
  
            this.newFederado = res[0];
          } else {
           console.log('no Registrado');
            
          }
        });



      }
    } catch (error) {
      
    }
  }

  addFederado() {
    if (!this.federadosRender.find(obj => obj._id == this.newFederado._id) && !this.feds.find(obj => obj._id == this.newFederado._id) && this.newFederado) {
      this.newFederado.seguro_poliza = localStorage.getItem('equipoPais') === 'mexico' ? 'UM0008542' : 'SIN SEGURO'; //validación si es nacional o extranjero
      this.newFederado.ngModelCounter = 1;
      this.newFederado.seguro_costo = localStorage.getItem('equipoPais') === 'mexico' ? this.polizas.find(p => p.nombre_poliza === 'UM0008542').cuota_prima : this.polizas.find(p => p.nombre_poliza === 'SIN SEGURO').cuota_prima
      this.newFederado.costo_total = Number(this.newFederado.seguro_costo) + this.cuotaFederado;
      console.log(this.newFederado.seguro_poliza);
      console.log(this.newFederado.seguro_costo);

      this.federadosRender.push(this.newFederado);
      this.feds.push(this.newFederado);
    }

    this.addFederadoFMCH = '';
    this.addFederadoName = '';
    this.addFederadoTeam = '';

    this.newFederado = '';
  }

  async mostrarFederados() {
    return new Promise((resolve, reject) => {
      try {



        console.log("this.equipoId ",this.equipoId);
        this.api.get("/getAllFederadosByEquipo/" + this.equipoId).subscribe((res) => {
          this.feds = res.arrFed.filter(obj => obj.status_pago !== 'vigente');
          this.federadosRender = res.arrFed.filter(obj => obj.status_pago !== 'vigente');
    
          this.federadosRender.forEach(obj => {
            obj.ngModelCounter = 0;
          });
    
          this.asociacionName = res.arrFed[0].asociacion[0].toUpperCase();
          this.equipoName = JSON.parse(localStorage.getItem('nombreEquipo'));
          this.unionName= res.arrFed[0].estado_origen[0].toUpperCase();
          console.log(this.unionName);
          let i = 0;
          
          res.arrFed.forEach(element => {
            if(element.alineacion == true) {
              i++;
            }
          });

          i >= 8 ? this.alineacionDeEquipoActiva = true : this.alineacionDeEquipoActiva = false;

          resolve(console.log('mostrarFederados\n', res));
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  setFederadoCostoTotal() {
    this.federadosRender.forEach(obj => {
      obj.costo_total = Number(obj.seguro_costo) + this.cuotaFederado;
    });

    console.log(this.cuotaFederado);
    
    console.log('setFederadoCostoTotal');
  }

  setDefaultPoliza() {
    this.feds.forEach(obj => {
      obj.seguro_poliza = this.equipoPais === 'mexico' ? 'UM0008542' : 'SIN SEGURO';
    });

    this.federadosRender.forEach(obj => {
      // obj.seguro_costo = this.polizas[this.polizas.length-4].cuota_prima;
      obj.seguro_costo = this.equipoPais === 'mexico' ? this.polizas.find(p => p.nombre_poliza === 'UM0008542').cuota_prima : this.polizas.find(p => p.nombre_poliza === 'SIN SEGURO').cuota_prima;
      obj.seguro_poliza = this.equipoPais === 'mexico' ? 'UM0008542' : 'SIN SEGURO';
      console.log('setDefaultPoliza');

    });

  }

  async getPolizas() {
    return new Promise((resolve, reject) => {
      this.api.get("/getAllPolizas").subscribe((res) => {
        this.polizas = res;
  
        res.forEach(element => {
          this.nombres_polizas.push({nombre:element.cuota_prima , id:element.nombre_poliza
          });
        });      
        resolve('getPolizas')
      });
    });
  }

  async getCuotasAfiliacion() {
    return new Promise((resolve, reject) => {
      this.api.get("/getCuotaAfiliacionByDate").subscribe((res) => {
        if(localStorage.getItem('equipoPais') === 'mexico'){
          this.cuotaFederado = res[0].cuota_afiliacion;
        }
        else{
          this.cuotaFederado = res[0].cuota_afiliacion_extranjero;
        }
        console.log("cuotaFederado", this.cuotaFederado);
        resolve(console.log('getCuotaAfiliacionByDate'));
      });
    });
  }

  changeAlineacionStatus(event: Event) {
    const element = event.target as HTMLElement;
    element.innerText == '+' ? element.innerText = '-' : element.innerText = '+';

    element.classList.toggle('add');
    element.classList.toggle('delete');

    if(event.type == 'click') this.agregarFederadosAlSubtotal(element);
  }

  agregarFederadosAlSubtotal(element) {
    let federado = this.federadosToDelete.find(obj => obj.no_registroFMCH == element.parentElement.parentElement.childNodes[1]['innerText']);
    
      if (element['innerText'] == '+' && !this.federadosToDelete.includes(element.parentElement.parentElement.childNodes[1]['innerText'])){
        const costosArr = [...element.parentElement.parentElement.childNodes];
        const costoTotalFed = costosArr.find(child => child.id == 'costo-total')['innerText'] ;
        const federadoToAdd = this.federadosRender.find(e => e.no_registroFMCH == element.parentElement.parentElement.childNodes[1]['innerText']);
        
        federadoToAdd.costoTotal = Number(costoTotalFed.slice(1));
        this.federadosToDelete.push(federadoToAdd);

      } else if (element['innerText'] == '-' && this.federadosToDelete.includes(federado)) {
        this.federadosToDelete.splice(this.federadosToDelete.indexOf(federado), 1);

      }

      if(this.federadosToDelete.length >= 1) {
        this.calcularCostoSubTotal();
        this.calcularCostoTotal();
      } else {
        this.calcularCostoTotal();
      }
  }

  // Suma el subtotal del equipo antes de restar el costo de los federados
  sumaSubtotal() {
      let total = 0;
  
      this.federadosRender.forEach(element => {
        total = total + Number(element.costo_total);
      });

      this.subTotal = total;
  }

  // Calcula el costo subtotal del equipo restando federado por federado que sera eliminado
  calcularCostoSubTotal() {
    let costoSubTotal = this.subTotal;

    this.federadosToDelete.forEach(element => {
      element.costoSubTotal = costoSubTotal - element.costoTotal;
      costoSubTotal = costoSubTotal - element.costoTotal;           
    });
  }

  
  // Calcula el costo total del equipo restando el costo de los federados que seran eliminados
  calcularCostoTotal() {
    if (this.federadosToDelete.length >= 1) {
      let suma = 0;

      this.federadosToDelete.forEach(obj => {
        suma = suma + obj.costoTotal;
      });

      this.costoTotalEquipo = this.subTotal - suma;
    } else {
      this.costoTotalEquipo = this.subTotal;
    }
  }

  async userNamengmodelchange(value, id){
    try {
      await this.setFederadoPoliza(value, id);
     // console.log(this.setFederadoPoliza)
      this.sumaSubtotal();
      this.calcularCostoSubTotal();
      this.calcularCostoTotal();
    } catch {

    }
  }

  async setFederadoPoliza(poliza, id) {
    return new Promise((resolve, reject) => {
      try {
        const federado = this.feds.find(obj => obj._id == id);
        const federadoRender = this.federadosRender.find(obj => obj._id == id);

        if(federadoRender.ngModelCounter < 2) {
          federadoRender.ngModelCounter++;
        } else {
          const cuotaPoliza = this.polizas.find(obj => obj.nombre_poliza == poliza).cuota_prima;
          const nombrePoliza = this.polizas.find(obj => obj.nombre_poliza == poliza).nombre_poliza;
          
          federado.seguro_poliza = nombrePoliza;
          federado.seguro_costo = cuotaPoliza;
          console.log("cuotaPoliza => ",cuotaPoliza);
          federadoRender.seguro_poliza = nombrePoliza;
          federadoRender.seguro_costo = cuotaPoliza;
          federadoRender.costo_total = federadoRender.seguro_costo + this.cuotaFederado;

        }

        resolve('resuelto');

      } catch (error) {
        reject(new Error(error))
      }
    });
   
  }

  procederPagoBtn(){ 
    this.loadingService.show("Cargando...");
    if(this.alineacionDeEquipoActiva) {
      this.actualizarPolizas();
      this.createPaypalOrder();
    } else {
      if (this.feds.length - this.federadosToDelete.length >= 8) {
        console.log(this.feds)
        this.actualizarPolizas();
        this.createPaypalOrder();
      } else {
        const config = {
          totalDeFederados: this.feds.length - this.federadosToDelete.length
        }

        this.openPaypalModal(config);
        
      }
    }
  }

  actualizarPolizas() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    this.feds.forEach(element => {
      let dataJson = {};
      dataJson["status_pago"] ="pendiente";
      dataJson["seguro_status"] = "pendiente";
      dataJson["seguro_poliza"] = element.seguro_poliza;
      dataJson["seguro_costo"] = element.seguro_costo;
      dataJson["cuota_costo"] = this.cuotaFederado ;
      //console.log(element.cuota_prima);
      console.log(dataJson);
      

      this.api.update(`/updateFederado/${element._id}`, dataJson, headers).subscribe((res) => {
        console.log(res);
      });
    });
  }

  createPaypalOrder() {
    if(this.feds.length > 0) {
      const equipo = JSON.parse(localStorage.getItem('nombreEquipo')) || false;
      // console.log("this.feds[0].equipo ", this.feds[0].equipo);
      // console.log("localstorage equipo ", equipo);
      // const equipo = this.feds[0].equipo || false;
      let ids = [];
      let dataJson = {}

      console.log("this.feds ", this.feds);
      console.log("this.federadosToDelete ", this.federadosToDelete);
  
      this.feds.forEach(element => {
        if(!this.federadosToDelete.find(obj => obj._id == element._id)) {
          ids.push(element._id);
        }
      });
  
      dataJson["costo_total"] = this.costoTotalEquipo;
      dataJson["idFederados"] = ids;
      dataJson["equipo"] = equipo;
      dataJson["estado_origen"] = this.unionName;

      // console.log("EQUIPOID ==> ",JSON.stringify(localStorage.getItem('equipoId')));
  
      this.api.post(`/createOrder`, dataJson).subscribe((res) => {
        // console.log(res);
        // console.log(ids);
        this.paypalUrl = res.msg.links[1].href;
        this.openPaypalModal();
  
      });
    }
  }

  async openPaypalModal(config?) {
    this.loadingService.hide();
    const modalConfig = {
      component: PaypalComponent,
      backdropDismiss: false,
    }

    if(config) {
      modalConfig['componentProps'] = config;

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    } else {
      modalConfig['componentProps'] = { paypalUrl: this.paypalUrl };

      let modal = await this.modalCtrl.create(modalConfig);
      modal.present();
    }
  }

  async cambiarStatusPago() {
    
    const headers = new HttpHeaders();
    let dataJson = {}

    return new Promise((resolve, reject) => {
      this.api.get(`/getPagoHistoricoId/${this.pagoId}`).subscribe(async (res) => {
        console.log(res);
  
        if(res.info.status == 'COMPLETED') {
          this.federadosPagados = res.idFederados;
  
          this.totalPagado = res.info.purchase_units[0].payments.captures[0].amount.value;
          this.fechaDePago = res.info.purchase_units[0].payments.captures[0].update_time.substring(0, 10);
          this.payerName = `${res.info.payer.name.given_name} ${res.info.payer.name.surname}`;
          this.payerEmail = res.info.payer.email_address;
          this.transactionId = res.info.id

          dataJson["asociacion"] =this.asociacionName;
          dataJson["equipo"] =res.equipo;
          dataJson["estado_origen"] = this.unionName;
          dataJson["status_pago"] ="VIGENTE";
          dataJson["seguro_status"] = "VIGENTE";
          dataJson["alineacion"] = "true";
          dataJson["fecha_pago_cuota_federacion"] = this.fechaDePago;
          dataJson["seguro_fecha_pago"] = this.fechaDePago;
          dataJson['comprobante_pago_url']=this.router.url
          headers.append("Content-Type", "application/json");
          headers.append("Accept", "application/json");
      
          this.feds.forEach(element => {
            if(this.federadosPagados.includes(element._id)) {
              // dataJson["seguro_costo"] = element.seguro_costo;
              // dataJson["cuota_costo"] = element.cuota_costo;
              
              this.api.update(`/updateFederado/${element._id}`, dataJson, headers).subscribe((res) => {
                console.log("Se actualizó el federado",res);
              });
            }
          });
    
          await this.searchFederadoById(false, this.federadosPagados);
          resolve(console.log('cambiarStatusPago'));

        }else {
          console.log('Pago no completado');
        }
      });
    });
  }

  async searchFederadoById(id?, ids?: any[]) {
    return new Promise((resolve, reject) => {
      if(id) {
        this.api.get("/getFederadoById/" + id).subscribe((res) => {
          this.alineacion.push(res[0]);          
          resolve(console.log('searchFederadoById'));
        });
      } else {
        let i = 0;

        ids.forEach(element => {
          this.api.get("/getFederadoById/" + element).subscribe((res) => {
            this.alineacion.push(res[0]);
            i++;
            if(i >= ids.length) {
              resolve(console.log('searchFederadoById'));
            }
          });
        });
      }
    });
  }

  cambiarFederadosDeEquipo(){
    let federadosDeOtroEquipo = [];
    let federadosNoIncluidos = [];

    this.alineacion.forEach(obj => {
      const headers = new HttpHeaders();

      console.log("obj.equipo[0].toLowerCase() ", obj.equipo[0].toLowerCase());
      console.log("this.equipoName.toLowerCase() ", this.equipoName);

      if(obj.equipo[0].toLowerCase() !== this.equipoName[0].toLowerCase()) {
        federadosDeOtroEquipo.push(obj);
        console.log("federadosDeOtroEquipo ", federadosDeOtroEquipo)
        let dataJson = {};
      
        dataJson["asociacion"] =this.asociacionName;
        dataJson["equipo"] =this.equipoName;
        dataJson["estado_origen"] = this.unionName;
        dataJson["status_pago"] ="VIGENTE";
        dataJson["seguro_status"] = "VIGENTE";
        dataJson["alineacion"] = "true";
        dataJson["fecha_pago_cuota_federacion"] = this.fechaDePago;
        dataJson["seguro_fecha_pago"] = this.fechaDePago;
        dataJson['comprobante_pago_url']=this.router.url;
        dataJson['comprobante_pago_url']=this.router.url;
        console.log(federadosDeOtroEquipo);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        this.api.update(`/updateFederado/${obj._id}`, dataJson, headers).subscribe((res) => {
          console.log(res);
        });
        // console.log(this.equipoName);
      }        
    });

    this.feds.forEach(obj => {
      if(!this.alineacion.find(element => element._id == obj._id)) {
        federadosNoIncluidos.push(obj)
      }
    });

    federadosNoIncluidos.forEach(obj => {
      const headers = new HttpHeaders();

      if(!federadosDeOtroEquipo.find(element => element._id == obj._id)) {
      let dataJson = {};
      
        dataJson["asociacion"] ="FEDERACION";
        dataJson["estado_origen"] = "FEDERACION";
        dataJson["equipo"] ="PENDIENTES";
        dataJson["status_pago"] ="PENDIENTE";
        dataJson["seguro_status"] = "PENDIENTE";
        dataJson["alineacion"] = "false";
        dataJson["fecha_pago_cuota_federacion"] = 'POR DEFINIR';
        dataJson["seguro_fecha_pago"] = 'POR DEFINIR';
        dataJson["seguro_poliza"] = 'POR DEFINIR';
       
    
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        this.api.update(`/updateFederado/${obj._id}`, dataJson, headers).subscribe((res) => {
          console.log(res);
        });
      }
    });
  }

  async print() {

    function getInnerHTMLWithoutNgContent(element: HTMLElement): string {
      const childNodes = element.childNodes;
      const innerHTML = [];
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];
        if (childNode.nodeType === Node.ELEMENT_NODE) {
          const element = childNode as HTMLElement;
          if (!element.hasAttribute('_ngcontent-xqw-c454')) {
            innerHTML.push(element.outerHTML);
          } else {
            innerHTML.push(getInnerHTMLWithoutNgContent(element));
          }
        } else if (childNode.nodeType === Node.TEXT_NODE) {
          innerHTML.push(childNode.textContent);
        }
      }
      return innerHTML.join('');
    }
    
    const element = document.getElementById('alineacion-pagada');
    const htmlWithoutNgContent = getInnerHTMLWithoutNgContent(element);
    console.log(htmlWithoutNgContent);

    // Hacer una solicitud POST al endpoint de Node.js
    this.api.post('/enviarPDF', { htmlToPDF: htmlWithoutNgContent, emailFed: localStorage.getItem('emailFed') })
        .subscribe(response => {
            console.log(response);
            // Manejar la respuesta del servidor si es necesario
        });
    
    // let element = document.getElementById('alineacion-pagada');
    // const errorSection = document.getElementById('btnBack');
    // errorSection.classList.add('disabled');

    // console.log(element);
  
    // let opt = {
    //   margin: 0.5,
    //   filename: `Comprobante_de_Pago.pdf`,
    //   image: { type: 'jpg', quality: 1 },
    //   html2canvas: { scale: 1, useCORS: true },
    //   jsPDF: { unit: 'cm', format: 'a2', orientation: 'portrait' },
    //   activeFontSize: 30,
    // };
  
      // html2pdf().set(opt).from(element).save().then((pdfBuffer) => {
      //     // Convertir el buffer del PDF a base64
      //     const pdfBase64 = pdfBuffer.toString('base64');
  
      //     // Hacer una solicitud POST al endpoint de Node.js
      //     this.api.post('/enviarPDF', { pdfFile: pdfBase64, emailFed: localStorage.getItem('emailFed') })
      //         .subscribe(response => {
      //             console.log(response);
      //             // Manejar la respuesta del servidor si es necesario
      //         });
      //   });

      // html2pdf().set(opt).from(element).save().output('blob').then((pdfBlob) => {
      //   // Convertir el blob del PDF a base64
      //   const reader = new FileReader();
      //   reader.onload = () => {
      //     const pdfBase64 = reader.result.toString();
      
      //     // Hacer una solicitud POST al endpoint de Node.js
      //     // this.api.post('/enviarPDF', { pdfFile: pdfBase64, emailFed: localStorage.getItem('emailFed') })
      //     //   .subscribe(response => {
      //     //     console.log(response);
      //     //     // Manejar la respuesta del servidor si es necesario
      //     //   });        
      //   };
      //   reader.readAsDataURL(pdfBlob);
      // });      
      
  }


  back(){

    window.history.back();
  }

}

