import { isArray } from 'rxjs/internal-compatibility';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { LoaderService } from '../../services/loading-service';
import { FederadosService } from '../federados/services/federados.service';
import Swal from "sweetalert2";
declare var $: any;
declare var XLSX;
import * as leaflet from 'leaflet'


@Component({
  selector: 'ngx-recintos',
  templateUrl: './recintos.component.html',
  styleUrls: ['./recintos.component.scss']
})
export class RecintosComponent implements OnInit, OnDestroy, AfterViewInit {

  public data: any = null;
  public create: boolean = false;
  public recintos: any[] = [];
  public map: any = null;
  public dataAdicional: any = {
    lat: 19.1986,
    long: -103.7583,
    img1: {
      url: "",
      b64: "",
      file: null
    },
    img2: {
      url: "",
      b64: "",
      file: null
    }
  };

  public dataRender: any = [
    {
      id: "inp-nombre_recinto",
      formControl: "nombre_recinto",
      label: "Nombre",
      placeholder: "Nombre...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    }, {
      id: "inp-pais_origen",
      formControl: "pais_origen",
      label: "País",
      placeholder: "País...",
      required: true,
      defaultValue: "MEXICO",
      type: "autoCompletar",
      withError: false,
      dato: ['MEXICO', 'USA']
    }, {
      id: "inp-codigo_postal",
      formControl: "codigo_postal",
      label: "CP / ZIP CODE",
      placeholder: "89670",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    }, {
      id: "inp-estado_origen",
      formControl: "estado_origen",
      label: "Entidad",
      placeholder: "Jalisco...",
      required: true,
      defaultValue: "MEXICO",
      type: "autoCompletar",
      withError: false
    }, {
      id: "inp-municipio_condado",
      formControl: "municipio_condado",
      label: "Delegación ó municipio",
      placeholder: "Aldama...",
      required: true,
      defaultValue: "",
      type: "text",
      withError: false
    }, {
      id: "inp-ciudad_localidad",
      formControl: "ciudad_localidad",
      label: "Colonia",
      placeholder: "Brownsville...",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },

    {
      id: "inp-calle_numero",
      formControl: "calle_numero",
      label: "Calle y número",
      placeholder: "Calle Mina #459...",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },

    {
      id: "inp-categoria",
      formControl: "categoria",
      label: "Categoría",
      placeholder: "Categoría...",
      required: true,
      defaultValue: "Lienzo Charro",
      type: "text",
      withError: false,
      // values: [
      //   { label: "Lienzo Charro", value: "lienzo charro" },
      // ]
    },
    // {
    //   id: "inp-asociacion",
    //   formControl: "asociacion",
    //   label: "Asociación",
    //   placeholder: "Asociación",
    //   required: true,
    //   defaultValue: "",
    //   type: "select",
    //   withError: false,
    //   values: [
    //   ]
    // },
    {
      id: "inp-telefono",
      formControl: "telefono",
      label: "Teléfono",
      placeholder: "5598765432",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    }, {
      id: "inp-email",
      formControl: "email",
      label: "Correo",
      placeholder: "charros@jalisco.com",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false
    },
    {
      id: "inp-poblacion_municipio",
      formControl: "poblacion_municipio",
      label: "Población INEGI",
      placeholder: "876",
      required: true,
      defaultValue: 0,
      type: "text",
      withError: false
    }, {
      id: "inp-fecha_fundacion",
      formControl: "fecha_fundacion",
      label: "Fecha de Fundación",
      placeholder: "0000-00-00",
      required: true,
      defaultValue: "1984-01-01",
      type: "date",
      withError: false
    }, {
      id: "inp-capacidad",
      formControl: "poblacion_municipio",
      label: "Capacidad",
      placeholder: "873",
      required: true,
      defaultValue: 0,
      defaultValueb64: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-calificacion",
      formControl: "rating",
      label: "Calificación",
      placeholder: "5",
      required: true,
      defaultValue: "pendiente",
      defaultValueb64: null,
      type: "text",
      withError: false,
    }, {
      id: "inp-ubicacion",
      formControl: "ubicacion",
      label: "Link Google Maps",
      placeholder: "https://goo.gl/maps/PZSPPyFjoP7H9CtV8",
      required: true,
      defaultValue: "pendiente",
      defaultValueb64: null,
      type: "text",
      withError: false,
    },
    {
      id: "inp-latitud",
      formControl: "latitud",
      label: "latitud",
      placeholder: "latitud",
      required: true,
      defaultValue: 0,
      type: "text",
      withError: false,
    },
    {
      id: "inp-longitud",
      formControl: "longitud",
      label: "longitud",
      placeholder: "longitud",
      required: true,
      defaultValue: 0,
      type: "text",
      withError: false,
    },
    {
      id: "inp-website",
      formControl: "website",
      label: "Sitio Web",
      placeholder: "https://www.charreria.com",
      required: true,
      defaultValue: "pendiente",
      type: "text",
      withError: false,
    }, 
    /* {
      id: "inp-facebook",
      formControl: "facebook",
      label: "Facebook",
      placeholder: "Facebook",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    }, {
      id: "inp-instagram",
      formControl: "instagram",
      label: "Instagram",
      placeholder: "Instagram",
      required: true,
      defaultValue: null,
      type: "text",
      withError: false
    } */
  ];

  constructor(
    private eventService: EventService,
    public api: FederadosService,
    private loadingService: LoaderService,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.cargarRecintos();
    // this.cargarAsociaciones();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cargaMapa();
    }, 1000);
  }

  // cargarAsociaciones() {
  //   this.api.get("/getAllAsociaciones").subscribe((res) => {

  //     res.asocArr.forEach(element => {
  //       this.dataRender[8].values.push({ label: element.nombreAsociacion, value: element.nombreAsociacion });
  //     });

  //   });
  // }

  cargarRecintos() {
    this.api.get(`/getAllRecintos`).subscribe((response: any) => {
      this.recintos = response.recArr;

      setTimeout(function () {
        $(function () {
          $("#dtRec").DataTable({
            bDestroy: true,

            bInfo: true,
            bProcessing: true,
            bDeferRender: true,
            iDisplayLength: 10,
            sPaginationType: "full_numbers",
            sDom: '<"top"i> T<"clear">lfrtip',
            sPageButtonActive: "paginate_active",
            sPageButtonStaticDisabled: "paginate_button",
            scrollY: "400px",
            scrollCollapse: true,
            stateSave: true,
              stateSaveCallback: function(settings,data) {
                  localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                },
              stateLoadCallback: function(settings) {
                return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
                }
          });
        });
      }, 300);
    }, (error: HttpErrorResponse) => {

    });
  }

  ngOnDestroy() {

  }

  plus2() {
    this.create = true;
    this.data = null;
    setTimeout(() => {
      this.cargaMapa();
    }, 1000);
  }

  cargaMapa() {
    console.log(this.dataRender[16].defaultValue)
    //this.loadingService.show();
    console.log("Se inicia la carga de mapa");
    var greenIcon = leaflet.icon({ iconUrl: 'assets/css/images/marker-icon.png', iconSize: [10, 25],iconAnchor: [10,15] })
    if (!this.dataAdicional.lat || this.dataAdicional.lat.length <= 0) {
      this.dataAdicional.lat = this.dataRender[16].defaultValue;
      this.dataAdicional.long = this.dataRender[17].defaultValue;

    }
    this.map = leaflet.map('map').setView([this.dataRender[16].defaultValue, this.dataRender[17].defaultValue], 17);

    // let a: any = document.getElementsByClassName("leaflet-control-attribution");
    // setTimeout(() => {
    //   a[0].children[0].style.display = "none";
    // }, 500);
    // set map tiles source

    let osmLayer = new leaflet.TileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

      maxZoom: 18,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      //attribution: 'Mapa de Juanshwo &copy; <a href="https://www.juanshwo.com/">Juanshwo</a>',
    });

    this.map.addLayer(osmLayer);

    // add marker to the map
    let marker = leaflet.marker([this.dataRender[16].defaultValue, this.dataRender[17].defaultValue], { draggable: false, icon: greenIcon }).addTo(this.map);
    marker.on('drag', function (e) {
      console.log('marker drag event');
    });
    marker.on('dragstart', function (e) {
    });
    marker.on('dragend', (e) => {
      var marker = e.target;
      var position = marker.getLatLng();
      this.dataAdicional.lat = position.lat;
      this.dataAdicional.long = position.lng;
      console.log(position);
      marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
    });

    this.loadingService.hide();
  }

  clearForm() {
    this.data = null;
    this.create = false;
    this.dataRender.forEach(element => {
      element.defaultValue = "";
    });
    location.reload();
  }

  edit(recinto: any) {
    console.log(recinto);
   
    let keys:any = Object.keys(recinto);
    this.dataRender.forEach(element => {
      element.defaultValue = recinto[element.formControl];
    });
    this.data = recinto;

    setTimeout(() => {
      this.cargaMapa();
    }, 3000)
  }

  delete(){
    

    Swal.fire({
      title: "Estás seguro de eliminar el registro?",
      text: "Se eliminará el recinto permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      console.log(result);
      console.log("THIS:DATA",this.data);
      
      if (result.isConfirmed) {
        this.api.delete(`/deleteRecinto/${this.data._id.toString()}`).subscribe((res) => {
          this.loadingService.hide();
          location.reload();
        });
        // console.log(this.data._id.toString())
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
    
  }

  update() {
    this.loadingService.show("Actualizando...");
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");

    let dataJson = {};
    this.dataRender.forEach(element => {
      dataJson[element.formControl] = element.defaultValue;
    });
    dataJson["pais_origen"]=dataJson["pais_origen"].toString();
    dataJson["estado_origen"]=dataJson["estado_origen"].toString();
    dataJson["latitud"] = dataJson["latitud"].toString();
    dataJson["longitud"] = dataJson["longitud"].toString();
console.log(dataJson);
    let sus = this.api.post2(`/createRecinto`, dataJson, headers);
    if (!this.create) {
      sus = this.api.update(`/updateRecinto/${this.data._id}`, dataJson, headers);
    }

    sus.subscribe((res: any) => {
      this.loadingService.hide();
      if (res) {
        Swal.fire({
          icon: "success",
          title: this.create ? "Se generó el recinto correctamente." : "Se actualizo el recinto correctamente.",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
         // this.clearForm();
         location.reload();
        });
      }
    }, (error: HttpErrorResponse) => {
      this.alertService.errorAlert("Oops!", "Ocurrió un error, contacta al administrador");
      this.loadingService.hide();
    });
  }

  onImageChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataAdicional.img1.file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.dataAdicional.img1.b64 = reader.result;
    };
  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataAdicional.img2.file = files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.dataAdicional.img2.b64 = reader.result;
    };
  }

  btnClick() {
    let id: any = document.getElementById("file");
    id.click();
  }
}
