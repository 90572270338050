import { AlertService } from './../../../services/alert.service';
import { TablaAsociacionEquipoComponent } from './../tablaAsociacionEquipo/tabla.component';
import { EventService } from './../../../services/event.service';
import { LoaderService } from './../../../services/loading-service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { DetallesComponent } from '../../federados/detalles/detalles.component';
import { FederadosService } from '../../federados/services/federados.service';
import { Asociacion } from '../../interface/interfaces';
import { isArray } from 'rxjs/internal-compatibility';
import { TablaEquipoAsocComponent } from '../tabla-by-asoc/tabla-by-equipo.component';
import { GenericService } from '../../../services/generic.service';
import { HttpErrorResponse } from '@angular/common/http';
declare var XLSX;
declare var TableToExcel;
declare var $: any;
@Component({
  selector: 'ngx-tabla-asociacion',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss']
})
export class TablaAsociacionComponent implements OnInit {
  asociacion: any[] = [];
  hideModal: boolean;
  url: string;
  img: any;
  public datosUsuario:any;




  constructor(
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private loadingService: LoaderService,
    private eventService: EventService,
    private alertaService: AlertService,
    private route: ActivatedRoute,
    private genericService: GenericService,
  ) {
    this.img = environment.urlServerimg;
    this.url = '/getAllAsociaciones';
    this.mostrarAsociacion();




  }

  ngOnInit(): void {
    //getAsociacionById
    if (this.route.snapshot.params.id) {
      this.edit(this.route.snapshot.params.id);
    }   
this.mostrarUsario();
  }

  mostrarAsociacion() {
    this.api.get(this.url).subscribe(res => {
      //console.log('Asociaciones', res.asocArr.length);
      this.asociacion = res.asocArr;
      if (this.asociacion.length > 50) {
        setTimeout(function () {
          $(function () {
            $("#dttr").DataTable({
              bDestroy: true,

              bInfo: true,
              bProcessing: true,
              bDeferRender: true,
              iDisplayLength: 50,
              sPaginationType: "full_numbers",
              sDom: '<"top"i> T<"clear">lfrtip',
              sPageButtonActive: "paginate_active",
              sPageButtonStaticDisabled: "paginate_button",
              scrollY: "650px",
              scrollCollapse: true,
              stateSave: true,
              stateSaveCallback: function(settings,data) {
                  localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                },
              stateLoadCallback: function(settings) {
                return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
                }

            });
          });
        }, 1000);
      }

      
    });

  }




  editOld(id) {

    const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
    });

    //this.router.navigateByUrl("pages/federados/detalles/" + id);
  }

  edit(id) {

    if (window.location.href.includes("registro")) {
      window.history.back();
    }
    //console.log("entro editar union", "idunion", id);
    this.loadingService.show("Cargando...");

    let url = "/getAsociacionById/" + id;
    //console.log("here");
    


    let data: any = {};
    data.id = id;
    this.api.get(url).subscribe((res) => {
      //console.log(res);


      if (res.estado_origen && isArray(res.estado_origen)) {
        res.estado_fundacion = res.estado_origen[0];
      } else {
        res.estado_fundacion = res.estado_fundacion;
      }

      if (res.pais_origen && isArray(res.pais_origen)) {
        res.pais_fundacion = res.pais_origen[0];
      } else {
        res.pais_fundacion = res.pais_origen;
      }

      if (res.union && isArray(res.union)) {
        res.union = res.union[0];
      } else {
        res.union = res.union;
      }

      if (!res.propietario_ap_materno) {
        res.propietario_ap_materno = "";
      }

      if (!res.equipo) {
        res.equipo = "";
      }

      if (isArray(res.equipo)) {
        res.equipo = res.equipo[0];
      }

      if (!res.pais_fundacion) {
        res.pais_fundacion = "";
      }

      if (!res.estado_fundacion) {
        res.estado_fundacion = "";
      }
      data.current = res;
      //console.log(data);


      this.loadingService.hide();
      console.log("----->");
      
      this.eventService.send("detalleAsociacionss", data);
    });
    /* const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });
 
     dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
     });   */
  }

  delete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        const url2 = '/deletewareHouse/' + id;
        this.api.get(url2).subscribe(res3 => {
          //console.log('delete', res3);
          this.mostrarAsociacion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your product is safe :)',
          'error'
        );
      }
    });

  }

  getEquipos(item) {
    //console.log(item);
    this.api.get(`/getAllEquiposByAsociacion/${item._id}`).subscribe(res => {
      if (res.eqArr.message != "No hay equipos registrados" || !res.message) {
        //console.log('equipspspspspsps', res);
        let equipos = res.eqArr;
        localStorage.setItem("equiposLoad", JSON.stringify(equipos));
        localStorage.setItem("nombreEquipo", JSON.stringify(item.nombreAsociacion));
        const dialogRef = this.dialog.open(TablaEquipoAsocComponent);

        dialogRef.afterClosed().subscribe(result => {
          //console.log(`Dialog result: ${result}`);
        });
      } else {
        this.alertaService.warnAlert("...", "Asociación sin equipos asignados");
      }
    });
  }

  btnClick() {
    let id: any = document.getElementById("file");
    id.click();
  }

  fileChange(evt: any) {
    let file: any = evt?.target?.files[0];
    if (file && file?.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.alertaService.errorAlert("Oops!", "Se espera un archivo .xlsx");
    } else {
      this.cargarMasivo(file);
    }
  }

  cargarMasivo(file: any): void {
    this.loadingService.show();
    const formData = new FormData();
    formData.append('importFile', file);
    this.genericService.sendPostRequest(environment.importAsociacionesCSV, formData).subscribe((r: any) => {
      this.alertaService.successAlert("Bien!", "Archivo cargado correctamente");
      this.mostrarAsociacion();
      this.loadingService.hide();
    }, (error: HttpErrorResponse) => {
      this.loadingService.hide();
    });
  }

  getRandom() {
    return Math.ceil(Math.random() * (100 - 1) + 1);
  }

  excel(table, name) {
    console.log(table);

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
      , base64 = (s) => { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = (s, c) => { return s.replace(/{(\w+)}/g, (m, p) => { return c[p]; }) }

    console.log(uri);

    return this.fr(uri, base64, format, template, table, name);
  }

  fr(uri: any, base64: any, format: any, template: any, table, name):any {
    let funcin: any = (table, name) => {
      console.log(table);

      if (!table.nodeType) table = document.getElementById(table)
      var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
      window.location.href = uri + base64(format(template, ctx))
    };
    return funcin();
  }

  plus() {
    this.router.navigateByUrl("/pages/asociacion/registro");
  }

mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}
}
