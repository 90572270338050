import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FederadosService } from "../services/federados.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { environment } from "../../../../environments/environment";
import { Asociacion } from "../../interface/interfaces";
import { Equipo, Federados } from "../../interface/interfaces";
import { MatDialogRef } from "@angular/material/dialog";

declare var $: any;
@Component({
  selector: "ngx-tabla-by-equipo",
  templateUrl: "./tabla-by-equipo.component.html",
  styleUrls: ["./tabla-by-equipo.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TablaEquipoFedComponent implements OnInit {
  federadosList: Federados[] = [];
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  public federadosForm: FormGroup;
  hideModal: boolean;
  url: string;
  url1: string;
  url2: string;

  img: any;

  public nombreEquipo:any;

  constructor(
    //public rest: FederadosService,
    public api: FederadosService,
    public formBuilder: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<any>,
  ) {
    this.img = environment.urlServerimg;
    this.url = "/getAllFederados";
    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";
    this.mostrarFederados();

    setTimeout(function () {
      $(function () {
        $("#dt").DataTable({
          bDestroy: true,

          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 10,
          sPaginationType: "full_numbers",
          sDom: '<"top"i> T<"clear">lfrtip',
          sPageButtonActive: "paginate_active",
          sPageButtonStaticDisabled: "paginate_button",
          scrollY: "400px",
          scrollCollapse: true,
        });
      });
    }, 5990);
  }

  ngOnInit(): void {
    this.hideModal = false;
    // this.getProducts();
  }

  // getProducts(): void {
  //   this.rest.getProducts().subscribe((resp: any) => {
  //     this.federado = resp;
  //    //console.log(this.federado);
  //   });
  // }

  clearForm() {
    this.federadosForm.reset();
  }

  mostrarFederados() {
    //this.api.get(this.url).subscribe((res) => {
     let res = JSON.parse(localStorage.getItem("federadosLoad"));
     this.nombreEquipo = JSON.parse(localStorage.getItem("nombreEquipo"));
     this.federadosList = res;
    //});
  }

  edit(id) {
    this.closeDialog();
    setTimeout(() => {
      //this.router.navigateByUrl("pages/federados/detalles/" + id);
      this.router.navigate(["pages","federados","detalles", id]);
      
    }, 500);
  }

  delete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {

        const url2 = "/deleteFederado/" + id;
        this.api.delete(url2).subscribe((res3) => {
         //console.log("delete", res3);
          this.mostrarFederados();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
