import { filter, map } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { element } from 'protractor'
import { Equipo } from './../interface/interfaces'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { setIndex } from '@ionic-native/core/decorators/common'
import { FederadosService } from '../federados/services/federados.service'
import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from 'src/environments/environment'
declare var $: any
@Component({
	selector: 'evento',
	templateUrl: './evento.component.html',
	styleUrls: ['./evento.component.scss'],
})
export class EventoComponent implements OnInit {
	img: any
	public datosUsuario: any
	public CharreadaRender: any[] = []
	public equipoRender: any[] = []
	public dataJsonComplete: any = {}
	public arrEquipo: any[] = []
	public arrCharreada: any[] = []
	public equipo: any[] = []
	public rencintoData: any[] = []
	public federados: any[] = []
	public federadosData: any[] = []
	public federadosJson: any[] = []
	public federadosSave: any[] = []
	public create: boolean = true
	public printSave: boolean = true
	public arrJsonCharreadas: any[] = []

	public printNuevo: boolean = true
	public nombreEquipo: any[] = []
	public dataJson = {}

	public eventId: string
	public currentEvent: any

	public charreadas: any[] = []

	public dataCharreada: any = [
		{
			id: 'inp-charreada',
			formControl: 'charreada',
			label: 'Nombre Charreada',
			placeholder: 'Evento de...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},

		{
		  id: "inp-id_charreada",
		  formControl: "id_charreada",
		  label: "id_charreadan",
		  placeholder: "id_charreada...",
		  required: true,
		  defaultValue: null,
		  type: "text",
		  withError: false
		},
		{
			id: 'inp-fecha_inicio',
			formControl: 'fecha_inicio',
			label: 'fecha_inicio',
			placeholder: 'Evento de...',
			required: true,
			defaultValue: null,
			type: 'date',
			withError: false,
		},
		{
			id: 'inp-hora_inicio',
			formControl: 'hora_inicio',
			label: 'hora_inicio',
			placeholder: 'Evento de...',
			required: true,
			defaultValue: null,
			type: 'time',
			withError: false,
		},
		
		{
		  id: "inp-categoria_charreada",
		  formControl: "categoria_charreada",
		  label: "categoria_charreada",
		  placeholder: "categoria_charreada...",
		  required: true,
		  defaultValue: null,
		  type: "text",
		  withError: false
		},
		
		{
		  id: "inp-fase_charreada",
		  formControl: "fase_charreada",
		  label: "fase_charreada",
		  placeholder: "fase_charreada...",
		  required: true,
		  defaultValue: null,
		  type: "text",
		  withError: false
		},
		
	]

	public dataRender: any = [
		{
			id: 'inp-nombre_organizador',
			formControl: 'nombre_organizador',
			label: 'Nombre del Organizador',
			placeholder: 'Organizador de...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},

		{
			id: 'inp-zona_evento',
			formControl: 'zona_evento',
			label: 'Zona del Evento',
			placeholder: 'Zona ...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},

		{
			id: 'inp-homenajeado',
			formControl: 'homenajeado',
			label: 'Homenajeado',
			placeholder: 'Federado Homenajeado ...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},
		{
			id: 'inp-categoria',
			formControl: 'categoria',
			label: 'Categoria',
			placeholder: 'Categoria ...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},
		{
			id: 'inp-fase',
			formControl: 'fase',
			label: 'Fase del Evento',
			placeholder: 'fase ...',
			required: true,
			defaultValue: null,
			type: 'text',
		},

		{
			id: 'inp-name',
			formControl: 'nombre_evento',
			label: 'Nombre del evento',
			placeholder: 'Evento de...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},

		{
			id: 'inp-edicion',
			formControl: 'edicion_evento',
			label: 'Edición',
			placeholder: '2021...',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},

		{
			id: 'inp-tipo_evento',
			formControl: 'tipo_evento',
			label: 'Tipo de Evento',
			placeholder: 'Tipo de Evento',
			required: true,
			defaultValue: '',
			type: 'text',
			withError: false,
		},
		{
			id: 'inp-dt1',
			formControl: 'fecha_inicio_evento',
			label: 'Fecha de inicio',
			placeholder: '01/01/20',
			required: true,
			defaultValue: null,
			type: 'date',
			withError: false,
		},
		{
		  id: "inp-dt2",
		  formControl: "fecha_fin_evento",
		  label: "Fecha de fin",
		  placeholder: "01/01/20",
		  required: true,
		  defaultValue: null,
		  type: "date",
		  withError: false
		},

		{
			id: 'inp-hr',
			formControl: 'hora_inicio_evento',
			label: 'Hora Inicio de Evento',
			placeholder: '12:00',
			required: true,
			defaultValue: null,
			type: 'time',
			withError: false,
		},
		{
			id: 'inp-costo_acceso_evento',
			formControl: 'costo_acceso_evento',
			label: 'Costo de Evento',
			placeholder: '$1000',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},
		{
			id: 'inp-link_venta_acceso_evento',
			formControl: 'link_venta_acceso_evento',
			label: 'www.ventatest.com',
			placeholder: '.com',
			required: true,
			defaultValue: null,
			type: 'text',
			withError: false,
		},
{
		  id: "inp-estado",
		  formControl: "estado_origen",
		  label: "Estado",
		  placeholder: "Estado....",
		  required: true,
		  defaultValue: null,
		  type: "text",
		  withError: false
		},
		{
		  id: "inp-recinto",
		  formControl: "nombre_recinto",
		  label: "nombre_recinto",
		  placeholder: "nombre_recinto....",
		  required: true,
		  defaultValue: null,
		  type: "text",
		  withError: false
		},


	]

	public inputEquipo: any = [
		{
			id: 'inp-equipo',
			formControl: 'equipo',
			label: 'Seleciona Equipo',
			placeholder: 'Equipo',
			required: true,
			defaultValue: null,
			type: 'autoCompletar',
			withError: false,
			dato: this.rencintoData,
		},
	]

	constructor(
		public api: FederadosService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.img = environment.urlServerimg
	}

	ngOnInit(): void {
		const eventId = this.route.snapshot.paramMap.get('id')
		if (eventId) {
			this.eventId = eventId
			this.create = false
			this.getEvent(eventId)
		}
		this.mostrarEquipo()
		this.mostrarUsario()
	}

	mostrarEquipo() {
		this.api.get('/getAllEquipos').subscribe((res) => {
			//console.log("equipoEXTERNO", res);
			this.equipo = res.eqArr

			this.equipo.forEach((element) => {
				const nombre = element.nombreEquipo
				const id = element._id
				this.rencintoData.push({ nombre, id })
			})
		})
	}

	addCharreadas() {
		if (this.validateInputs()) {
			let dataJson = {}
			this.dataCharreada.forEach((element, index) => {
				dataJson[element.formControl] = element.defaultValue
				dataJson['equipo'] = this.datosUsuario.equipo
				dataJson['Federados'] = [
					{
						idFederado: this.datosUsuario._id,
						suerte: 'cala de caballo',
						suplente: false,
					},
				]
				element.defaultValue = null
			})

			if (
				!this.CharreadaRender.find(
					(obj) => obj.charreada == this.dataJson['charreada']
				)
			) {
				//Render de Tabla html
				this.CharreadaRender.push(dataJson)
				//Arreglo Json
				this.arrCharreada.push(dataJson)
			}
			this.dataJson['Charreada'] = this.arrCharreada
			this.charreadas = [...this.CharreadaRender]
		}
	}

	addEquipos() {
		//
		this.api
			.get('/getEquipoById/' + this.inputEquipo[0].defaultValue)
			.subscribe((res) => {
				if (this.validateInputsEquipo()) {
					if (this.CharreadaRender.length > 0) {
						let dataJson = [{}]
						this.inputEquipo.forEach((element, index) => {
							dataJson[element.formControl] = res.nombreEquipo.toUpperCase()
							dataJson['id'] = res._id.toUpperCase()
							dataJson['charreada'] = this.CharreadaRender.length
							element.defaultValue = null
						})

						if (
							!this.equipoRender.find((obj) => obj.equipo == dataJson['equipo'])
						) {
							this.arrEquipo.push(dataJson['equipo'])
							this.dataJson['equipo'] = this.arrEquipo
							this.equipoRender.push(dataJson)

							console.log(this.dataJson)
						}
					} else {
						Swal.fire({
							//position: "top-end",
							icon: 'success',
							title: 'No has colocado Charreadas  ',
							showConfirmButton: false,
							timer: 1500,
						}).then((result) => {
							//this.clearForm();
						})
					}
				}
			})
	}

	public isInputValid
	validateInputs() {
		this.isInputValid = true

		this.dataCharreada.forEach((e) => {
			if (e.defaultValue == null) {
				this.isInputValid = false
				Swal.fire({
					//position: "top-end",
					icon: 'success',
					title: 'Faltan Datos por en  ' + e.label,
					showConfirmButton: false,
					timer: 1500,
				}).then((result) => {
					//this.clearForm();
				})
			}
		})

		return this.isInputValid
	}

	validateInputsEquipo() {
		this.isInputValid = true

		this.inputEquipo.forEach((e) => {
			if (e.defaultValue == null) {
				this.isInputValid = false
				Swal.fire({
					//position: "top-end",
					icon: 'success',
					title: 'Faltan Datos por en  ' + e.label,
					showConfirmButton: false,
					timer: 1500,
				}).then((result) => {
					//this.clearForm();
				})
			}
		})

		return this.isInputValid
	}

	edit(id) {
		//console.log(this.federadosSave);
		if (this.printSave) {
			this.federadosData = []
			this.api.get('/getAllFederadosByEquipo/' + id).subscribe((res) => {
				this.federados = res.arrFed
				this.federados.forEach((element) => {
					const equipo = element.equipo
					const foto = element.foto
					const no_registroFMCH = element.no_registroFMCH
					const suerte = 'cala de caballo'
					const idFederado = element._id
					const nombreFed = element.nombre_completo
					this.federadosData.push({
						foto,
						no_registroFMCH,
						suerte,
						nombreFed,
						equipo,
					})
					this.federadosJson.push({ suerte, idFederado })
				})
				this.dataJson['Federados'] = this.federadosJson
			})
		}

		setTimeout(function () {
			$(function () {
				$('#dewfeft').DataTable({
					bDestroy: true,
					retrieve: true,
					bInfo: true,
					bProcessing: true,
					bDeferRender: true,
					iDisplayLength: 50,
					sPaginationType: 'full_numbers',
					Dom: '<"top"i> T<"clear">bfrtip',
					PageButtonActive: 'paginate_active',
					PageButtonStaticDisabled: 'paginate_button',
					scrollY: '650px',
					scrollCollapse: true,
					stateSave: true,
					stateSaveCallback: function (settings, data) {
						localStorage.setItem(
							'DataTables_' + settings.sInstance,
							JSON.stringify(data)
						)
					},
					stateLoadCallback: function (settings) {
						return JSON.parse(
							localStorage.getItem('DataTables_' + settings.sInstance)
						)
					},
				})
			})
		}, 1000)
		$('#dewfeft').DataTable().draw().destroy()
		console.log(this.dataJson)
	}

	BorraRegistro(position) {
		console.log(position)
		this.equipoRender.splice(position, 1)
		this.federadosData = []
	}

	BorraRegistroFederados(position) {
		setTimeout(function () {
			$(function () {
				$('#dewfeft').DataTable({
					bDestroy: true,
					retrieve: true,
					bInfo: true,
					bProcessing: true,
					bDeferRender: true,
					iDisplayLength: 50,
					sPaginationType: 'full_numbers',
					Dom: '<"top"i> T<"clear">bfrtip',
					PageButtonActive: 'paginate_active',
					PageButtonStaticDisabled: 'paginate_button',
					scrollY: '650px',
					scrollCollapse: true,
					stateSave: true,
					stateSaveCallback: function (settings, data) {
						localStorage.setItem(
							'DataTables_' + settings.sInstance,
							JSON.stringify(data)
						)
					},
					stateLoadCallback: function (settings) {
						return JSON.parse(
							localStorage.getItem('DataTables_' + settings.sInstance)
						)
					},
				})
			})
		}, 300)
		$('#dewfeft').DataTable().draw().destroy()

		this.federadosData.splice(position, 1)
		this.federadosJson.splice(position, 1)
		this.federadosData.forEach((e) => {
			const equipo = e.equipo
			const foto = e.foto
			const no_registroFMCH = e.no_registroFMCH
			const suerte = e.suerte
			const nombreFed = e.nombreFed
			this.federadosSave.push({
				foto,
				no_registroFMCH,
				suerte,
				nombreFed,
				equipo,
			})
		})
	}

	// agregarCharreada() {

	// }

	crearevento() {
		this.dataJsonComplete = {}
		let dataJson = {}
		this.dataRender.forEach((element, index) => {
			dataJson[element.formControl] = element.defaultValue
		})

		this.dataJsonComplete = dataJson

		this.arrJsonCharreadas.push(this.dataJson)
		this.dataJsonComplete['opciones_extras_evento'] =
			this.arrJsonCharreadas[0]['Charreada']

		this.dataJson = {}
		this.CharreadaRender = []
		this.arrCharreada = []
		this.arrEquipo = []
		this.equipoRender = []
		this.federadosData = []
		this.federadosJson = []
		this.api.post('/createEvento/', this.dataJsonComplete).subscribe()

		Swal.fire({
			title: 'Evento creado',
			text: 'El evento se ha creado correctamente',
			icon: 'success',
		})

		this.router.navigate(['/pages/tablaEvento'])
	}

	mostrarUsario() {
		//this.api.get(this.url).subscribe((res) => {
		let res = JSON.parse(localStorage.getItem('userSessionFederacion'))
		this.datosUsuario = res

		console.log(this.datosUsuario)
		// this.chang.detectChanges();
		//});
	}

	getEvent(id: string) {
		this.api.get(`/getEventoById/${id}`).subscribe((res) => {
			this.dataRender = this.dataRender.map((e) => ({
				...e,
				defaultValue: res[e.formControl],
			}))

			this.CharreadaRender = res['opciones_extras_evento']
		})
	}

	editarEvento() {
		this.dataJsonComplete = {}
		let dataJson = {}
		this.dataRender.forEach((element) => {
			dataJson[element.formControl] = element.defaultValue
		})

		this.dataJsonComplete = dataJson

		this.arrJsonCharreadas.push(this.dataJson)
		this.dataJsonComplete['opciones_extras_evento'] =
			this.arrJsonCharreadas[0]['Charreada']

		this.dataJson = {}
		this.CharreadaRender = []
		this.arrCharreada = []
		this.arrEquipo = []
		this.equipoRender = []
		this.federadosData = []
		this.federadosJson = []
		this.api
			.update(`/updateEvento/${this.eventId}`, {
				...this.dataJsonComplete,
				opciones_extras_evento: this.charreadas,
			})
			.subscribe(() => {
				Swal.fire({
					title: 'Evento editado',
					text: 'El editado se ha creado correctamente',
					icon: 'success',
				})

				this.router.navigate(['/pages/tablaEvento'])
			})
	}
}
