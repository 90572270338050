import { Component, Inject, OnInit } from "@angular/core";
import { FederadosService } from "../../federados/services/federados.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Asociacion } from "../../interface/interfaces";
import { Equipo, Federados, Union } from "../../interface/interfaces";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "ngx-detalles",
  templateUrl: "./detalles.component.html",
  styleUrls: ["./detalles.component.scss"],
})
export class DetallesUnionComponent implements OnInit {
  FederadosList: {};
  public unionForm: FormGroup;
  hideModal: boolean;
  url: string;
  urlupdate: string;
  filename2: any;
  file2: "";
  federadosList: Federados[] = [];
  asociacion: Asociacion[] = [];
  equipo: Equipo[] = [];
  termSubscriber: Subscription;
  url1: string;
  url2: string;
  img: any;
  ids: any;
  aso: any;
  equi: any;

  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
  ) {
    this.url1 = "/getAllAsociaciones";
    this.url2 = "/getAllEquipos";

    this.mostrarAsociacion();
    this.mostrarEquipo();

    this.unionForm = this.formBuilder.group({
      nombre: ["Union Test 2"],
      pais_origen: ["MÉXICO"],
      estado_origen: ["CDMX"],
      cp: ["52123"],
      calle: ["calle test 1"],
      numero_ext: ["1235"],
      colonia: ["colonia test 1"],
      ciudad: ["ECATEPUNK"],
      lada: ["+52"],
      celular: ["5523467910"],
      noRegistroFMCH: ["463815348"],
      email: ["emailunion1@mail.com"],
      facebook: [""],
      instagram: [""],
      importFile: [""],
      logotipo: [""],
      tipo_Puesto_Union_FMCH: ["PRESIDENTE"],
      tipo_Puesto_Union_FMCH_NombreCompleto: ["nombre presidente test 1"],
      tipo_Puesto_Union_FMCH_Tel: ["5546798025"],
      tipo_Puesto_Union_FMCH_email: ["testemailpuesto1@mail.com"],
      statusPago: ["VIGENTE"],
      vencimiento: ["2022-02-16"],
    });
  }

  ngOnInit(): void {
    this.hideModal = false;

    this.termSubscriber = this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        this.ids = params["id"];
       //console.log(this.ids);
        if(!this.ids){
          this.ids = this.data.id;
        }
        this.url = "/getUnionById/" + this.ids;
        this.api.get(this.url).subscribe((res) => {
         //console.log("union", res);
          this.urlupdate = "/updateUnion/" + res._id;
          this.equi = res.equipo;
          this.aso = res.asociacion;
          if (res.foto) {
            this.img = environment.urlServerimg + res.foto;
           //console.log(this.img);
          }

          this.unionForm.setValue({
            nombre: res.nombre,
            pais_origen: res.pais_origen,
            estado_origen: res.estado_origen,
            cp: res.cp,
            calle: res.calle,
            numero_ext: res.numero_ext,
            colonia: res.colonia,
            ciudad: "",
            lada: res.lada,
            celular: res.celular,
            noRegistroFMCH: res.noRegistroFMCH,
            email: res.email,
            facebook: res.facebook,
            instagram: res.instagram,
            importFile: "",
            logotipo: res.logotipo,
            tipo_Puesto_Union_FMCH: res.tipo_Puesto_Union_FMCH,
            tipo_Puesto_Union_FMCH_NombreCompleto:
              res.tipo_Puesto_Union_FMCH_NombreCompleto,
            tipo_Puesto_Union_FMCH_Tel: res.tipo_Puesto_Union_FMCH_Tel,
            tipo_Puesto_Union_FMCH_email: res.tipo_Puesto_Union_FMCH_email,
            statusPago: "",
            vencimiento: res.vencimiento,
          });
        });
      }
    );
  }

  mostrarAsociacion() {
    this.api.get(this.url1).subscribe((res) => {
     //console.log("asociacion", res);
      this.asociacion = res;
    });
  }

  mostrarEquipo() {
    this.api.get(this.url2).subscribe((res) => {
     //console.log("equipo", res);
      this.equipo = res;
    });
  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
  }

  update() {

   //console.log(this.unionForm.value.logotipo);
    
    let formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("nombre", this.unionForm.value.nombre);
    formData.append("pais_origen", this.unionForm.value.pais_origen);
    formData.append("estado_origen", this.unionForm.value.estado_origen);
    formData.append("cp", this.unionForm.value.cp);
    formData.append("calle", this.unionForm.value.calle);
    formData.append("numero_ext", this.unionForm.value.numero_ext);
    formData.append("colonia", this.unionForm.value.colonia);
    formData.append("ciudad", this.unionForm.value.ciudad);
    formData.append("lada", this.unionForm.value.lada);
    formData.append("celular", this.unionForm.value.celular);
    formData.append("noRegistroFMCH", this.unionForm.value.noRegistroFMCH);
    formData.append("email", this.unionForm.value.email);
    formData.append("facebook", this.unionForm.value.facebook);
    formData.append("instagram", this.unionForm.value.instagram);
    formData.append("importFile", this.unionForm.value.impoertFile);
    formData.append("logotipo", this.unionForm.value.logotipo);
    formData.append(
      "tipo_Puesto_Union_FMCH",
      this.unionForm.value.tipo_Puesto_Union_FMCH
    );
    formData.append(
      "tipo_Puesto_Union_FMCH_NombreCompleto",
      this.unionForm.value.tipo_Puesto_Union_FMCH_NombreCompleto
    );
    formData.append(
      "tipo_Puesto_Union_FMCH_Tel",
      this.unionForm.value.tipo_Puesto_Union_FMCH_Tel
    );
    formData.append(
      "tipo_Puesto_Union_FMCH_email",
      this.unionForm.value.tipo_Puesto_Union_FMCH_email
    );
    formData.append("statusPago", this.unionForm.value.statusPago);
    formData.append("vencimiento", this.unionForm.value.vencimiento);
    this.api.update(this.urlupdate, formData, headers).subscribe((res) => {
      if (res) {
        // this.clearForm();
        // this.showWareHoouse();
        // this.router.navigateByUrl('/usuario/u/warehouseCheck');
        Swal.fire({
          //position: "top-end",
          icon: "success",
          title: "Se actualizo la union correctamente.",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          this.goTable();
        });
      }
    });
  }

  clearForm() {
    this.unionForm.reset();
  }

  goTable() {
    this.closeDialog();
  }

  plus() {
    this.closeDialog();
    this.router.navigateByUrl("/pages/union/registro");
  }

  closeDialog(){
    this.dialogRef.close();
  }

  delete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        const url2 = "/deleteUnion/" + this.data.id;
        this.api.delete(url2).subscribe((res3) => {
         //console.log("delete", res3);
          location.reload();
          //this.mostrarUnion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }
}
