import { isArray } from 'rxjs/internal-compatibility';
import { Component,Output,EventEmitter, OnInit,Input } from '@angular/core';
import { Union } from 'src/app/pages/interface/interfaces';
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";

@Component({
  selector: 'fmch-input',
  templateUrl: './fmch-input.component.html',
  styleUrls: ['./fmch-input.component.scss'],
})
export class FmchInputComponent implements OnInit {
  @Input() data: any = [];
  @Input() id:string = "";
  @Input() nombre:string = "text";
  @Input() label:string = null;
  @Input() inputType: string = "text";
  @Input() inputPlaceholder: string = "";
  @Input() inputMaxLength: number = 10;
  @Input() fmchModel: string = "";
  @Input() control = new  FormControl('');
  @Input() dato:  any= [{}];
  @Input() extraClass: string;
  @Output() multipleSelectionChange = new EventEmitter<any>();
  @Input() ControlName:string=""
  @Output() fmchModelChange = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  public seleccion: any[] = [];
  completePlaceholder = "Elija una opción";
  public filteredOptions: Observable<string[]>;
  public propiedad: boolean = true;
  constructor() { }
 
  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(" "),
      debounceTime(300),
      map(value => (value ? this._filter(value):this.dato.slice()))
    );

   
  }
  private _filter(value: any): any[] {
    
    this.dato.map(arr=>{
      if(arr.hasOwnProperty('nombre')){
        this.propiedad=true
      }
      else{
        this.propiedad=false
      }
    })
    
  
      
    
    try {
      if (!this.propiedad){
      const filterValue = value.toLowerCase();
      //return this.dato.filter(option => option.nombre.toLowerCase().includes(filterValue));
      return this.dato.filter(option => option.toLowerCase().includes(filterValue));
      }
      else{
        const filterValue = value.toLowerCase();
        return this.dato.filter(option => option.nombre.toLowerCase().includes(filterValue));
        //return this.dato.filter(option => option.toLowerCase().includes(filterValue));

      }
    } catch (error) {
      
    }

  }
  Onclick(event:any){
    this.clickEvent.emit(event.target.innerText);
    
console.log(event.target.innerText)
  }
/*checkbox change event*/
onChange(event) {
  console.log("-----------------");
  let index: any = this.seleccion.findIndex(x => x.value === event.value);
  if (index != -1) {
    console.log(index);
    if (this.seleccion.length == 1) {
      this.seleccion = [];
    } else {
      this.seleccion = this.seleccion.slice(index, this.seleccion.length);
    }
  } else {
    this.seleccion.push(event);
  }
  console.log(this.seleccion);


  this.multipleSelectionChange.emit(this.seleccion);

}
}