import { TablaEventoComponent } from './pages/evento/tabla/tabla.component'
import { EventoComponent } from './pages/evento/evento.component'
import { EventosHistoricosComponent } from './pages/eventos-historicos/eventos-historicos.component'
import { LoginFeelComponent } from './pages/login/login.component'
import { path } from './../environments/environment'
import { FederadosComponent } from './pages/federados/federados.component'
import { PrintComponent } from './pages/print/print.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { TablaEquipoAsocComponent } from './pages/asociacion/tabla-by-asoc/tabla-by-equipo.component'
import { TablaAsociacionComponent } from './pages/asociacion/tabla/tabla.component'

import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './guards/auth.guard'
import { RootGuard } from './guards/root.guard'
import { RegistroComponent } from './pages/asociacion/registro/registro.component'
import { AsociacionComponent } from './pages/asociacion/asociacion.component'
import { PagesComponent } from './pages/pages.component'
import { UnionComponent } from './pages/union/union.component'
import { RegistroUnionComponent } from './pages/union/registro/registro.component'
import { TablaUnionComponent } from './pages/union/tabla/tabla.component'
import { DetallesUnionComponent } from './pages/union/detalles/detalles.component'
import { EquiposComponent } from './pages/equipos/equipos.component'
import { RegistroEquipoComponent } from './pages/equipos/registro/registro.component'
import { TablaEquipoComponent } from './pages/equipos/tabla/tabla.component'
import { RecintosComponent } from './pages/recintos/recintos.component'
import { UsuariosComponent } from './pages/usuarios/usuarios.component'
import { ConstruccionComponent } from './pages/construccion/construccion.component'
import { DetallesComponent } from './pages/federados/detalles/detalles.component'
import { TablaEquipoFedComponent } from './pages/federados/tabla-by-equipo/tabla-by-equipo.component'
import { UploadFileComponent } from './pages/federados/upload-file/upload-file.component'
import { UploadImgComponent } from './pages/federados/upload-img/upload-img.component'
import { TablaFederadosComponent } from './pages/federados/tabla/tabla.component'
import { RegistroFederadosComponent } from './pages/federados/registro/registro.component'
import { EstadisticasComponent } from './pages/estadisticas/estadisticas.component'
import { PagosComponent } from './pages/pagos/pagos.component'
import { RegistrokioskoComponent } from './pages/registrokiosko/registrokiosko.component'
import { CredencialEquipoComponent } from './pages/credencial-equipo/credencial-equipo.component'
import { DetallesKioskoComponent } from './pages/detalles-kiosko/detalles-kiosko.component'
import { LoginPerfilesComponent } from './pages/login-perfiles/login-perfiles.component'
import { ValidacionComponent } from './pages/validacion/validacion.component'
import { RecuperarPassComponent } from './pages/recuperar-pass/recuperar-pass.component'

const routes: Routes = [

	{
		path: 'validacion/:id/:perfil',
		component: ValidacionComponent,
	},
	{
		path: 'RegistroKiosko',
		component: RegistrokioskoComponent,
	},
	{
		path: 'DetalleKiosko/:id/:perfil',
		component: DetallesKioskoComponent,
	},
	{
		path: 'login-perfil/:id',
		component: LoginPerfilesComponent,
	},
	{
		path: 'CredencialEquipo',
		component: CredencialEquipoComponent,
	},
	{
		path: 'recuperar/:id',
		component: RecuperarPassComponent,
	},
	{
		path: '',
		redirectTo: 'pages/estadisticas',
		pathMatch: 'full',
	},

	{
		path: 'pages',
		redirectTo: 'pages/users',
		pathMatch: 'full',
	},

	{
		path: 'login',
		component: LoginFeelComponent,
	},

	{
		path: 'pages',
		component: PagesComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'asociacion',
				component: AsociacionComponent,
				children: [
					{
						path: 'registro',
						component: RegistroComponent,
					},
					{
						path: 'tabla',
						component: TablaAsociacionComponent,
					},
					{
						path: 'tabla',
						children: [
							{
								path: ':id',
								component: TablaAsociacionComponent,
							},
						],
					},
					{
						path: 'tabla-asoc',
						data: {
							title: 'Tabla de federados por equipo',
						},
						children: [{ path: ':slug', component: TablaEquipoAsocComponent }],
					},
				],
			},
			{
				path: 'dashboard',
				component: DashboardComponent,
			},
			{
				path: 'union',
				component: UnionComponent,
				children: [
					{
						path: 'registro',
						component: RegistroUnionComponent,
					},
					{
						path: 'tabla',
						component: TablaUnionComponent,
					},
					{
						path: 'detalles/:id',
						component: DetallesUnionComponent,
					},
				],
			},

			{
				path: 'print',
				children: [
					{
						path: ':type',
						component: PrintComponent,
					},
				],
			},

			{
				path: 'federados',
				component: FederadosComponent,
				children: [
					{
						path: 'tabla',
						component: TablaFederadosComponent,
					},
					{
						path: 'tabla-equipo',
						data: {
							title: 'Tabla de federados por equipo',
						},
						children: [{ path: ':slug', component: TablaEquipoFedComponent }],
					},
					{
						path: 'registro',
						component: RegistroFederadosComponent,
					},
					{
						path: 'upload-file',
						component: UploadFileComponent,
					},
					{
						path: 'img',
						component: UploadImgComponent,
					},
					{
						path: 'detalles/:id',
						component: DetallesComponent,
					},
					{
						path: 'detalles',
						component: DetallesComponent,
					},
				],
			},

			{
				path: 'equipos',
				component: EquiposComponent,
				children: [
					{
						path: 'registro',
						component: RegistroEquipoComponent,
					},
					{
						path: 'tabla',
						component: TablaEquipoComponent,
					},
				],
			},
			{
				path: 'estadisticas',
				component: EstadisticasComponent,
			},
			{
				path: 'recintos',
				component: RecintosComponent,
			},
			{
				path: 'users',
				component: UsuariosComponent,
			},
			{
				path: 'pagos/:id_Equipo/:id_PAGO',
				component: PagosComponent,
			},
			{
				path: 'wip',
				component: ConstruccionComponent,
			},
			{
				path: 'evento',
				component: EventoComponent,
			},
      {
				path: 'evento/:id',
				component: EventoComponent,
			},
			{
				path: 'tablaEvento',
				component: TablaEventoComponent,
			},
			{
				path: 'eventos-historicos',
				component: EventosHistoricosComponent,
			},
		],
	},
]
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
