import { LoaderService } from './../../../services/loading-service';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { FederadosService } from "../../federados/services/federados.service";
import { Equipo, Union } from "../../interface/interfaces";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { EventService } from "../../../services/event.service";

declare var $: any;
@Component({
  selector: "ngx-tabla",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.scss"],
})
export class TablaUnionComponent implements OnInit {
  union: any = [];
  mesa: any[]=[];
  url: string;
  img: any;
  hideModal: boolean;
  public datosUsuario:any;


  constructor(
    public api: FederadosService,
    private router: Router,
    private dialog: MatDialog,
    private eventService: EventService,
    private loadingService: LoaderService) {

    this.img = environment.urlServerimg;
    this.url = "/getAllUniones";
    this.mostrarUnion();
    setTimeout(function () {
      $(function () {
        $("#dewfeft").DataTable({

          
          bDestroy: true,

          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 50,
          sPaginationType: "full_numbers",
          Dom: '<"top"i> T<"clear">bfrtip',
          PageButtonActive: "paginate_active",
          PageButtonStaticDisabled: "paginate_button",
          scrollY: "650px",
          scrollCollapse: true,
          stateSave: true,
          stateSaveCallback: function(settings,data) {
              localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
            },
          stateLoadCallback: function(settings) {
            return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) )
            }
        });
      });
    }, 4000);
  }

  ngOnInit(): void {
    this.hideModal = false;
    this.mostrarUsario();
  }

  mostrarUnion() {
    this.api.get(this.url)
    
    .subscribe((res) => {
     console.log("Uniones", res.UnionArr);
      this.union = res.UnionArr;
   
      console.log("Uniones", this.mesa[1]);
     
    });
  }

  edit(id, item) {

    if(window.location.href.includes("registro")){
      window.history.back();
    }
   //console.log("entro editar union", "idunion", id);
    this.loadingService.show("Cargando...");

    let url = "/getUnionById/" + id;

    let url1 = "/getAllAsociaciones";
    let url2 = "/getAllEquipos";

    let data: any = {};
    data.id = id;

    this.api.get(url).subscribe((res) => {
     //console.log(res);
      if (!res.msg || res.msg != "Unión no encontrada") {
        data.current = item;
      } else {
        data.current = res;
      }

      this.api.get(url1).subscribe((res2) => {
        data.asociaciones = res2;

        this.api.get(url2).subscribe((res3) => {
          data.equipos = res3;
          this.loadingService.hide();
         //console.log(data);
          this.eventService.send("detalleUnion", data);
        });

      });


    });
    /* const dialogRef = this.dialog.open(DetallesComponent, { data: { id } });
 
     dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
     });   */
  }



  delete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        const url2 = "/deleteUnion/" + id;
        this.api.delete(url2).subscribe((res3) => {
         //console.log("delete", res3);
          this.mostrarUnion();
        });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your product is safe :)", "error");
      }
    });
  }
  
  getRandom(){
    return Math.ceil(Math.random() * (100 - 1) + 1);
  }

  plus() {
    this.eventService.send("createUn", null);
    //this.router.navigateByUrl("/pages/union/registro");
  }

  btnClick() {
    let id: any = document.getElementById("file");
    id.click();
  }



mostrarUsario() {
  //this.api.get(this.url).subscribe((res) => {
   let res = JSON.parse(localStorage.getItem("userSessionFederacion"));
   this.datosUsuario = res
  
  console.log(this.datosUsuario);
  // this.chang.detectChanges();
  //});

}
}
