import { emulado } from './../../environments/environment.prod';
import { LocalStorageEncryptService } from './local-storage-encrypt.service';
import { LoaderService } from './loading-service';
import { SqlGenericService } from './sqlGenericService';
import { Injectable } from '@angular/core';
import {
    Plugins,
} from '@capacitor/core';

import { Router } from '@angular/router';

import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
    PushNotificationToken,
    PushNotification,
} from '@capacitor/push-notifications';

import { HttpErrorResponse } from '@angular/common/http';

import { FCM } from '@capacitor-community/fcm';
import { Device } from '@capacitor/device';
@Injectable({
    providedIn: 'root'
})
export class FcmService {

    constructor(
        private router: Router,
        private sqlGenericService: SqlGenericService,
        private loadingService: LoaderService,
        private localStorageEncryptService: LocalStorageEncryptService
    ) { }

    initPush() {
        if (!emulado) {
            this.registerPush();
        }
    }

    private async registerPush() {
        await PushNotifications.requestPermissions();
        await PushNotifications.register();

        console.log("For get token");
        const info = await Device.getId();
      console.log(info.uuid);
        
        // Get FCM token instead the APN one returned by Capacitor
        FCM.getToken()
            .then((r) => {
                console.log("***TOKEN***");
                console.log(r.token);
                
                let token: string = r.token;
                /** FCM Register */
                let sql: string = `INSERT INTO usuario (uuid, token) VALUES ('${info.uuid}', '${token}')`;
                let sqlChecking: string = `SELECT * FROM usuario WHERE uuid = '${info.uuid}'`;

                this.loadingService.show();

                //consultar uuid en base de datos antes de registrar nuevo token 
                //Si encuentra el uuid se actualizará el token pero no creará nuevo usuario

                console.log("listo for consumming");
                
                this.sqlGenericService.excecuteQueryString(sqlChecking).subscribe((resp: any) => {
                    console.log("RESPONSE*****************");
                    console.log(resp);
                    
                    if (resp.parameters.length <= 0) {
                        console.log("CONSUMING INSERT INTO");
                        
                        this.sqlGenericService.excecuteQueryString(sql, 3).subscribe((resp: any) => {
                            //Se registra correctamente nuevo usuario
                            this.loadingService.hide();
                            this.localStorageEncryptService.setToLocalStorage("token-ConecTandem", token);
                            FCM.subscribeTo({ topic: 'connecTandem' });//se suscribe a notificaciones globales de la app
                            //PushNotifications.
                            this.listenNotifications();
                        }, (err: HttpErrorResponse) => {
                            console.log(err);
                            
                            this.loadingService.hide();
                        });
                    } else {
                        this.loadingService.hide();
                    }
                }, (err: HttpErrorResponse) => {
                    console.log("IN ERRROOR");
                    
                    console.log(err);
                    
                    this.loadingService.hide();
                });
                /** */
            })
            .catch((err) => console.log(err));

        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error: ' + JSON.stringify(error));
        });

        this.listenNotifications();

        
    }


    public listenNotifications() {
        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: ActionPerformed) => {
                const data = notification.notification.data;
                console.log('Action performed: ' + JSON.stringify(notification.notification));
                if (data.detailsId) {
                    //this.router.navigateByUrl(`/home/${data.detailsId}`);
                }
            }
        );
    }
}