import { EventService } from './../../../services/event.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { HeaderItem } from 'src/app/models/header-item.model';
/**Componente header de la aplicación */
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderCharrosComponent implements OnInit {

  public publicItems: HeaderItem[] = [];
  public active: boolean = true;
  public w: number = 0;
  public user: any = null;
  public img: any = null;
  constructor(
    private router: Router,
    private eventService: EventService
  ) {
    this.w = window.innerWidth;
    this.user = JSON.parse(localStorage.getItem("userSessionFederacion"));
    this.img = environment.urlServerimg;
  }

  onResize(e) {
    this.w = window.innerWidth;
    if (this.w > 792) {
      this.openNav();
    }
  }

  /**Inicializa items de header público */
  public ngOnInit() {
    this.publicItems = JSON.parse(localStorage.getItem("lastItems"));
    if(!this.publicItems){
      this.publicItems = HeaderItem.staticPublicList();
    }

  }

  public clickedItem(itm: HeaderItem): void {
    console.log(itm);
    
    localStorage.setItem("lastItems", JSON.stringify(this.publicItems));
    this.publicItems.forEach(element => {
      element.active = false;
    });

    itm.active = true;
    localStorage.setItem("lastItems", JSON.stringify(this.publicItems));
    
    if(itm.id == 10){
      this.exit();
    }else{
      this.router.navigate([itm.link]);
    }
  }

  public clickedItemMini(itm: HeaderItem): void {
    this.router.navigateByUrl(itm.link);
  }


  public openNav() {
    if (!this.active) {
      this.active = true;
      this.eventService.send("sidemenu", null);
      document.getElementById("mySidenav").style.left = "0px";
    }
  }

  public closeNav() {
    if (this.active) {
      this.active = false;
      this.eventService.send("sidemenu", null);
      document.getElementById("mySidenav").style.left = "-300px";
    }
  }

  goLogin() {
    this.router.navigateByUrl(`/login`);
  }

  exit() {
    Swal.fire({
      title: 'Cierre de sesión',
      text: '¿Estás seguro de cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.goLogin();
      }
    });
  }

  inicio() {
    this.router.navigateByUrl(`/`);
  }

}