import { isArray } from 'rxjs/internal-compatibility';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';
import { LoaderService } from '../../services/loading-service';
import { FederadosService } from '../federados/services/federados.service';
import Swal from "sweetalert2";
declare var $: any;
declare var XLSX;
import * as leaflet from 'leaflet'
import { Subscription } from 'rxjs';


@Component({
  selector: 'construccion',
  templateUrl: './construccion.component.html',
  styleUrls: ['./construccion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConstruccionComponent implements OnInit, OnDestroy {

  constructor() {

  }

  ngOnDestroy() {

  }

  ngOnInit() {

  }

}
